import React, { useMemo, useState } from 'react';

import { Divider, Select } from 'antd';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { CustomTag } from 'src/pages/admin/paywalls/PaywallBuilder/products/SmartTextTagCloud';

import { usePicker } from './PickerContextWrapper';

const Presets = ({
  presets = [],
  dynamicColors = [],
}: {
  presets: string[];
  dynamicColors?: { label: string; value: string }[];
}) => {
  const picker = usePicker();
  const recentColors = useAppSelector(({ paywallBuilder }) => {
    return paywallBuilder.recentColors;
  });

  const [dropdownValue, setDropdownValue] = useState<
    'preset' | 'dynamic' | 'recent'
  >(picker?.value.includes('${block.') ? 'dynamic' : 'recent');

  const getPresets = () => {
    if (presets?.length > 0) {
      return presets?.slice(0, 18);
    } else {
      return fakePresets;
    }
  };

  const getRecent = useMemo(() => {
    return Array.from(recentColors).reverse().slice(0, 18);
  }, [recentColors]);

  if (!picker) return null;

  let selectOptions: { value: string; label: string }[] = [
    {
      value: 'recent',
      label: 'Recently Used Colors',
    },
  ];
  if (dynamicColors.length) {
    selectOptions.push({
      value: 'dynamic',
      label: 'From Custom Data Source',
    });
  }
  selectOptions.push({
    value: 'preset',
    label: 'Sample Colors',
  });

  return (
    <>
      <Divider style={{ margin: '12px 0px' }} />
      <Select
        value={dropdownValue}
        size="small"
        options={selectOptions}
        onChange={(value) => setDropdownValue(value)}
      />
      <div style={{ display: 'flex', marginTop: 14 }}>
        {dropdownValue === 'recent' && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            {getRecent.map((p, key) => (
              <div
                key={key}
                style={{
                  height: 21,
                  width: 21,
                  borderRadius: 2,
                  background: p,
                  border: '0.5px solid #d9d9d9',
                  cursor: 'pointer',
                }}
                onClick={() => picker.handleChange(p)}
              />
            ))}
          </div>
        )}
        {dropdownValue === 'preset' && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            {getPresets().map((p, key) => (
              <div
                key={key}
                style={{
                  height: 21,
                  width: 21,
                  borderRadius: 2,
                  background: p,
                  border: '0.5px solid #d9d9d9',
                  cursor: 'pointer',
                }}
                onClick={() => picker.handleChange(p)}
              />
            ))}
          </div>
        )}
        {dropdownValue === 'dynamic' && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
            {dynamicColors.map((color, index) => (
              <CustomTag
                onClick={() => picker.handleChange(color.value)}
                key={index}
              >
                {color.label}
              </CustomTag>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Presets;

const fakePresets = [
  'rgba(0,0,0,1)',
  'rgba(128,128,128, 1)',
  'rgba(192,192,192, 1)',
  'rgba(255,255,255, 1)',
  'rgba(0,0,128,1)',
  'rgba(0,0,255,1)',
  'rgba(0,255,255, 1)',
  'rgba(0,128,0,1)',
  'rgba(128,128,0, 1)',
  'rgba(0,128,128,1)',
  'rgba(0,255,0, 1)',
  'rgba(128,0,0, 1)',
  'rgba(128,0,128, 1)',
  'rgba(175, 51, 242, 1)',
  'rgba(255,0,255, 1)',
  'rgba(255,0,0, 1)',
  'rgba(240, 103, 46, 1)',
  'rgba(255,255,0, 1)',
];
