import React, { useEffect, useState } from 'react';

import { EditOutlined, ReadOutlined } from '@ant-design/icons';
import { Form, Input, Typography } from 'antd';
import { deconstructVariable } from 'src/utils/paywall';
import { namiMediumGray } from 'src/variables';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import { FieldObject } from '../../../utils/formFieldBuilding';
import SmartTextTagCloud from '../../products/SmartTextTagCloud';
import InputLabel from './InputLabel';
import VariableTagCloud from './VariableTagCloud';

type TextInputProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  url?: boolean;
};

const ClickwrapLink = styled.a.attrs({
  href: 'https://learn.namiml.com/public-docs/nami-account/manage-your-apps/setting-up-legal-text-for-your-apps',
  children: [<EditOutlined />, <span>Edit in App Settings</span>],
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  .anticon {
    margin-right: 0.5rem;
  }
`;

const MarkdownHint = styled(Typography.Text)`
  float: right;
  margin-bottom: 15px;
`;

const MarkdownLink = styled.a.attrs({
  href: 'https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/editable-properties#text',
  target: '_blank',
  rel: 'noreferrer noopener',
})`
  color: inherit !important;
`;

const FormHint = styled(Typography.Text)`
  float: right;
  margin-bottom: 10px;
  color: ${namiMediumGray} !important;
`;

const TextInputItem = styled(Form.Item)`
  .ant-form-item-label label {
    width: 100%;
    justify-content: space-between;
  }
  margin-bottom: 12px;
`;

export default function TextInput({
  defaultValue,
  onChange,
  controlled = false,
  url = false,
  ...field
}: TextInputProps) {
  const placeholder = field.placeholder || field.name;
  const [inputValue, setInputValue] = useState<string>(field.value);
  const [debouncedValue] = useDebounce(inputValue, 150);

  useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  useEffect(() => {
    handleInputChange(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const markdownHint = field.markdownHint && (
    <MarkdownHint>
      <MarkdownLink>
        <ReadOutlined /> Add <strong>**Bold**</strong> or <i>*Italic*</i> Text
        with Markdown
      </MarkdownLink>
    </MarkdownHint>
  );

  const textHint = field.hint && <FormHint>{field.hint}</FormHint>;

  const itemProps = {
    help: markdownHint || textHint,
    name: controlled ? undefined : field.variable,
    label: <InputLabel {...field} />,
  };

  const isClickwrap = (defaultValue || field.value) // TODO: Find a way to bring this from the template
    .toString()
    .match(/\$\{\s*legal\.clickwrap_text\s*}/);
  itemProps.label = (
    <>
      {itemProps.label}
      {isClickwrap ? <ClickwrapLink /> : null}
    </>
  );

  const isCustomVariable = ['launch', 'state'].includes(
    deconstructVariable(field.value.toString(), true)
  );
  const value = controlled ? field.value : undefined;

  const suffix = field.showSmartTextCloud ? (
    <SmartTextTagCloud handleChange={appendTagToInput} />
  ) : field.showVariableCloud ? (
    <VariableTagCloud handleChange={appendTagToInput} />
  ) : undefined;

  return (
    <TextInputItem {...itemProps}>
      <Input
        value={inputValue}
        maxLength={field.maxLimit || undefined}
        minLength={field.minLimit || undefined}
        disabled={!field.editable}
        placeholder={placeholder}
        onChange={(e) => setInputValue(e.target.value as string)}
        suffix={suffix}
        status={
          url &&
          ((!isCustomVariable && !isValidURL(value)) || value.trim() !== value)
            ? 'error'
            : undefined
        }
      />
    </TextInputItem>
  );

  function isValidURL(value: string): boolean {
    try {
      new URL(value);
      return true;
    } catch (e) {
      return false;
    }
  }

  function appendTagToInput(tagValue: string): void {
    setInputValue(`${value}${tagValue}`);
  }

  function handleInputChange(value: string): void {
    if (!field.editable || !isWithinMinMax(field, value.length)) return;
    onChange(value);
  }

  function isWithinMinMax(
    field: Omit<FieldObject, 'variable'>,
    value: number
  ): boolean {
    return (
      (!field.maxLimit || field.maxLimit >= value) &&
      (!field.minLimit || field.minLimit <= value)
    );
  }
}
