import React from 'react';

import Icon from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Popover,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import ColorSquare from 'src/components/ColorPicker/ColorSquare';
import { ReactComponent as DatabaseIcon } from 'src/images/icons/database.svg';
import { formatPercentageString } from 'src/utils/formatting';
import { namiLightGray, namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';
import { default as TinyColor, default as tinycolor } from 'tinycolor2';

import BestGradientPicker from '../../../../../../components/ColorPicker/BestGradientPicker';
import {
  useActions,
  useAppSelector,
} from '../../../../../../hooks/redux.hooks';
import PaywallBuilderSlice from '../../../../../../redux/PaywallBuilderSlice';
import { capitalizeString } from '../../../../../../utils/string';
import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

export type GradientPickerProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  showGradientControls?: boolean;
  showDynamicColors?: boolean;
  parentDataSourceRoot?: string | null;
};

export const DynamicallySizedParagraph = styled(Typography.Paragraph)`
  max-width: 110px;
  margin-bottom: 0 !important;
  cursor: pointer;
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  overflow-x: clip;
  :hover {
    color: ${namiPrimaryBlue};
  }
`;

const StyledPickerButton = styled(Button)`
  flex-grow: 1;
  font-size: 13px;
  padding: 5px 4px !important;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const StyledPicker = styled(BestGradientPicker)`
  background-color: white;
  margin-top: -12px;
  padding: 10px;
  border: 0.5px solid ${namiLightGray};
  box-shadow: 0 8px 16px rgba(35, 23, 5, 0.15);
  border-radius: 2px;
  max-width: 316px;
  max-height: 565px;
  overflow-y: scroll;
  overflow-x: clip;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: @nami-smoke;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: @nami-light-gray;
  }
`;

const PRESET_COLORS = [
  // Source: https://github.com/casesandberg/react-color/blob/bc9a0e1dc5d11b06c511a8e02a95bd85c7129f4b/docs/documentation/03.04-individual.md
  '#D0021B',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#FFADD2',
  '#003A8C',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#F0F0F0',
  '#FFFFFF',
].map((color) => {
  const { r, g, b, a } = TinyColor(color).toRgb();
  return `rgb(${r} ${g} ${b} / ${a})`;
});

export default function GradientPicker({
  defaultValue,
  onChange,
  controlled = false,
  showGradientControls = true,
  showDynamicColors = true, //TODO
  parentDataSourceRoot = null,
  ...field
}: GradientPickerProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const parentDataSourceLoopVariable = 'block'; //TODO
  const { inDarkMode, hasDarkMode, customVariableOptions } = useAppSelector(
    ({ paywallBuilder }) => {
      const inDarkMode = paywallBuilder.inDarkMode;
      const hasDarkMode = !!field.darkModeVariable;
      const customObjectSchema = paywallBuilder.customObjectSchema;
      const customVariableOptions = customObjectSchema
        .filter((value) => {
          return (
            parentDataSourceRoot &&
            value.type !== 'array' &&
            value.type !== 'boolean' &&
            value.title.includes(parentDataSourceRoot || '')
          );
          //TODO - further filter to only values with title 'color', 'hue', etc?
        })
        .map((value) => {
          return {
            label: parentDataSourceRoot
              ? value.title.replace(`${parentDataSourceRoot}.`, '')
              : value.title,
            value: parentDataSourceRoot
              ? value.title.replace(
                  `${parentDataSourceRoot}.`,
                  `\${${parentDataSourceLoopVariable}.`
                ) + '}'
              : `\${launch.${value.title}}`,
          };
        });
      return { inDarkMode, hasDarkMode, customVariableOptions };
    }
  );

  const value = controlled
    ? formatValueForColorPicker(field.value || defaultValue || '#FFFFFF')
    : '#FFFFFF';

  const valueIsGradient = value.includes('gradient');
  const valueIsDynamicVariable =
    parentDataSourceRoot && value.includes('${block.'); //TODO

  const buttonText = valueIsDynamicVariable
    ? value.replace(`\${${parentDataSourceLoopVariable}.`, '').replace('}', '')
    : valueIsGradient
    ? capitalizeString(value.split('-')[0])
    : tinycolor(value).toHexString().toUpperCase();

  const opacityValue = tinycolor(value).getAlpha();

  return (
    <Form.Item label={<InputLabel {...field} />}>
      <Wrapper>
        <Popover
          overlayInnerStyle={{
            background: 'transparent',
            boxShadow: 'none',
          }}
          overlayStyle={{ padding: 0 }}
          trigger="click"
          content={
            <StyledPicker
              value={value as string}
              onChange={(value) => handleValueChange(formatValueForSDK(value))}
              hideAdvancedSliders
              hideEyeDrop
              hideOpacity={!field.showOpacity}
              hideColorGuide
              hideInputType
              hideControls={!showGradientControls}
              showRadialGradient={false}
              presets={PRESET_COLORS}
              inDarkMode={inDarkMode}
              showDarkModeSwitcher={hasDarkMode}
              onDarkModeChange={actions.setDarkMode}
              dynamicColors={customVariableOptions}
            />
          }
        >
          {valueIsDynamicVariable ? (
            <StyledPickerButton disabled={!field.editable}>
              <Row gutter={0}>
                <Tooltip title={buttonText}>
                  <Space size={4}>
                    <Icon component={DatabaseIcon} style={{ fontSize: 14 }} />
                    <DynamicallySizedParagraph>
                      {buttonText}
                    </DynamicallySizedParagraph>
                  </Space>
                </Tooltip>
              </Row>
            </StyledPickerButton>
          ) : (
            <StyledPickerButton disabled={!field.editable}>
              <Row gutter={0}>
                <Col flex={'28px'}>
                  <ColorSquare
                    color={value}
                    colorIsGradient={valueIsGradient}
                  />
                </Col>
                <Col flex="auto" style={{ textAlign: 'left' }}>
                  {buttonText}
                </Col>
                <Col flex="28px">
                  {!valueIsGradient && field.showOpacity && (
                    <span>{formatPercentageString(opacityValue)}</span>
                  )}
                </Col>
              </Row>
            </StyledPickerButton>
          )}
        </Popover>
      </Wrapper>
    </Form.Item>
  );

  function handleValueChange(value: any): void {
    if (!field.editable) return;
    onChange(value);
  }

  function formatValueForSDK(value: string): string {
    if (value.includes('block')) return value; //TODO
    if (!value.includes('gradient')) TinyColor(value).toHex8String();
    // We force the pattern `rgb(<r> <g> <b> / <a>)` for easier RegEx matches
    // on the SDK side
    return value.replace(
      /(rgba?)?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(,\s*([0-1](\.\d+)?))?\)/gi,
      (...args) => {
        const [, label, r, g, b, , a = 1] = args;
        return `${label}(${r} ${g} ${b} / ${a})`;
      }
    );
  }

  function formatValueForColorPicker(value: string): string {
    // Color Picker requires format `rgb|a(<r>, <g>, <b>, <a>)
    return value.replace(
      /(rgba?)?\((\d{1,3})\s*(\d{1,3})\s*(\d{1,3})(\s*\/\s*([0-1](\.\d+)?))?\)/gi,
      (...args) => {
        const [, , r, g, b, , a] = args;
        return `rgba(${r},${g}, ${b}, ${a})`;
      }
    );
  }
}
