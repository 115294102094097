import React from 'react';

import { TDeviceOrientation } from 'src/api/types/paywall.types';
import styled from 'styled-components';

import {
  breakpointXl,
  breakpointXxl,
  colorBlack,
  colorNegative,
} from '../../variables';

type IPadProps = {
  orientation: TDeviceOrientation;
  children: JSX.Element | JSX.Element[];
  inFocusedState: boolean; //Unused
};

const LandscapeWrapper = styled.div<{
  scale: number;
  height: number;
}>`
  --height: ${({ height }) => height}px;
  width: calc(var(--height) * 1.435654);
  height: var(--height);
  background-color: ${colorBlack};
  margin: 0 auto;
  border-radius: 55px;
  padding: 40px;
  z-index: 1;
  box-shadow: 0 35px 60px -24px rgba(0 0 0 / 0.4);
  user-select: none;

  ${({ scale }) => (scale ? `transform: scale(${scale});` : '')}

  @media only screen and (max-width: ${breakpointXxl}) {
    transform: scale(0.5);
    margin: -5rem auto 0 -5rem;
  }

  @media only screen and (max-width: ${breakpointXl}) {
    transform: scale(0.35);
    margin: -10rem auto 0 -15rem;
  }
`;

const PortraitWrapper = styled.div<{
  scale: number;
  height: number;
}>`
  --height: ${({ height }) => height}px;
  width: calc(var(--height) * 0.696546661);
  height: var(--height);
  background-color: ${colorBlack};
  border-radius: 55px;
  margin: 0 auto;
  padding: 40px;
  z-index: 2;
  box-shadow: 0 35px 60px -24px rgba(0 0 0 / 0.4);
  user-select: none;

  transform-origin: center center;
  ${({ scale }) => (scale ? `transform: scale(${scale});` : '')}

  /*TODO*/
  @media only screen and (max-width: ${breakpointXxl}) {
    transform: scale(0.6);
    // margin: -3rem auto 0 auto;
  }

  /*TODO*/
  @media only screen and (max-width: ${breakpointXl}) {
    transform: scale(0.45);
    // margin: -7rem auto 0 auto;
  }
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorBlack};
  position: relative;
  z-index: 2;
`;

const TabletView = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorNegative};
  border-radius: 12px;
  position: relative;
  overflow: hidden;
`;

const TabletInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: initial;
`;

export default function IPad({ children, orientation }: IPadProps) {
  const height = orientation === 'landscape' ? 820 : 1106;
  const scale = 0.7;
  const Wrapper =
    orientation === 'landscape' ? LandscapeWrapper : PortraitWrapper;
  return (
    <Wrapper scale={scale} height={height}>
      <Body>
        <TabletView>
          <TabletInner>{children}</TabletInner>
        </TabletView>
      </Body>
    </Wrapper>
  );
}
