import React, { useEffect, useState } from 'react';

import Icon, { NumberOutlined } from '@ant-design/icons';
import { Form, InputNumber, Radio, Space } from 'antd';
import styled from 'styled-components';

import { ReactComponent as FitWidthIcon } from '../../../../../../images/material-symbols-light-fit.svg';
import { ReactComponent as FitHeightIcon } from '../../../../../../images/material-symbols-light-fitheight.svg';
import { ReactComponent as FillHeightIcon } from '../../../../../../images/material-symbols-light_height.svg';
import { ReactComponent as FillWidthIcon } from '../../../../../../images/material-symbols-light_width.svg';
import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type HeightWidthInputProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  variant: 'height' | 'width';
  includeFit?: boolean;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
};

const StyledInput = styled(InputNumber)`
  width: 56px;
  font-size: small;
`;

export default function HeightWidthInput({
  defaultValue,
  onChange,
  controlled = false,
  variant,
  includeFit = true,
  ...field
}: HeightWidthInputProps) {
  const [manualValue, setManualValue] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<
    '100%' | 'fitContent' | '_manual'
  >(field.value);

  useEffect(() => {
    if (field.value !== '100%' && field.value !== 'fitContent') {
      setSelectedOption('_manual');
      setManualValue(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const itemProps = {
    name: controlled ? undefined : field.variable,
    label: <InputLabel {...field} />,
  };
  let options = [
    {
      value: '100%',
      label: (
        <Space size={4}>
          <Icon
            component={variant === 'width' ? FillWidthIcon : FillHeightIcon}
            style={{ fontSize: 20, transform: 'translateY(3px)' }}
          />
          <>Fill</>
        </Space>
      ),
    },
  ];
  if (includeFit) {
    options.push({
      value: 'fitContent',
      label: (
        <Space size={4}>
          <Icon
            component={variant === 'width' ? FitWidthIcon : FitHeightIcon}
            style={{ fontSize: 20, transform: 'translateY(3px)' }}
          />
          <>Fit</>
        </Space>
      ),
    });
  }
  options.push({
    value: '_manual',
    label: (
      <Space size={4}>
        <NumberOutlined />
        <StyledInput
          size="small"
          min={0}
          max={1000}
          value={manualValue}
          onMouseDown={() => handleChange('_manual')}
          onChange={(value) => {
            setManualValue((value as number) || 0);
            handleChange('_manual');
          }}
          onKeyUp={(e) => {
            handleChange('_manual');
          }}
        />
      </Space>
    ),
  });

  return (
    <Form.Item {...itemProps}>
      <Radio.Group
        disabled={!field.editable}
        value={selectedOption}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options.map(({ label, value }) => (
          <Radio.Button
            key={value}
            value={value}
            style={{ fontSize: 13, padding: '0 12px' }}
          >
            {label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );

  function handleChange(value: any) {
    setSelectedOption(value);
    if (value === '_manual') {
      onChange(manualValue);
    } else {
      onChange(value);
    }
  }
}
