import React from 'react';

import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';

interface ColorSquareProps {
  color: string;
  colorIsGradient?: boolean;
}

const ColorSquareFull = styled.div<{ color: string }>`
  ${({ color }) => {
    return css`
      height: 22px;
      width: 22px;
      border-radius: 3px;
      border: 0.5px solid #ebebeb;
      display: flex;
      flex-drection: row;
      background: ${color};
    `;
  }}
`;

const ColorSquareHalf = styled.div<{ color: string }>`
  ${({ color }) => {
    return css`
      background: ${color};
      width: 50%;
      height: 100%;
    `;
  }}
`;

const ColorSquareWrapper = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 3px;
  border: 0.5px solid #ebebeb;
  display: flex;
  flex-drection: row;
`;

export default function ColorSquare({
  color,
  colorIsGradient,
}: ColorSquareProps) {
  const colorMinusOpacity = tinycolor(color).toHexString().toUpperCase();

  if (colorIsGradient) {
    return <ColorSquareFull color={color} />;
  }
  return (
    <ColorSquareWrapper>
      <ColorSquareHalf color={colorMinusOpacity} />
      <ColorSquareHalf color={color} />
    </ColorSquareWrapper>
  );
}
