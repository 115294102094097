import React, { useContext, useState } from 'react';

import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import {
  ComputerOutlined,
  ConnectedTvOutlined,
  SmartphoneOutlined,
  TabletMacOutlined,
} from '@mui/icons-material';
import {
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { AppContext } from 'src/AppContextProvider';
import { TAppliedFilters } from 'src/api/types/analytics.types';
import {
  PaywallType,
  TFormFactorOrientation,
} from 'src/api/types/paywall.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import Responsive from 'src/components/Responsive/Responsive';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import ScheduledDot from 'src/components/StatusDots/ScheduledDot';
import {
  useArchivePaywallMutation,
  usePaywallRedirect,
} from 'src/hooks/queries/paywall.hooks';
import { capitalizeString } from 'src/utils/string';
import { namiDarkGray, namiMediumGray, namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

import {
  findBackgroundColor,
  findBackgroundImage,
} from '../../../utils/paywall';
import { PaywallBackground } from '../campaigns/detail/SegmentFieldSection';
import DuplicatePaywallModal from './PaywallBuilder/modals/DuplicatePaywallModal';
import { RuleTag } from './PaywallBuilder/modals/PaywallSettingsModal';
import { pullOutDeviceOrientation, pullOutFormFactor } from './utils/functions';

export const formFactorIcons: Record<TFormFactorOrientation, React.ReactNode> =
  {
    phone: (
      <SmartphoneOutlined
        style={{
          color: namiDarkGray,
          fontSize: 16,
          transform: 'translateY(3px)',
        }}
      />
    ),
    'tablet-portrait': (
      <TabletMacOutlined
        style={{
          color: namiDarkGray,
          fontSize: 16,
          transform: 'translateY(3px)',
        }}
      />
    ),
    'tablet-landscape': (
      <TabletMacOutlined
        style={{
          color: namiDarkGray,
          fontSize: 16,
          transform: 'translateY(3px) rotate(270deg)',
        }}
      />
    ),
    'tablet-rotateable': (
      <TabletMacOutlined
        style={{
          color: namiDarkGray,
          fontSize: 16,
          transform: 'translateY(3px) rotate(270deg)',
        }}
      />
    ),
    television: (
      <ConnectedTvOutlined
        style={{
          color: namiDarkGray,
          fontSize: 16,
          transform: 'translateY(3px)',
        }}
      />
    ),
    desktop: (
      <ComputerOutlined
        style={{
          color: namiDarkGray,
          fontSize: 16,
          transform: 'translateY(3px)',
        }}
      />
    ),
  };

type PaywallTableProps = {
  paywalls: PaywallType[];
  loading: boolean;
  pagination: false | TablePaginationConfig;
};

const NameLink = styled.span`
  cursor: pointer;
  font-weight: 500;
  :hover {
    color: ${namiPrimaryBlue};
    text-decoration: underline;
  }
`;

export default function PaywallTable({
  paywalls,
  loading,
  pagination,
}: PaywallTableProps) {
  const { userHasEntitlement } = useContext(AppContext);
  const redirectToPaywall = usePaywallRedirect();
  const [paywallToDuplicate, setPaywallToDuplicate] = useState<string | null>(
    null
  );
  const archivePaywallMutation = useArchivePaywallMutation();

  const dataSource = paywalls.map((paywall) => ({
    ...paywall,
    key: paywall.id,
  }));

  return (
    <>
      <Responsive size="mdUp">
        <Table
          loading={loading}
          rowKey="id"
          dataSource={dataSource}
          scroll={{ x: 1300 }}
          columns={getColumns('mdUp')}
          pagination={pagination}
          className="horizontalScrollTable"
        />
      </Responsive>
      <Responsive size="mdDown">
        <Table
          loading={loading}
          rowKey="id"
          dataSource={dataSource}
          scroll={{ x: 1200 }}
          columns={getColumns('mdDown')}
          pagination={pagination}
          className="horizontalScrollTable"
        />
      </Responsive>
      <DuplicatePaywallModal
        open={!!paywallToDuplicate}
        paywallId={paywallToDuplicate!}
        onCancel={() => setPaywallToDuplicate(null)}
        onDuplicateSettled={() => setPaywallToDuplicate(null)}
      />
    </>
  );

  function renderCampaignsList(paywall: PaywallType): JSX.Element {
    const paywallRules = paywall.rules || [];
    const selectedRules = paywallRules.slice(0, 4);
    const numRemainingRules = paywallRules.length - 4;
    return (
      <Space direction="horizontal" size={6} wrap>
        {selectedRules.length > 0 ? (
          selectedRules.map((rule) => <RuleTag key={rule.id} {...rule} />)
        ) : (
          <span style={{ fontStyle: 'italic' }}>None</span>
        )}
        {numRemainingRules > 0 && (
          <Typography.Link onClick={() => redirectToPaywall(paywall)}>
            <Tag>+ {numRemainingRules} more</Tag>
          </Typography.Link>
        )}
      </Space>
    );
  }

  function getLiveBadge(paywall: PaywallType): React.ReactNode {
    const rules = paywall.rules || [];
    if (rules.length === 0) return null;
    const isLive = rules.some((rule) => rule.enabled && rule.label_enabled);
    const onlyScheduled = rules.every(
      (rule) => rule.not_before && moment(rule.not_before).isAfter(moment())
    );
    return onlyScheduled ? (
      <span>
        <ScheduledDot />
      </span>
    ) : isLive ? (
      <span>
        <LiveBadgeDot />
      </span>
    ) : null;
  }

  function getFormFactor(paywall: PaywallType): React.ReactNode {
    const device = pullOutFormFactor(paywall.template);
    const orientation = pullOutDeviceOrientation(paywall.template);
    return (
      <Space direction="horizontal" size="small">
        {formFactorIcons[orientation]}
        <Typography.Text>{capitalizeString(device)}</Typography.Text>
      </Space>
    );
  }

  function getReportingLink(paywallId: string): React.ReactNode {
    const filters: TAppliedFilters = {
      filter_by_paywall: {
        identifier: 'Paywall.id',
        operator: 'equals',
        values: [paywallId],
      },
    };

    const urlMeta = '?filters=' + JSON.stringify([filters.filter_by_paywall]);

    return (
      <IconActionButton
        type="text"
        icon={<LineChartOutlined style={{ fontSize: '13px' }} />}
        href={`/insights/impressions/${urlMeta}`}
        size="small"
      >
        Impressions
      </IconActionButton>
    );
  }

  function getDuplicateLink(paywall: PaywallType): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        icon={<CopyOutlined style={{ fontSize: '12px' }} />}
        size="small"
        onClick={() => setPaywallToDuplicate(paywall.id)}
        disabled={!userHasEntitlement('app.paywall.create')}
      >
        Duplicate
      </IconActionButton>
    );
  }

  function getArchiveLink(paywall: PaywallType): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        icon={<DeleteOutlined style={{ fontSize: '12px' }} />}
        size="small"
        onClick={() =>
          archivePaywallMutation.mutate({
            paywall_id: paywall.id,
            archived: !!paywall.archived,
          })
        }
        disabled={
          !userHasEntitlement('app.paywall.update') ||
          paywall.rules.some((rule) => rule.archived === false)
        }
      >
        {paywall.archived ? 'Unarchive' : 'Archive'}
      </IconActionButton>
    );
  }

  function getEditingLink(paywall: PaywallType): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        icon={<EditOutlined style={{ fontSize: '13px' }} />}
        size="small"
        onClick={() => redirectToPaywall(paywall)}
        disabled={!userHasEntitlement('app.paywall.update')}
      >
        Edit
      </IconActionButton>
    );
  }

  function getColumns(size: 'mdUp' | 'mdDown'): ColumnsType<PaywallType> {
    return [
      {
        title: <Typography.Text strong>Name</Typography.Text>,
        render: (paywall: PaywallType) => (
          <Space direction="horizontal">
            <PaywallBackground
              height={32}
              preview={false}
              width={32}
              color={findBackgroundColor(paywall)}
              src={findBackgroundImage(paywall) || undefined}
              onClick={() => paywall && redirectToPaywall(paywall)}
              style={{ cursor: 'pointer' }}
            />
            <NameLink onClick={() => paywall && redirectToPaywall(paywall)}>
              {paywall.name}
            </NameLink>
            {getLiveBadge(paywall)}
            {paywall.archived && (
              <DeleteOutlined style={{ fontSize: 12, color: namiMediumGray }} />
            )}
          </Space>
        ),
        fixed: size === 'mdUp' ? 'left' : undefined,
        width: 300,
      },
      {
        title: <Typography.Text strong>Campaigns</Typography.Text>,
        render: (paywall: PaywallType) => renderCampaignsList(paywall),
        width: 300,
      },
      {
        title: (
          <Tooltip
            title={
              'Use Description to describe the paywall design, placement, and product selection to your team.'
            }
          >
            <Typography.Text strong>Description</Typography.Text>
          </Tooltip>
        ),
        dataIndex: 'description',
        render: (description: string) => (
          <Typography.Text style={{ fontSize: 'small' }}>
            {description}
          </Typography.Text>
        ),
        width: 300,
      },
      {
        title: <Typography.Text strong>Template</Typography.Text>,
        render: (paywall: PaywallType) => (
          <Typography.Text>
            {paywall.type === 'component'
              ? (paywall.template.codename || '') +
                ' v' +
                paywall.template.version
              : 'Legacy'}
          </Typography.Text>
        ),
        width: 200,
        responsive: ['lg'],
      },
      {
        title: <Typography.Text strong>Form Factor</Typography.Text>,
        render: (paywall: PaywallType) => getFormFactor(paywall),
        width: 150,
        responsive: ['lg'],
      },
      {
        title: <Typography.Text strong>Created</Typography.Text>,
        dataIndex: 'created_date',
        render: (created_date: string) => (
          <Tooltip title={moment(created_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'pointer' }}>
              {moment(created_date).fromNow()}
            </span>
          </Tooltip>
        ),
        width: 150,
      },
      {
        title: <Typography.Text strong>Updated</Typography.Text>,
        dataIndex: 'updated_date',
        render: (updated_date: string) => (
          <Tooltip title={moment(updated_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'pointer' }}>
              {moment(updated_date).fromNow()}
            </span>
          </Tooltip>
        ),
        width: 150,
        responsive: ['lg'],
      },
      {
        title: <Typography.Text strong>Actions</Typography.Text>,
        render: (paywall: PaywallType) => (
          <Space direction="horizontal" size="small">
            {getEditingLink(paywall)}
            {getDuplicateLink(paywall)}
            {getArchiveLink(paywall)}
            {userHasEntitlement('app.analytics.impressions') &&
              getReportingLink(paywall.id)}
          </Space>
        ),
        width: 400,
      },
    ];
  }
}
