import React from 'react';

import styled from 'styled-components';

import { colorBlack, colorNegative } from '../../variables';

type IPhoneProps = {
  className?: string;
  children: JSX.Element | JSX.Element[];
  inFocusedState: boolean; //Unused
};

const Wrapper = styled.div<{ height: number; scale?: number }>`
  --height: ${({ height }) => height}px;
  width: calc(var(--height) * 0.483221477);
  height: var(--height);
  background-color: ${colorBlack};
  border-radius: 60px;
  padding: 5px;
  margin: 0 auto 0 auto;
  z-index: 2;
  box-shadow: 0 35px 60px -24px rgba(0 0 0 / 0.4);

  ${({ scale }) => (scale ? `transform: scale(${scale});` : '')}
  &::before,
  &::after {
    content: '';
    height: 4px;
    width: 100%;
    background-color: rgba(${colorBlack}, 0.25);
    position: absolute;
    left: 0;
  }

  &::before {
    top: 50px;
  }

  &::after {
    bottom: 50px;
  }
`;

const Body = styled.div`
  border-radius: 60px;
  width: 100%;
  height: 100%;
  background-color: ${colorBlack};
  position: relative;
  padding: 9px;
  z-index: 2;
`;

const PhoneView = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorNegative};
  border-radius: 47px;
  position: relative;
  overflow: hidden;
`;

export const PhoneInner = styled.div`
  height: 100%;
  justify-content: initial;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function IPhone({ children, ...props }: IPhoneProps) {
  return (
    <Wrapper height={880} scale={0.7} {...props}>
      <Body>
        <PhoneView>
          <PhoneInner>{children}</PhoneInner>
        </PhoneView>
      </Body>
    </Wrapper>
  );
}
