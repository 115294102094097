import React, { useMemo, useState } from 'react';

import Icon, {
  CloseOutlined,
  DeleteOutlined,
  DollarCircleOutlined,
  EyeOutlined,
  GiftOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  UnlockFilled,
} from '@ant-design/icons';
import {
  AccessibilityNewRounded,
  AccountBoxRounded,
  CodeOutlined,
  ContentCopyOutlined,
  DataObjectOutlined,
  EmojiPeopleRounded,
  Grade,
  LaunchOutlined,
  Science,
  SettingsOutlined,
  ToggleOnOutlined,
  TouchAppSharp,
  VideoSettingsOutlined,
  ViewAgendaRounded,
  ViewCarouselOutlined,
} from '@mui/icons-material';
import { createSelector } from '@reduxjs/toolkit';
import {
  Avatar,
  Button,
  Card,
  Divider,
  Input,
  List,
  Result,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { useHistory, useParams } from 'react-router-dom';
import api from 'src/api';
import { ProductType } from 'src/api/types/main.types';
import { TProductGroup } from 'src/api/types/paywall.types';
import {
  TAvailableCapability,
  TTemplateCapability,
  TTemplateCapabilityTag,
  TTemplateCapabilityTagReadable,
} from 'src/api/types/paywallTemplate.types';
import NamiIcon from 'src/components/NamiIcon/NamiIcon';
import BuilderTreeWebPaywall from 'src/components/WebPaywalls/BuilderTreeWebPaywall';
import CustomFontWebPaywall from 'src/components/WebPaywalls/CustomFontWebPaywall';
import GoogleFontWebPaywall from 'src/components/WebPaywalls/GoogleFontWebPaywall';
import LaunchContextWebPaywall from 'src/components/WebPaywalls/LaunchContextWebPaywall';
import { useAppContext } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { ReactComponent as DatabaseIcon } from 'src/images/icons/database.svg';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { capitalizeFirstCharacter } from 'src/utils/string';
import { PartialAttrs } from 'src/utils/types';
import {
  namiBrightBlue,
  namiGreen,
  namiMediumGray,
  namiYellow,
} from 'src/variables';
import styled from 'styled-components';

import { ReactComponent as FontIcon } from '../../../../../images/font.svg';
import ProductGroupCapabilitySettingsModal from '../modals/ProductGroupCapabilitySettingsModal';

type CapabilitiesListProps = {
  startLoading: () => void;
  stopLoading: () => void;
  onProductGroupChange: () => void;
};

const StyledListItem = styled(List.Item)`
  ul {
    text-align: center;
  }

  ul.ant-list-item-action {
    margin-top: 10px;
  }

  .ant-list-item-meta-avatar {
    margin-right: 12px;
  }

  div {
    margin-bottom: 0px !important;
  }

  h4 {
    font-weight: 500 !important;
    font-size: 15px !important;
    margin-bottom: 4px !important;
  }

  .ant-list-item-meta-description {
    color: rgba(76, 84, 90, 0.85);
  }

  ul.ant-list-item-action > li {
    padding: 0 8px !important;
  }

  ul.ant-list-item-action > li > button {
    color: rgba(76, 84, 90, 0.9);
  }

  ul.ant-list-item-action > li > a {
    color: rgba(76, 84, 90, 0.9);
  }
`;

const selector = createSelector(
  [
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.capabilities'],
    ({ paywallBuilder: { productGroups } }: RootState) => productGroups,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.displaySingleGroup'],
    ({ paywallBuilder: { formFactor } }: RootState) => formFactor,
  ],
  (capabilities, groups, displaySingleGroup, formFactor) => ({
    capabilities: capabilities || [],
    groups,
    displaySingleGroup,
    formFactor,
    supportSingleGroup: formFactor !== 'television',
  })
);

export const capabilityPlanMap: Record<TTemplateCapability, ProductType> = {
  accessibility: 'business',
  byo_creative: 'starter',
  carousel: 'team',
  conditional_product_groups: 'enterprise',
  conditional_product_offer: 'business',
  conditional_product_trial: 'team',
  conditional_product_intro: 'team',
  conditionals: 'team',
  custom_font: 'starter',
  custom_launch_context: 'enterprise',
  custom_launch_object: 'enterprise',
  dark_mode: 'starter',
  deeplink: 'team',
  dynamic_signin: 'business',
  featured_product: 'starter',
  google_fonts: 'starter',
  honest_paywall: 'starter',
  multipage: 'enterprise',
  offer_code: 'team',
  personalization: 'business',
  product_groups: 'business',
  selected_product: 'starter',
  table: 'enterprise',
  toggle_product_offer: 'business',
  toggle_product_trial: 'team',
  upload_list_v2_icons: 'starter',
  upload_list_v2_images: 'business',
  video: 'enterprise',
};

export default function CapabilitiesList({
  startLoading,
  stopLoading,
  onProductGroupChange,
}: CapabilitiesListProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const {
    capabilities,
    groups,
    displaySingleGroup,
    formFactor,
    supportSingleGroup,
  } = useAppSelector(selector);
  const appContext = useAppContext();
  const history = useHistory();
  const [paywallOpen, setPaywallOpen] = useState<string | null>(null);
  const [capabilitiesSearch, setCapabilitiesSearch] = useState<string>('');
  const [selectedTag, setSelectedTag] = useState<TTemplateCapabilityTag | null>(
    null
  );
  const userCanEdit = appContext.userHasEntitlement(
    'app.paywall.component.create'
  );

  const [productGroupCapabilityModalOpen, setProductGroupCapabilityModalOpen] =
    useState(false);
  const paywallId = useParams<{ paywallID: string }>().paywallID;

  const allCapabilities: Array<TAvailableCapability> = [
    {
      title: 'Featured Styling',
      avatar: (
        <Avatar
          icon={<Grade style={{ transform: 'translateY(4px)' }} />}
          style={{ backgroundColor: namiGreen }}
        />
      ),
      capability: 'featured_product',
      description:
        'Draw attention with special styling or badge applied to 1 product',
      entitlement: 'app.paywall.capabilities.featured_product',
      formFactors: ['phone', 'tablet', 'desktop'],
      tags: ['product_display'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableFeatured}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/featured-styling"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enableFeatured}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/featured-styling"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableFeatured}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Selected Styling',
      avatar: (
        <Avatar
          icon={<TouchAppSharp style={{ transform: 'translateY(4px)' }} />}
          style={{ backgroundColor: '#9254DE' }}
        />
      ),
      capability: 'selected_product',
      description:
        'Allow users to select a product before tapping the Purchase button',
      entitlement: 'app.paywall.capabilities.selected_product',
      formFactors: ['phone', 'tablet', 'desktop'],
      tags: ['product_display'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableSelected}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/selected-styling"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enableSelected}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/selected-styling"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableSelected}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    // {
    //   title: 'Google Fonts',
    //   avatar: (
    //     <Avatar
    //       icon={<GoogleFontIcon style={{ transform: 'translateY(3px)' }} />}
    //       style={{ backgroundColor: '#1890FF' }}
    //     />
    //   ),
    //   capability: 'google_fonts',
    //   description: 'Choose from a selection of Google Fonts',
    //   entitlement: 'app.paywall.capabilities.custom_font',
    //   noAdd: true,
    //   inactiveActions: [
    //     <Button
    //       type="text"
    //       icon={<UnlockFilled style={{ color: namiBrightBlue }} />}
    //       onClick={() => setPaywallOpen('google_fonts')}
    //     >
    //       Unlock
    //     </Button>,
    //     <Button
    //       type="text"
    //       icon={<InfoCircleOutlined />}
    //       href="https://learn.namiml.com/public-docs/v/template-builder/capabilities/google-fonts"
    //       target="_blank"
    //     >
    //       Learn More
    //     </Button>,
    //   ],
    //   activeActions: [
    //     <Button
    //       type="text"
    //       icon={<InfoCircleOutlined />}
    //       href="https://learn.namiml.com/public-docs/v/template-builder/capabilities/google-fonts"
    //       target="_blank"
    //     >
    //       Learn More
    //     </Button>,
    //   ],
    // },
    {
      title: 'Trial Eligibility',
      avatar: (
        <Avatar
          icon={<Science style={{ transform: 'translateY(3px)' }} />}
          style={{ backgroundColor: '#FA8C16' }}
        />
      ),
      capability: 'conditional_product_trial',
      description:
        'Display product and paywall copy based on user’s eligibility for a free trial',
      entitlement: 'app.paywall.capabilities.conditional_product_trial',
      formFactors: ['phone', 'tablet', 'television', 'desktop'],
      tags: ['product_display', 'offers'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableTrialEligible}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/trial-eligibility"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enableTrialEligible}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/trial-eligibility"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableTrialEligible}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Deeplink Button',
      avatar: (
        <Avatar
          icon={
            <LaunchOutlined
              style={{ fontSize: 18, transform: 'translateY(3px)' }}
            />
          }
          style={{ backgroundColor: '#40a9ff' }}
        />
      ),
      capability: 'deeplink',
      description:
        'Display a button deeplinking the user to another screen in your app',
      entitlement: 'app.paywall.capabilities.deeplink',
      formFactors: ['phone', 'tablet', 'television'],
      tags: ['components'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableDeeplink}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/deeplink-button"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enableDeeplink}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/deeplink-button"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableDeeplink}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Intro Eligibility',
      avatar: (
        <Avatar
          icon={<EmojiPeopleRounded style={{ transform: 'translateY(4px)' }} />}
          style={{ backgroundColor: '#996633' }}
        />
      ),
      capability: 'conditional_product_intro',
      description:
        'Display product and paywall copy based on active introductory offers',
      entitlement: 'app.paywall.capabilities.conditional_product_intro',
      formFactors: ['phone', 'tablet', 'television', 'desktop'],
      tags: ['product_display', 'offers'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableIntroEligible}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/introductory-offer-eligibility"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enableIntroEligible}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/introductory-offer-eligibility"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableIntroEligible}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Carousel',
      avatar: (
        <Avatar
          icon={
            <ViewCarouselOutlined style={{ transform: 'translateY(4px)' }} />
          }
          style={{ backgroundColor: '#208653' }}
        />
      ),
      capability: 'carousel',
      description:
        'Encourage user interaction with a swipeable carousel of images and value props.',
      entitlement: 'app.paywall.capabilities.carousel',
      formFactors: ['phone', 'tablet', 'desktop'],
      tags: ['components', 'design'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableCarousel}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/carousel"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enableCarousel}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/carousel"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableCarousel}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Login Check',
      avatar: (
        <Avatar
          icon={<AccountBoxRounded style={{ transform: 'translateY(4px)' }} />}
          style={{ backgroundColor: '#ff85c0' }}
        />
      ),
      capability: 'dynamic_signin',
      description: "Display buttons and copy based on user's sign in state",
      entitlement: 'app.paywall.capabilities.dynamic_signin',
      formFactors: ['phone', 'tablet', 'television', 'desktop'],
      tags: [],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableSigninCheck}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/login-check"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enableSigninCheck}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/login-check"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableSigninCheck}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Product Groups',
      avatar:
        displaySingleGroup && supportSingleGroup ? (
          <Avatar
            icon={
              <ToggleOnOutlined
                style={{
                  fontSize: 18,
                  transform: 'translateY(2.5px)',
                }}
              />
            }
            style={{ backgroundColor: '#13c2c2' }}
          />
        ) : (
          <Avatar
            icon={
              <ViewAgendaRounded
                style={{
                  fontSize: 18,
                  transform: 'translateY(2.5px)',
                }}
              />
            }
            style={{ backgroundColor: '#13c2c2' }}
          />
        ),
      capability: 'product_groups',
      description:
        displaySingleGroup && supportSingleGroup
          ? 'Display 2 groups or tiers of products and a toggle to switch between groups.'
          : 'Display 2 groups or tiers of products on the same paywall.',
      entitlement: 'app.paywall.capabilities.product_groups',
      formFactors: ['phone', 'tablet', 'television', 'desktop'],
      tags: ['product_display', 'components'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableProductGroups}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/product-groups"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enableProductGroups}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/product-groups"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          icon={
            <SettingsOutlined
              style={{
                fontSize: 15,
                transform: 'translateY(2.5px)',
                marginRight: 6,
              }}
            />
          }
          onClick={() => setProductGroupCapabilityModalOpen(true)}
        >
          Settings
        </Button>,
        <Button
          type="text"
          onClick={disableProductGroups}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Promo Offer Purchasing',
      avatar: (
        <Avatar
          icon={<GiftOutlined />}
          style={{ backgroundColor: '#FF5B74' }}
        />
      ),
      capability: 'conditional_product_offer',
      description: 'Display and purchase promotional offers.',
      entitlement: 'app.paywall.capabilities.conditional_product_offer',
      formFactors: ['phone', 'tablet', 'television', 'desktop'],
      tags: ['product_display', 'offers'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enablePromoEligible}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/offer-eligibility-and-purchasing"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enablePromoEligible}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/offer-eligibility-and-purchasing"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disablePromoEligible}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Screenreader Text',
      avatar: (
        <Avatar
          icon={
            <AccessibilityNewRounded
              style={{ transform: 'translateY(3.5px)' }}
            />
          }
          style={{ backgroundColor: '#b37feb' }}
        />
      ),
      capability: 'accessibility',
      description:
        'Customize paywall screenreader text for visually impaired users',
      entitlement: 'app.paywall.capabilities.accessibility',
      formFactors: ['phone', 'tablet'],
      tags: [],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableScreenreaderText}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/screenreader-text"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={enableScreenreaderText}
          icon={
            userCanEdit ? (
              <EyeOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Preview
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/screenreader-text"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableScreenreaderText}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Brand Fonts',
      avatar: (
        <Avatar
          icon={<FontIcon style={{ transform: 'translateY(3px)' }} />}
          style={{ backgroundColor: namiYellow }}
        />
      ),
      capability: 'custom_font',
      description: 'Upload your brand font files',
      entitlement: 'font.create',
      formFactors: ['phone', 'tablet', 'television', 'desktop'],
      tags: ['design'],
      noAdd: true,
      ungatedInactiveActions: [
        <Button
          type="text"
          icon={<UnlockFilled style={{ color: namiBrightBlue }} />}
          onClick={() => setPaywallOpen('custom_font')}
        >
          Unlock
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-brand-fonts"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          icon={<UnlockFilled style={{ color: namiBrightBlue }} />}
          onClick={() => setPaywallOpen('custom_font')}
        >
          Unlock
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-brand-fonts"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          icon={
            <LaunchOutlined
              style={{
                fontSize: 15,
                transform: 'translateY(2.5px)',
                marginRight: 6,
              }}
            />
          }
          onClick={() => history.push('/settings?tab=brand')}
        >
          Manage
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-brand-fonts"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
    },
    {
      title: 'Conditional Product Groups',
      avatar: (
        <Avatar
          icon={
            <CodeOutlined
              style={{
                fontSize: 18,
                transform: 'translateY(2.5px)',
              }}
            />
          }
          style={{ backgroundColor: '#0050b3' }}
        />
      ),
      capability: 'conditional_product_groups',
      description:
        'Control which product groups are displayed on the paywall from your app code.',
      entitlement: 'app.paywall.capabilities.conditional_product_groups',
      formFactors: ['phone', 'tablet', 'television', 'desktop'],
      tags: ['dynamic_data', 'product_display'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableDynamicProductGroups}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/conditional-product-groups"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={() => setPaywallOpen('launch_context')}
          icon={<UnlockFilled style={{ color: namiBrightBlue }} />}
        >
          Unlock
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/conditional-product-groups"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableDynamicProductGroups}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Custom Variables',
      avatar: (
        <Avatar
          icon={
            <DataObjectOutlined
              style={{
                fontSize: 18,
                transform: 'translateY(2.5px)',
              }}
            />
          }
          style={{ backgroundColor: '#7cb305' }}
        />
      ),
      capability: 'custom_launch_context',
      description:
        'Generate custom variables in app code and insert them into paywall at launch.',
      entitlement: 'app.paywall.capabilities.custom_launch_context',
      formFactors: ['phone', 'tablet', 'television', 'desktop'],
      tags: ['dynamic_data'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableCustomLaunchContext}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-variables"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={() => setPaywallOpen('launch_context')}
          icon={<UnlockFilled style={{ color: namiBrightBlue }} />}
        >
          Unlock
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-variables"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableCustomLaunchContext}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Multipage (Early Access)',
      avatar: (
        <Avatar
          icon={
            <ContentCopyOutlined
              style={{
                fontSize: 17,
                transform: 'translateY(2.5px)',
              }}
            />
          }
          style={{ backgroundColor: '#91C2FF' }}
        />
      ),
      capability: 'multipage',
      description: 'Add a second page to your paywall.',
      entitlement: 'app.paywall.capabilities.multipage',
      formFactors: ['television'], //TODO - phone, tablet, web
      tags: ['design'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableMultipage}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/multipage"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={() => setPaywallOpen('multipage')}
          icon={<UnlockFilled style={{ color: namiBrightBlue }} />}
        >
          Unlock
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/multipage"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableMultipage}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
    {
      title: 'Custom Data Source',
      avatar: (
        <Avatar
          icon={<Icon component={DatabaseIcon} style={{ fontSize: 18 }} />}
          style={{ backgroundColor: '#08979c' }}
        />
      ),
      capability: 'custom_launch_object',
      description:
        'Pass a list of custom data to the paywall for rendering repeating lists.',
      entitlement: 'app.paywall.capabilities.custom_launch_object',
      formFactors: ['phone', 'tablet'],
      tags: ['dynamic_data'],
      ungatedInactiveActions: [
        // <Button
        //   type="text"
        //   onClick={enableCustomObject}
        //   icon={
        //     userCanEdit ? (
        //       <PlusOutlined />
        //     ) : (
        //       <UnlockFilled style={{ color: namiBrightBlue }} />
        //     )
        //   }
        // >
        //   Add
        // </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-data-source"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={() => setPaywallOpen('custom_launch_object')}
          icon={<UnlockFilled style={{ color: namiBrightBlue }} />}
        >
          Unlock
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-data-source"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        // <Button
        //   type="text"
        //   onClick={disableCustomObject}
        //   icon={<DeleteOutlined />}
        //   disabled={!userCanEdit}
        // >
        //   Remove
        // </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-data-source"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
    },
    {
      title: 'Advanced Video Features',
      avatar: (
        <Avatar
          icon={
            <VideoSettingsOutlined
              style={{ fontSize: 18, transform: 'translateY(3.5px)' }}
            />
          }
          style={{ backgroundColor: namiYellow }}
        />
      ),
      capability: 'video',
      description: 'Enable advanced video features',
      entitlement: 'app.paywall.capabilities.video',
      enterpriseOnly: true,
      formFactors: ['phone', 'tablet'], //TODO - tv
      tags: ['dynamic_data', 'components'],
      ungatedInactiveActions: [
        <Button
          type="text"
          onClick={enableAdvancedVideo}
          icon={
            userCanEdit ? (
              <PlusOutlined />
            ) : (
              <UnlockFilled style={{ color: namiBrightBlue }} />
            )
          }
        >
          Add
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/advanced-video-features"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      gatedInactiveActions: [
        <Button
          type="text"
          onClick={() => setPaywallOpen('custom_launch_object')}
          icon={<UnlockFilled style={{ color: namiBrightBlue }} />}
        >
          Unlock
        </Button>,
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/screenreader-text"
          target="_blank"
        >
          Learn More
        </Button>,
      ],
      activeActions: [
        <Button
          type="text"
          onClick={disableAdvancedVideo}
          icon={<DeleteOutlined />}
          disabled={!userCanEdit}
        >
          Remove
        </Button>,
      ],
    },
  ];

  const addedCapabilities = useMemo(() => {
    return allCapabilities.reduce((output, capability) => {
      if (selectedTag && !capability.tags.includes(selectedTag)) return output;
      if (
        capabilitiesSearch.length &&
        !capability.title
          .toLowerCase()
          .includes(capabilitiesSearch.toLowerCase()) &&
        !capability.description
          .toLowerCase()
          .includes(capabilitiesSearch.toLowerCase())
      )
        return output;
      if (capabilities.includes(capability.capability))
        return [...output, capability];
      if (
        capability.noAdd &&
        appContext.planHasEntitlement(capability.entitlement)
      ) {
        return [...output, capability];
      }
      return output;
    }, [] as TAvailableCapability[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, capabilitiesSearch, capabilities]);

  const availableCapabilities = useMemo(() => {
    return allCapabilities.reduce((output, capability) => {
      if (
        capabilities.includes(capability.capability) ||
        (capability.noAdd &&
          appContext.planHasEntitlement(capability.entitlement)) ||
        (capability.capability === 'conditional_product_groups' &&
          !capabilities.includes('product_groups')) ||
        (capability.capability === 'selected_product' && !displaySingleGroup) ||
        (capability.enterpriseOnly &&
          !appContext.planHasEntitlement(capability.entitlement)) ||
        !capability.formFactors.includes(formFactor) ||
        (capabilitiesSearch.length &&
          !capability.title
            .toLowerCase()
            .includes(capabilitiesSearch.toLowerCase()) &&
          !capability.description
            .toLowerCase()
            .includes(capabilitiesSearch.toLowerCase())) ||
        (selectedTag && !capability.tags.includes(selectedTag))
      )
        return output;
      return [...output, capability];
    }, [] as TAvailableCapability[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedTag,
    capabilities,
    capabilitiesSearch,
    displaySingleGroup,
    formFactor,
  ]);

  const unGatedAvailableCapabilities = useMemo(() => {
    return availableCapabilities.reduce((output, capability) => {
      if (appContext.planHasEntitlement(capability.entitlement)) {
        return [...output, capability.capability];
      }
      return output;
    }, [] as TTemplateCapability[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCapabilities]);

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Space direction="vertical" size="small" style={{ marginTop: 8 }}>
          <Input
            value={capabilitiesSearch}
            onChange={(e) => setCapabilitiesSearch(e.target.value)}
            size="small"
            allowClear={true}
            placeholder="Search for a paywall capability"
          />
          <Space direction="horizontal" size={2} wrap>
            {Object.entries(TTemplateCapabilityTagReadable).map(
              ([tag, value]) => {
                return (
                  <CheckableTag
                    key={tag}
                    checked={selectedTag === (tag as TTemplateCapabilityTag)}
                    onChange={(checked) =>
                      checked
                        ? setSelectedTag(tag as TTemplateCapabilityTag)
                        : setSelectedTag(null)
                    }
                  >
                    {value}
                  </CheckableTag>
                );
              }
            )}
            {(selectedTag || capabilitiesSearch) && (
              <CheckableTag
                key={'reset'}
                checked={false}
                onChange={() => {
                  setSelectedTag(null);
                  setCapabilitiesSearch('');
                }}
              >
                <CloseOutlined
                  style={{ fontSize: 10, color: namiMediumGray }}
                />
              </CheckableTag>
            )}
          </Space>
          <Divider style={{ margin: '8px 0' }} />
        </Space>
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          <Typography.Text style={{ fontWeight: 500 }}>
            Active Paywall Capabilities
          </Typography.Text>
          {addedCapabilities.length > 0 && (
            <List
              itemLayout="vertical"
              dataSource={addedCapabilities}
              renderItem={(item) => {
                return (
                  <StyledListItem
                    key={`active_${item.capability}`}
                    actions={item.activeActions}
                  >
                    <List.Item.Meta
                      avatar={item.avatar}
                      title={item.title}
                      description={item.description}
                    />
                  </StyledListItem>
                );
              }}
            />
          )}
          {!addedCapabilities.length && (
            <Card bordered={false}>
              <Result
                style={{ padding: '32px 12px' }}
                icon={
                  <NamiIcon icon={'add'} size={'medium'} color="lightGray" />
                }
                title={''}
                extra={
                  <>
                    <div
                      style={{
                        marginBottom: 12,
                        marginTop: -20,
                        color: namiMediumGray,
                      }}
                    >
                      Add capabilities below to unlock more features for your
                      paywall.
                    </div>
                  </>
                }
              />
            </Card>
          )}
        </Space>
        <Space
          direction="vertical"
          size="small"
          style={{ width: '100%', marginTop: 16 }}
        >
          <Typography.Text style={{ fontWeight: 500 }}>
            Add More Capabilities
          </Typography.Text>
          <List itemLayout="vertical">
            {availableCapabilities.map((item) => {
              return (
                <StyledListItem
                  actions={
                    unGatedAvailableCapabilities.includes(item.capability) ||
                    !userCanEdit
                      ? item.ungatedInactiveActions
                      : item.gatedInactiveActions
                  }
                  key={`inactive_${item.capability}`}
                >
                  <List.Item.Meta
                    avatar={item.avatar}
                    title={
                      <Space direction="horizontal">
                        {item.title}
                        {!unGatedAvailableCapabilities.includes(
                          item.capability
                        ) &&
                          userCanEdit &&
                          !item.enterpriseOnly && (
                            <Tooltip
                              title={`Premium feature - requires ${capitalizeFirstCharacter(
                                capabilityPlanMap[item.capability]
                              )} plan`}
                            >
                              <DollarCircleOutlined
                                style={{ color: namiBrightBlue }}
                              />
                            </Tooltip>
                          )}
                      </Space>
                    }
                    description={item.description}
                  />
                </StyledListItem>
              );
            })}
          </List>
        </Space>
      </Space>
      <BuilderTreeWebPaywall
        visible={paywallOpen === 'builder_tree'}
        onCancel={() => setPaywallOpen(null)}
      />
      <GoogleFontWebPaywall
        visible={paywallOpen === 'google_fonts'}
        onCancel={() => setPaywallOpen(null)}
      />
      <CustomFontWebPaywall
        visible={paywallOpen === 'custom_font'}
        onCancel={() => setPaywallOpen(null)}
      />
      <LaunchContextWebPaywall
        visible={
          paywallOpen === 'launch_context' ||
          paywallOpen === 'custom_launch_object'
        }
        onCancel={() => setPaywallOpen(null)}
      />
      <ProductGroupCapabilitySettingsModal
        open={productGroupCapabilityModalOpen}
        onClose={() => setProductGroupCapabilityModalOpen(false)}
      />
    </>
  );

  // function toggleTagInList(tag: TTemplateCapabilityTag, checked: boolean) {
  //   if (checked) {
  //     const newSelectedTags = [...selectedTags, tag];
  //     setSelectedTags(newSelectedTags);
  //   } else {
  //     const newSelectedTags = selectedTags.reduce((output, curTag) => {
  //       if (curTag === tag) return output;
  //       return [...output, curTag];
  //     }, [] as TTemplateCapabilityTag[]);
  //     setSelectedTags(newSelectedTags);
  //   }
  // }

  function enableFeatured() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addFeaturedCapability();
    }
  }

  function disableFeatured() {
    actions.removeFeaturedCapability();
  }

  function enableSelected() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addSelectedCapability();
    }
  }

  function disableSelected() {
    actions.removeSelectedCapability();
  }

  function enableTrialEligible() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addTrialEligibility();
    }
  }

  function disableTrialEligible() {
    actions.removeTrialEligibility();
  }

  function enableDeeplink() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addDeeplink({});
    }
  }

  function disableDeeplink() {
    actions.removeDeeplink();
  }

  function enablePromoEligible() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addPromoEligibility();
    }
  }

  function disablePromoEligible() {
    actions.removePromoEligibility();
  }

  function enableIntroEligible() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addIntroEligibility();
    }
  }

  function disableIntroEligible() {
    actions.removeIntroEligibility();
  }

  function enableSigninCheck() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addDynamicSignin();
    }
  }

  function disableSigninCheck() {
    actions.removeDynamicSignin();
  }

  function enableCarousel() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addCarousel({});
    }
  }

  function disableCarousel() {
    actions.removeCarousel();
  }

  function enableProductGroups() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      startLoading();
      let sampleProductGroups: PartialAttrs<TProductGroup, 'id'>[] = [];
      if (groups.length > 1) {
        sampleProductGroups = [
          {
            ...groups[0],
          },
          {
            ...groups[1],
          },
        ];
      } else {
        sampleProductGroups = [
          {
            ...groups[0],
            display_name: 'Basic',
          },
          {
            display_name: 'Pro',
            display_order: 2,
            ref: 'annual',
            default: true,
            paywall: paywallId,
          },
        ];
      }
      const promises = Object.values(sampleProductGroups).map(
        ({ id: groupId, ...group }) => {
          if (!!groupId) return api.updateProductGroup(groupId, group);
          return api.createProductGroup(group);
        }
      );
      Promise.all(promises)
        .then(() => onProductGroupChange())
        .then(() => {
          if (formFactor === 'television') {
            actions.addMultipleProductGroups();
          } else {
            actions.addProductGroups();
          }
        })
        .finally(() => stopLoading());
    }
  }

  function disableProductGroups() {
    actions.removeProductGroups();
  }

  function enableDynamicProductGroups() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addDynamicProductGroups();
    }
  }

  function disableDynamicProductGroups() {
    actions.removeDynamicProductGroups();
  }

  function enableCustomLaunchContext() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.addCustomLaunchContext();
    }
  }

  function disableCustomLaunchContext() {
    actions.removeCustomLaunchContext();
  }

  function enableScreenreaderText() {
    if (!userCanEdit) {
      setPaywallOpen('builder_tree');
    } else {
      actions.enableScreenreaderText();
    }
  }

  function disableScreenreaderText() {
    actions.removeScreenreaderText();
  }

  function enableMultipage() {
    if (!userCanEdit) {
      setPaywallOpen('multipage');
    } else {
      actions.enableMultipage();
    }
  }

  function disableMultipage() {
    actions.removeMultipage();
  }

  function enableAdvancedVideo() {
    if (!userCanEdit) {
      setPaywallOpen('custom_launch_object');
    } else {
      actions.addAdvancedVideo();
    }
  }

  function disableAdvancedVideo() {
    actions.removeAdvancedVideo();
  }

  function enableCustomObject() {
    if (!userCanEdit) {
      setPaywallOpen('custom_launch_object');
    } else {
      actions.addCustomObject();
    }
  }

  function disableCustomObject() {
    actions.removeCustomObject();
  }
}
