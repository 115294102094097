import React from 'react';

import { TSemverObj } from 'src/utils/parsing';
import styled from 'styled-components';

import { TComponent } from '../../../api/types/paywallTemplate.types';
import { transition } from '../css';
import TemplateComponent from '../TemplateComponent';
import HoverTag from './HoverTag';

type FooterProps = {
  components: TComponent[];
  zIndex: number;
  inFocusedState?: boolean;
  groupId: string | null;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled.div<{ zIndex: number }>`
  text-align: center !important;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: ${({ zIndex }) => zIndex};
  ${transition()};
`;

export default function Footer({
  components,
  zIndex,
  inFocusedState,
  groupId,
  minSDKVersion,
}: FooterProps) {
  if (components.length === 0) return null;

  return (
    <HoverTag
      title={components[0].namiComponentType ? 'Footer' : ''}
      id={'footer'}
    >
      <Wrapper zIndex={zIndex}>
        {components.map((component, index) => (
          <TemplateComponent
            key={`footer${index}`}
            component={component}
            inFocusedState={inFocusedState}
            groupId={groupId}
            minSDKVersion={minSDKVersion}
          />
        ))}
      </Wrapper>
    </HoverTag>
  );
}
