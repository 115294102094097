/* eslint-disable no-template-curly-in-string */
import React, { useMemo } from 'react';

import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Button, Popover, Space, Tooltip, Typography } from 'antd';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';

import { CustomTag } from '../../products/SmartTextTagCloud';

type SmartTextTag = {
  label: string;
  value: string;
  hint: string;
};

type VariableTagCloudProps = {
  handleChange: (value: any) => void;
};

const selector = createSelector(
  [
    ({ paywallBuilder: { paywall } }: RootState) => paywall,
    ({ paywallBuilder: { customObjectSchema } }: RootState) =>
      customObjectSchema,
  ],
  (paywall, customObjectSchema) => ({
    launchCustomAttributes:
      paywall?.template['ui.launchCustomAttributes'] || {},
    capabilities: paywall?.template['ui.capabilities'] || [],
    customObjectSchema,
  })
);

export default function VariableTagCloud({
  handleChange,
}: VariableTagCloudProps) {
  const { launchCustomAttributes, capabilities, customObjectSchema } =
    useAppSelector(selector);
  const smartText: SmartTextTag[] = useMemo(() => {
    let result: SmartTextTag[] = [];

    if (capabilities.includes('video')) {
      result.push({
        label: 'App Supplied Video URL',
        value: '${state.appSuppliedVideoUrl}',
        hint: 'Video URL provided asynchronously by your app code after paywall launch',
      });
    }
    if (Object.keys(launchCustomAttributes).length) {
      return Object.entries(launchCustomAttributes).reduce(
        (output, [variable, attribute]) => {
          return [
            ...output,
            {
              label: variable,
              value: `\${launch.customAttributes.${variable}}`,
              hint: `Example: "${attribute}"`,
            },
          ];
        },
        result
      );
    }
    return result;
  }, [launchCustomAttributes, capabilities]);

  const objectFields: SmartTextTag[] = useMemo(() => {
    return customObjectSchema.reduce((output, field) => {
      if (field.type !== 'array' && !field.child) {
        return [
          ...output,
          {
            label: field.title,
            value: `\${launch.${field.title}}`,
            hint: `${field.description}`,
          },
        ];
      }
      return output;
    }, [] as SmartTextTag[]);
  }, [customObjectSchema]);

  const customVariableChildren = smartText.length ? (
    smartText.map((tag) => (
      <Tooltip title={tag.hint} key={tag.value}>
        <CustomTag onClick={() => handleChange(tag.value)}>
          {tag.label}
        </CustomTag>
      </Tooltip>
    ))
  ) : (
    <Typography.Text italic>No custom variables added</Typography.Text>
  );

  const customObjectChildren = objectFields.length ? (
    objectFields.map((tag) => (
      <Tooltip title={tag.hint} key={tag.value}>
        <CustomTag onClick={() => handleChange(tag.value)}>
          {tag.label}
        </CustomTag>
      </Tooltip>
    ))
  ) : (
    <Typography.Text italic>No custom object schema added</Typography.Text>
  );

  if (
    !capabilities.includes('custom_launch_context') &&
    !capabilities.includes('video') &&
    !customObjectSchema.length
  )
    return <></>;

  return (
    <Popover
      placement="topRight"
      trigger="click"
      align={{ offset: [10, 0] }}
      content={
        <Space direction="vertical">
          <Space direction="horizontal" size={1}>
            <Typography.Text style={{ fontWeight: 500 }}>
              Custom Variables
            </Typography.Text>
            <Tooltip
              title="Learn more about Custom Variables"
              align={{ offset: [0, 5] }}
            >
              <Button
                icon={<InfoCircleOutlined style={{ fontSize: 13 }} />}
                href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-variables"
                target="_blank"
                size="small"
                type="link"
              />
            </Tooltip>
          </Space>
          {customVariableChildren}
          <Space direction="horizontal" size={1}>
            <Typography.Text style={{ fontWeight: 500 }}>
              Custom Object Fields
            </Typography.Text>
            <Tooltip
              title="Learn more about Custom Objects"
              align={{ offset: [0, 5] }}
            >
              <Button
                icon={<InfoCircleOutlined style={{ fontSize: 13 }} />}
                href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-objects"
                target="_blank"
                size="small"
                type="link"
              />
            </Tooltip>
          </Space>
          {customObjectChildren}
        </Space>
      }
      overlayInnerStyle={{ minWidth: '300px', maxWidth: '500px' }}
    >
      <PlusCircleOutlined />
    </Popover>
  );
}
