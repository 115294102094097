import React from 'react';

import moment from 'moment';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import OffBadgeDot from 'src/components/StatusDots/OffBadgeDot';
import ScheduledDot from 'src/components/StatusDots/ScheduledDot';

export function getRuleStatusForDetailPage(
  ruleEnabled: boolean,
  ruleNotBefore: string | null,
  size: 'mdUp' | 'mdDown',
  archived: boolean = false
) {
  if (ruleEnabled && !archived) {
    if (ruleNotBefore) {
      if (moment(ruleNotBefore).local().isAfter()) {
        return size === 'mdUp'
          ? 'Scheduled to go live in ' + moment(ruleNotBefore).local().fromNow()
          : 'Scheduled';
      }
    }
    return size === 'mdUp' ? 'Campaign is Live' : 'Live';
  } else if (archived) {
    return size === 'mdUp' ? 'Campaign is Archived' : 'Archived';
  } else {
    return size === 'mdUp' ? 'Campaign is Paused' : 'Paused';
  }
}

export function getRuleDotForDetailPage(
  ruleEnabled: boolean,
  ruleNotBefore: string | null,
  archived: boolean = false
) {
  if (ruleEnabled && !archived) {
    if (ruleNotBefore) {
      if (moment(ruleNotBefore).local().isAfter()) {
        return <ScheduledDot />;
      }
    }
    return <LiveBadgeDot />;
  } else if (archived) {
    return <></>;
  } else {
    return <OffBadgeDot />;
  }
}

export function getLabelDotForDetailPage(
  labelEnabled: boolean,
  archived: boolean = false
) {
  if (labelEnabled && !archived) {
    return <LiveBadgeDot />;
  } else if (archived) {
    return <></>;
  } else {
    return <OffBadgeDot />;
  }
}

export function getLabelStatusForDetailPage(
  labelEnabled: boolean,
  archived: boolean = false
) {
  if (labelEnabled && !archived) {
    return 'On';
  } else if (archived) {
    return 'Archived';
  } else {
    return 'Off';
  }
}
