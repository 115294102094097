import React, { useMemo, useState } from 'react';

import { AppstoreAddOutlined } from '@ant-design/icons';
import { Col, Divider, Row, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import api from 'src/api';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import OrgCDPIntegrationCard from 'src/components/Cards/IntegrationCard/OrgCDPIntegrationCard';
import IntegrationsWebPaywall from 'src/components/WebPaywalls/IntegrationsWebPaywall';
import { useCDPIntegrationsQuery } from 'src/hooks/queries/org.hooks';

import {
  TIntegrationMetadata,
  TOrgCDPIntegration,
} from '../../../api/types/main.types';
import IntegrationCard from '../../../components/Cards/IntegrationCard/IntegrationCard';
import FeatureRequestModal from '../../../components/NewFeatureRequestModal/FeatureRequestModal';
import Page from '../../../components/Page/Page';
import ResponsiveColumn from '../../../components/ResponsiveColumn';
import SectionTitle from '../../../components/SectionTitle';
import { useAppContext } from '../../../hooks';
import {
  useAddAppCDPIntegrationMutation,
  useAppCDPIntegrationsQuery,
  useEventStreamsQuery,
  useIntegrationsMetadataQuery,
} from '../../../hooks/queries/integration.hooks';
import { usePlatformsQuery } from '../../../hooks/queries/platform.hooks';
import { platformLogo, platformName } from '../../../services/helpers';

export default function IntegrationsPage() {
  const history = useHistory();
  const { planHasEntitlement, userHasEntitlement } = useAppContext();
  const [entitlement, setEntitlement] = useState('');
  const [modal, setModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const integrationsQuery = useIntegrationsMetadataQuery();
  const platformsQuery = usePlatformsQuery();
  const streamsQuery = useEventStreamsQuery();
  const cdpIntegrationsQuery = useAppCDPIntegrationsQuery();
  const availableCDPIntegrationsQuery = useCDPIntegrationsQuery();
  const addCDPIntegrationMutation = useAddAppCDPIntegrationMutation();

  const integrationsByType = useMemo(
    () =>
      (integrationsQuery.data || []).reduce((output, integration) => {
        return { ...output, [integration.type]: integration };
      }, {} as Record<string, TIntegrationMetadata>),
    [integrationsQuery.data]
  );

  const availableCDPIntegrations: TOrgCDPIntegration[] = useMemo(() => {
    const setupAppCDPIntegrations = (cdpIntegrationsQuery.data || []).map(
      (integration) => integration.cdp_integration_id
    );
    return (availableCDPIntegrationsQuery.data?.results || []).reduce(
      (output, orgIntegration) => {
        if (setupAppCDPIntegrations.includes(orgIntegration.id)) return output;
        return [...output, orgIntegration];
      },
      [] as TOrgCDPIntegration[]
    );
  }, [availableCDPIntegrationsQuery.data, cdpIntegrationsQuery.data]);

  const isLoading =
    platformsQuery.isFetching ||
    streamsQuery.isFetching ||
    availableCDPIntegrationsQuery.isFetching ||
    cdpIntegrationsQuery.isFetching;
  const isEmpty =
    (platformsQuery.data || []).length +
      (streamsQuery.data || []).length +
      (cdpIntegrationsQuery.data || []).length ===
    0;

  return (
    <Page title="Integrations">
      <Row>
        <Col lg={8} sm={24}>
          <Breadcrumb items={[{ name: 'Integrations' }]} />
        </Col>
      </Row>
      {(!isEmpty || isLoading) && (
        <Row style={{ paddingTop: '16px', paddingBottom: '24px' }}>
          <Col lg={8} sm={24}>
            <SectionTitle>My Integrations</SectionTitle>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        {isLoading ? (
          <ResponsiveColumn>
            <IntegrationCard loading={true} />
          </ResponsiveColumn>
        ) : (
          <>
            {(platformsQuery.data || []).map((platform) => {
              const status =
                platform.type === 'web' || platform.credentials_are_set
                  ? 'Configured'
                  : 'Add Store Credentials';
              const urlPath = `/integrations/${platform.type}/${platform.id}`;
              return (
                <ResponsiveColumn key={platform.id}>
                  <IntegrationCard
                    title={platformName(platform.type)}
                    subtitle={platform.name}
                    logo={platformLogo(platform.type)}
                    status={status}
                    onClick={() => handlePlatformClick(urlPath)}
                  />
                </ResponsiveColumn>
              );
            })}
            {(streamsQuery.data || []).map((stream) => {
              const integration = integrationsByType[stream.partner];
              const status = stream.has_complete_config
                ? 'Configured'
                : 'Finish Configuring';
              const urlPath = `/integrations/${stream.partner}/${stream.id}`;
              return (
                <ResponsiveColumn key={stream.id}>
                  {integration && (
                    <IntegrationCard
                      title={integration.title}
                      subtitle={stream?.name || integration.subtitle}
                      logo={integration.logo}
                      status={status}
                      onClick={() => handlePlatformClick(urlPath)}
                    />
                  )}
                </ResponsiveColumn>
              );
            })}
            {(cdpIntegrationsQuery.data || []).map((integration) => {
              const status =
                integration.configured && integration.enabled
                  ? 'Enabled'
                  : !integration.configured
                  ? 'Finish configuring'
                  : 'Disabled';
              const urlPath = `/integrations/${integration.provider}/${integration.id}`;
              return (
                <ResponsiveColumn key={integration.id}>
                  {integration && (
                    <IntegrationCard
                      title={integration.display_name}
                      subtitle={'Org CDP Integration'}
                      logo={platformLogo('custom')}
                      status={status}
                      onClick={() => handlePlatformClick(urlPath)}
                    />
                  )}
                </ResponsiveColumn>
              );
            })}
          </>
        )}
      </Row>
      <Divider />
      <Row gutter={[16, 16]} style={{ paddingBottom: '24px' }}>
        <Col lg={8} sm={24}>
          <SectionTitle>All Integrations</SectionTitle>
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        {integrationsQuery.isFetching ? (
          <ResponsiveColumn>
            <IntegrationCard loading={true} />
          </ResponsiveColumn>
        ) : (
          (integrationsQuery.data || []).map((integration) => (
            <ResponsiveColumn key={integration.type}>
              <IntegrationCard
                className={integration.type}
                title={integration.title}
                subtitle={integration.subtitle}
                logo={integration.logo}
                status={buildIntegrationStatus(integration)}
                onClick={() => handleIntegrationClick(integration)}
              />
            </ResponsiveColumn>
          ))
        )}
        {availableCDPIntegrations.map((orgCDPIntegration) => (
          <ResponsiveColumn key={orgCDPIntegration.id}>
            <OrgCDPIntegrationCard
              provider_type={orgCDPIntegration.provider}
              title={orgCDPIntegration.display_name}
              onClick={() => handleCDPIntegrationClick(orgCDPIntegration)}
            />
          </ResponsiveColumn>
        ))}
        <ResponsiveColumn>
          {userHasEntitlement('app.platform.get') && (
            <>
              <IntegrationCard
                onClick={() => setVisible(true)}
                title="Request an Integration"
                subtitle="Not seeing your partner or vendor?"
                icon={<AppstoreAddOutlined />}
                loading={integrationsQuery.isFetching}
              />
              <FeatureRequestModal
                title="Request an Integration"
                nameLabel="Integration Name"
                urlLabel="Integration Website"
                useCaseLabel="What is your use case for this integration?"
                visible={visible}
                onClose={() => setVisible(false)}
              />
            </>
          )}
        </ResponsiveColumn>
      </Row>
      <IntegrationsWebPaywall
        visible={modal}
        onCancel={() => setModal(false)}
        entitlement={entitlement}
      />
    </Page>
  );

  function buildIntegrationStatus(integration: TIntegrationMetadata): string {
    if (!integration.implemented) return 'Coming Soon';
    if (integration.request_only) return 'Request Access';
    const hasEntitlement = planHasEntitlement(integration.entitlement);
    return hasEntitlement ? '' : 'Upgrade for Access';
  }

  function handlePlatformClick(urlPath: string): void {
    if (userHasEntitlement('app.platform.get')) {
      history.push(urlPath);
      return;
    }
    notification.warn({
      message:
        "You don't have permission to view integrations. Contact your admin for access.",
    });
  }

  function handleCDPIntegrationClick(integration: TOrgCDPIntegration) {
    addCDPIntegrationMutation.mutate(integration.id);
  }

  function handleIntegrationClick(integration: TIntegrationMetadata): void {
    if (!integration.implemented) return;
    if (integration.request_only && userHasEntitlement('app.platform.get')) {
      api.requestUpgrade(integration.entitlement).then(() => {
        notification.success({
          message: 'Thanks',
          description: `We will be in touch to discuss integrating ${integration.title} with your Nami app.`,
        });
      });
    } else if (
      !planHasEntitlement(integration.entitlement) &&
      userHasEntitlement('app.platform.create')
    ) {
      setEntitlement(integration.entitlement);
      setModal(true);
    } else if (!userHasEntitlement(integration.entitlement)) {
      notification.warn({
        message:
          "You don't have permission to create integrations. Contact your admin for access.",
      });
    } else {
      history.push(`/integrations/${integration.type}/`);
    }
  }
}
