import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SendOutlined,
  UnlockOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { uuid4 } from '@sentry/utils';
import {
  Button,
  Col,
  Collapse,
  Drawer,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import Anchor from 'antd/lib/anchor/Anchor';
import AnchorLink from 'antd/lib/anchor/AnchorLink';
import moment, { Moment } from 'moment';
import { useParams } from 'react-router-dom';
import { TDevice } from 'src/api/types/paywall.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import Loading from 'src/components/Loading/Loading';
import Responsive from 'src/components/Responsive/Responsive';
import PaywallCapabilityWebPaywall from 'src/components/WebPaywalls/PaywallCapabilityWebPaywall';
import { copyTextToClipBoard } from 'src/services/helpers';
import { isValidURL } from 'src/utils/string';
import styled from 'styled-components';

import {
  CampaignSegmentPayloadType,
  CampaignSegmentType,
  TCampaignConversionEventType,
  TCampaignRuleFilter,
  TCampaignRulePayload,
  TCampaignRuleQueryOperator,
} from '../../../../api/types/campaign.types';
import Page from '../../../../components/Page/Page';
import { useAppContext, useBooleanState } from '../../../../hooks';
import { useEnhancedAntForm } from '../../../../hooks/ant-form.hooks';
import {
  useArchiveCampaignRuleMutation,
  useCampaignLabelIdMap,
  useCampaignRuleQuery,
  useCampaignSegmentsQuery,
  useDeleteCampaignRuleMutation,
  useUpdateCampaignRuleMutation,
} from '../../../../hooks/queries/campaign.hooks';
import {
  namiDarkGray,
  namiMediumGray,
  namiPureWhite,
  namiSmoke,
} from '../../../../variables';
import DuplicateRuleModal from '../DuplicateRuleModal';
import DuplicateRuleToPlacementsModal from '../DuplicateRuleToPlacementsModal';
import { getRuleDotForDetailPage } from '../utils/campaign.utils';
import CampaignStatusLabel from '../utils/CampaignStatusLabel';
import AudienceBuilder, { TFilters } from './AudienceBuilder';
import CampaignCard from './CampaignCard';
import CampaignFooterButtons from './CampaignFooterButtons';
import CampaignLabelSelect from './CampaignLabelSelect';
import CampaignMetricCards from './CampaignMetricCards';
import CampaignScheduler from './CampaignScheduler';
import { TCampaignDetailParams } from './params.types';
import SegmentFieldSection from './SegmentFieldSection';
import { anonymous_filters, semver_filters } from './selectors/mapsAndOptions';

type TCampaignRuleValidation = {
  saveDisabled: boolean;
  segmentMissingPaywalls: boolean;
  hasEmptyFilter: boolean;
  hasInvalidFilterValueSemver: boolean;
  startDateInPast: boolean;
  anonymousCampaignMissingPlatformFilter: boolean;
  splitTotalNot100Percent: boolean;
  segmentHasGatedPaywalls: boolean;
  segmentFFMismatch: boolean;
  filterErrorMessages: string[];
  segmentErrorMessages: string[];
  conversionEventUrlMissing: boolean;
  conversionEventUrlInvalid: boolean;
};

const semverRegex = new RegExp(
  '^v?(0|[1-9]\\d*)\\.(0|[1-9]\\d*)\\.?(0|[1-9]\\d*)?(-\\w+\\.\\d+)?$'
);

const NameFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    display: flex;
    justify-content: space-between;
  }
`;

const DateLabel = styled.span`
  font-weight: 500;
`;

const DateWrapper = styled.div`
  color: ${namiDarkGray};
  cursor: default;
  font-size: small;
`;

export default function CampaignDetailPage() {
  const ruleId = useParams<TCampaignDetailParams>().campaignID;
  const form = useEnhancedAntForm();
  const { userHasEntitlement, planHasEntitlement } = useAppContext();
  const [operator, setOperator] = useState<TCampaignRuleQueryOperator>('and');
  const [filters, setFilters] = useState<{
    [key: string]: TCampaignRuleFilter;
  }>({});
  const [formFactorRule, setFormFactorRule] = useState<TDevice | null>(null);
  const deleteCampaignRuleMutation = useDeleteCampaignRuleMutation();
  const ruleQuery = useCampaignRuleQuery(ruleId);
  const segmentsQuery = useCampaignSegmentsQuery(ruleId);
  const labelsMap = useCampaignLabelIdMap();
  const archiveMutation = useArchiveCampaignRuleMutation();
  const [segments, setSegments] = useState<
    Record<string, CampaignSegmentType | CampaignSegmentPayloadType>
  >({});
  const [segmentLocked, setSegmentLocked] = useState(false);
  const [segmentFFMismatch, setSegmentFFMismatch] = useState(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const updateCampaignRuleMutation = useUpdateCampaignRuleMutation(ruleId);
  const [isEnabled, setIsEnabled] = useState(ruleQuery.data?.enabled);
  const [isAnonymous, setAnonymous] = useState(!!ruleQuery.data?.anonymous);
  const [conversionEventType, setConversionEventType] =
    useState<TCampaignConversionEventType>();
  const [conversionEventUrl, setConversionEventUrl] = useState<string>();
  const [isDuplicateModalVisible, setDuplicateModalVisible] = useState(false);
  const [
    isDuplicateToPlacementsModalVisible,
    setDuplicateToPlacementsModalVisible,
  ] = useState(false);
  const userCanEditCampaign = userHasEntitlement('app.campaign.update');
  const planCanScheduleCampaign = planHasEntitlement('app.campaign.scheduling');
  const userCanViewMetrics = [
    'app.analytics.revenue',
    'app.analytics.impressions',
    'app.analytics.paywall_conversion_rate',
  ].some(userHasEntitlement);
  const [hasChanges, setHasChanges] = useState(false);
  const [capabilityPaywallOpen, openCapabilityPaywall, closeCapabilityPaywall] =
    useBooleanState(false);
  const allAnchorOptions: {
    id: string;
    label: string;
    hasAccess: boolean;
  }[] = [
    {
      id: 'name',
      label: 'Name',
      hasAccess: true,
    },
    {
      id: 'placement',
      label: 'Placement',
      hasAccess: true,
    },
    {
      id: 'audience',
      label: 'Audience',
      hasAccess: true,
    },
    {
      id: 'paywalls',
      label: 'Paywalls',
      hasAccess: true,
    },
    {
      id: 'conversion-event',
      label: 'Conversion Event',
      hasAccess: planHasEntitlement('app.campaign.conversion_event'),
    },
    {
      id: 'reporting',
      label: 'Reporting',
      hasAccess: userCanViewMetrics,
    },
    {
      id: 'schedule',
      label: 'Schedule',
      hasAccess: planCanScheduleCampaign,
    },
    {
      id: 'anonymous-mode',
      label: 'Anonymous Mode',
      hasAccess: userHasEntitlement('sdk.anonymous_allowed'),
    },
  ];
  const entitledAnchorOptions: React.ReactNode[] = allAnchorOptions.reduce(
    (output, opt) => {
      if (!opt.hasAccess) return output;
      return [
        ...output,
        <AnchorLink href={`#${opt.id}`} title={opt.label} key={opt.id} />,
      ];
    },
    [] as React.ReactNode[]
  );

  useEffect(() => {
    if (!segmentsQuery.data) return;
    setSegments(
      segmentsQuery.data.reduce((output, segment) => {
        return { ...output, [segment.id]: segment };
      }, {})
    );
  }, [segmentsQuery.data]);

  useEffect(() => {
    if (!ruleQuery.data) return;

    setIsEnabled(ruleQuery.data.enabled);
    setAnonymous(ruleQuery.data.anonymous);
    setConversionEventType(ruleQuery.data.conversion_event_type || undefined);
    setConversionEventUrl(ruleQuery.data.conversion_event_url || undefined);
    form.setFieldsValue(ruleQuery.data);
    form.setFieldValue('startDate', moment(ruleQuery.data.not_before));
    const filters = ruleQuery.data.query.filter || [];
    setFilters(
      filters.reduce((output, filter) => ({ ...output, [uuid4()]: filter }), {})
    );
    const operator = ruleQuery.data.query.operator || 'and';
    setOperator(operator);
    if (ruleQuery.data.not_before) setStartDate(ruleQuery.data.not_before);
  }, [form, ruleQuery.data]);

  const performCampaignUpdate = useCallback(
    (
      values: TCampaignRulePayload,
      { checkLabel = true }: { checkLabel?: boolean } = {}
    ): void => {
      if (!checkLabel || values.label === ruleQuery.data!.label.id) {
        const filter = [...Object.values(filters)];
        const query = ruleQuery.data?.query || {};
        const payload = {
          rule: {
            ...values,
            query: { ...query, filter, operator },
            enabled: isEnabled,
            anonymous: isAnonymous,
            not_before: startDate,
            conversion_event_type: conversionEventType,
            conversion_event_url: conversionEventUrl,
          },
          segments: Object.values(segments),
        };
        updateCampaignRuleMutation.mutate(payload);
        return;
      }
      performCampaignUpdate(values, { checkLabel: false });
    },
    [
      filters,
      isEnabled,
      isAnonymous,
      ruleQuery.data,
      segments,
      startDate,
      updateCampaignRuleMutation,
      operator,
      conversionEventType,
      conversionEventUrl,
    ]
  );

  const validationStatus: TCampaignRuleValidation = useMemo(() => {
    const filterErrorMessages: string[] = [];
    const segmentErrorMessages: string[] = [];
    const hasEmptyFilter = Object.values(filters).some(
      ({ values }) => values.length === 0
    );
    const hasInvalidFilterValueSemver = Object.values(filters).some(
      (filter) =>
        semver_filters.includes(filter.identifier) &&
        filter.values.length !== 0 &&
        !semverRegex.test(filter.values[0] || '')
    );
    const segmentMissingPaywalls = Object.values(segments).some(
      (segment) => segment.paywall === null
    );
    const anonymousMissingPlatformFilter =
      isAnonymous &&
      !Object.values(filters).some(
        ({ identifier }) => identifier === 'Platform.id'
      );
    if (anonymousMissingPlatformFilter)
      filterErrorMessages.push(
        'Anonymous campaigns must have a Platform filter'
      );

    const startDateInPast =
      (startDate && moment(startDate).isBefore(moment())) || false;

    const segmentSplitTotal = Object.entries(segments).reduce(
      (output, [_itemKey, item]) => {
        return (output += item.split);
      },
      0
    );
    if (segmentSplitTotal !== 100)
      segmentErrorMessages.push('Segment split must add up to 100%');

    if (segmentFFMismatch)
      segmentErrorMessages.push(
        'Paywall form factor should be the same as Audience Filter'
      );

    if (segmentLocked)
      segmentErrorMessages.push(
        'One of your selected paywalls uses Premium features. Upgrade your account to publish this campaign.'
      );

    const conversionEventUrlMissing =
      !!conversionEventType && !conversionEventUrl;

    const conversionEventUrlInvalid = conversionEventUrl
      ? !isValidURL(conversionEventUrl)
      : false;

    return {
      saveDisabled:
        hasEmptyFilter ||
        hasInvalidFilterValueSemver ||
        anonymousMissingPlatformFilter ||
        startDateInPast ||
        segmentSplitTotal !== 100 ||
        segmentFFMismatch ||
        segmentLocked ||
        (!!isEnabled && segmentMissingPaywalls) ||
        conversionEventUrlMissing ||
        conversionEventUrlInvalid,
      hasEmptyFilter,
      hasInvalidFilterValueSemver,
      segmentMissingPaywalls,
      anonymousCampaignMissingPlatformFilter: anonymousMissingPlatformFilter,
      startDateInPast,
      segmentHasGatedPaywalls: segmentLocked,
      splitTotalNot100Percent: segmentSplitTotal !== 100,
      filterErrorMessages,
      segmentErrorMessages,
      segmentFFMismatch,
      conversionEventUrlMissing: conversionEventUrlMissing,
      conversionEventUrlInvalid: conversionEventUrlInvalid,
    };
  }, [
    isEnabled,
    filters,
    isAnonymous,
    startDate,
    segments,
    segmentLocked,
    segmentFFMismatch,
    conversionEventType,
    conversionEventUrl,
  ]);

  useEffect(() => {
    const formFactorFilter = Object.values(filters).filter(
      ({ identifier }) => identifier === 'Device.form_factor'
    );
    if (formFactorFilter.length > 0 && formFactorFilter[0].values.length > 0) {
      setFormFactorRule(formFactorFilter[0].values[0] as TDevice);
    } else setFormFactorRule(null);
  }, [filters]);

  function updateCampaign() {
    const payload = {
      ...form.getFieldsValue(),
    };
    performCampaignUpdate({
      ...payload,
      label: payload.label.hasOwnProperty('id')
        ? payload.label.id
        : payload.label,
    });
    setHasChanges(false);
  }

  const ActionButton: React.ReactNode = useMemo(() => {
    const saveDisabled =
      validationStatus.saveDisabled ||
      !userCanEditCampaign ||
      !!ruleQuery.data?.archived;
    return (
      <Tooltip
        title={getActionButtonTooltipMessage()}
        align={{ offset: [0, 4] }}
        placement="topRight"
        overlayInnerStyle={{ width: 300, cursor: 'pointer' }}
      >
        <Button
          icon={saveDisabled ? <WarningOutlined /> : <SendOutlined />}
          type="primary"
          danger={saveDisabled}
          className="intercom-campaignStatusUpdate"
          loading={updateCampaignRuleMutation.isLoading}
          onClick={() => {
            if (saveDisabled) {
              notification.error({
                message: 'There are unresolved errors with this campaign.',
              });
            } else {
              const method = !!isEnabled
                ? openConfirmationModal
                : updateCampaign;
              method();
            }
          }}
        >
          Publish
        </Button>
      </Tooltip>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    performCampaignUpdate,
    updateCampaignRuleMutation.isLoading,
    validationStatus,
    userCanEditCampaign,
    ruleQuery.data?.archived,
  ]);

  const createdDate = moment(ruleQuery.data?.created_date);
  const updatedDate = moment(ruleQuery.data?.updated_date);

  const operatorSelectOpts: {
    label: string;
    value: TCampaignRuleQueryOperator;
  }[] = [
    {
      label: 'ALL',
      value: 'and',
    },
    {
      label: 'ANY',
      value: 'or',
    },
  ];

  const conversionEventTypeOpts: {
    label: string;
    value: TCampaignConversionEventType;
    description: React.ReactNode;
  }[] = [
    {
      label: 'Open Deeplink URL',
      value: 'deeplink',
      description: (
        <Space direction="vertical" size={0}>
          <span style={{ fontWeight: 600 }}>Open Deeplink URL</span>
          <span>Navigate to a specific page of the app</span>
        </Space>
      ),
    },
    {
      label: 'Open Web URL',
      value: 'in_app',
      description: (
        <Space direction="vertical" size={0}>
          <span style={{ fontWeight: 600 }}>Open Web URL</span>
          <span>Open URL in in-app web browser</span>
        </Space>
      ),
    },
  ];

  if (ruleQuery.isFetching || segmentsQuery.isFetching) return <Loading />;

  return (
    <>
      <Page title={getPageTitle()}>
        <Row justify="start" gutter={[0, 36]}>
          <Col xs={24} style={{ paddingBottom: 12 }}>
            <Space direction="horizontal" size={6}>
              <Breadcrumb
                items={[
                  { url: '/campaigns/placements/', name: 'Placements' },
                  getPlacementBreadcrumb(),
                  { name: getPageTitle() },
                ]}
              />
              {!ruleQuery.isFetching &&
                !ruleQuery.data?.archived &&
                getRuleDotForDetailPage(
                  !!ruleQuery.data?.enabled,
                  ruleQuery.data?.not_before || null
                )}
            </Space>
          </Col>
        </Row>
        <Row gutter={[0, 30]} style={{ marginTop: 12 }}>
          <Col lg={4} xs={0}>
            <Anchor targetOffset={0} offsetTop={40}>
              {entitledAnchorOptions}
            </Anchor>
          </Col>
          <Col lg={20} xs={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={performCampaignUpdate}
              onChange={() => setHasChanges(true)}
            >
              <CampaignCard
                bordered={false}
                title="Name"
                type="inner"
                loading={ruleQuery.isFetching}
                id="name"
              >
                <Row gutter={[12, 0]}>
                  <Col xs={24} md={12}>
                    <NameFormItem
                      name="name"
                      label={<span>Name</span>}
                      className="intercom-campaignNameField"
                    >
                      <Input
                        placeholder="Name"
                        disabled={!userCanEditCampaign}
                      />
                    </NameFormItem>
                  </Col>
                </Row>
                <Space
                  direction="horizontal"
                  style={{ marginBottom: 4 }}
                  size="small"
                >
                  <DateWrapper>
                    <Tooltip title={createdDate.format('YYYY-MM-DD h:mm A')}>
                      <DateLabel>Created: </DateLabel>
                      {createdDate.fromNow()}
                    </Tooltip>
                  </DateWrapper>
                  <DateWrapper>
                    <Tooltip title={updatedDate.format('YYYY-MM-DD h:mm A')}>
                      <DateLabel>Last Updated: </DateLabel>
                      {updatedDate.fromNow()}
                    </Tooltip>
                  </DateWrapper>
                </Space>
              </CampaignCard>
              <CampaignCard title="Placement" type="inner" id="placement">
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <Form.Item
                      label="Placement"
                      name="label"
                      className="intercom-campaignLabelField"
                    >
                      <CampaignLabelSelect
                        disabled={!userCanEditCampaign}
                        placeholder="Campaign Label"
                        onNewLabel={(label) =>
                          form.setFieldValue('label', label.id)
                        }
                        onChange={() => setHasChanges(true)}
                        currentLabelId={ruleQuery.data?.label.id}
                        bulkDuplicateAction={() =>
                          setDuplicateToPlacementsModalVisible(true)
                        }
                        currentlyArchived={!!ruleQuery.data?.archived}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </CampaignCard>
              <CampaignCard
                loading={ruleQuery.isFetching}
                title="Audience"
                type="inner"
                id="audience"
              >
                <Row gutter={[16, 0]} style={{ marginBottom: 8 }}>
                  <Col style={{ paddingLeft: 8 }}>Include users who meet</Col>
                  <Col>
                    <Select
                      size="small"
                      options={operatorSelectOpts}
                      value={operator}
                      onChange={setOperator}
                      style={{ width: 70 }}
                    />
                  </Col>
                  <Col>of the following criteria:</Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col flex="auto">
                    <AudienceBuilder
                      anonymous={isAnonymous}
                      filters={filters}
                      onChange={(
                        value: ((prevState: TFilters) => TFilters) | TFilters
                      ) => {
                        setHasChanges(true);
                        setFilters(value);
                      }}
                      disabled={!userCanEditCampaign}
                      operator={operator}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
                  {validationStatus.filterErrorMessages.length > 0 && (
                    <Col md={12}>
                      <Typography.Text type="danger">
                        {validationStatus.filterErrorMessages.join('\n')}
                      </Typography.Text>
                    </Col>
                  )}
                </Row>
              </CampaignCard>
              <SegmentFieldSection
                anonymous={isAnonymous}
                loading={segmentsQuery.isFetching}
                segments={segments}
                onChange={(
                  segments: Record<
                    string,
                    CampaignSegmentPayloadType | CampaignSegmentType
                  >
                ) => {
                  setHasChanges(true);
                  setSegments(segments);
                }}
                segmentErrorMessages={validationStatus.segmentErrorMessages}
                formFactorRule={formFactorRule}
                setPaywallLocked={setSegmentLocked}
                setPaywallFFMismatch={setSegmentFFMismatch}
                openCapabilitiesWebPaywall={openCapabilityPaywall}
                campaignLive={isEnabled}
              />
              {planHasEntitlement('app.campaign.conversion_event') && (
                <CampaignCard
                  loading={ruleQuery.isFetching}
                  title="Conversion Event"
                  type="inner"
                  id="conversion-event"
                >
                  <Row gutter={[12, 0]}>
                    <Col lg={18} md={24}>
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <Space direction="horizontal">
                          <Typography.Text>
                            After user successfully purchases from paywall:
                          </Typography.Text>
                          <Tooltip
                            title={
                              <IconActionButton
                                size="small"
                                type="link"
                                href="https://learn.namiml.com/public-docs/campaigns/campaigns/campaign-conversion-events"
                                style={{ color: namiSmoke }}
                                icon={
                                  <ReadOutlined style={{ fontSize: '13px' }} />
                                }
                                target="_blank"
                              >
                                Learn more
                              </IconActionButton>
                            }
                          >
                            <QuestionCircleOutlined
                              style={{ fontSize: 13, color: namiMediumGray }}
                            />
                          </Tooltip>
                        </Space>
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Select conversion event"
                          value={conversionEventType}
                          onChange={(value) => {
                            setHasChanges(true);
                            setConversionEventType(value || null);
                          }}
                          optionLabelProp="label"
                          allowClear={true}
                          onClear={() => {
                            setConversionEventType(undefined);
                            setConversionEventUrl(undefined);
                          }}
                        >
                          {conversionEventTypeOpts.map((item) => (
                            <Select.Option
                              key={item.value}
                              value={item.value}
                              label={item.label}
                            >
                              {item.description}
                            </Select.Option>
                          ))}
                        </Select>
                        <Input
                          placeholder="URL"
                          disabled={!userCanEditCampaign}
                          value={conversionEventUrl}
                          onChange={(event) =>
                            setConversionEventUrl(event.target.value)
                          }
                        />
                      </Space>
                    </Col>
                  </Row>
                </CampaignCard>
              )}
              {userCanViewMetrics && (
                <CampaignMetricCards
                  loading={segmentsQuery.isFetching}
                  hasSegment={Object.keys(segments).length > 1}
                />
              )}
              {planCanScheduleCampaign && (
                <CampaignCard
                  type="inner"
                  loading={ruleQuery.isFetching}
                  title="Schedule"
                  id="schedule"
                >
                  <CampaignScheduler
                    startDate={startDate}
                    startDateInPast={validationStatus.startDateInPast}
                    onChange={(value) => {
                      setHasChanges(true);
                      updateStartDate(value);
                    }}
                  />
                </CampaignCard>
              )}
              {userHasEntitlement('sdk.anonymous_allowed') && (
                <CampaignCard
                  type="inner"
                  loading={ruleQuery.isFetching}
                  title="Anonymous Mode"
                  id="anonymous-mode"
                >
                  <Space direction="vertical">
                    <Typography.Text>
                      Anonymous campaigns are used to raise a paywall when Nami
                      doesn't have any information about the user to use for
                      targeting & personalization. If a campaign is anonymous,
                      you can't apply most audience filters. In addition, you
                      cannot raise a paywall that has personalized information.
                      <Typography.Link
                        href="https://learn.namiml.com/nami-enterprise/7rlk38XGMORjNGPGEhGx/campaigns/anonymous-mode"
                        style={{ marginLeft: 5 }}
                        target="_blank"
                      >
                        Learn More
                      </Typography.Link>
                    </Typography.Text>
                    <Form.Item style={{ marginTop: 7, marginBottom: 3 }}>
                      <Space direction="horizontal">
                        <span>Flag as Anonymous:</span>
                        <Switch
                          checked={isAnonymous}
                          size="small"
                          disabled={!userCanEditCampaign}
                          onChange={() => {
                            updateAnonymousFlag(!isAnonymous); //todo
                            setHasChanges(true);
                          }}
                          checkedChildren={<EyeInvisibleOutlined />}
                        />
                      </Space>
                    </Form.Item>
                  </Space>
                </CampaignCard>
              )}
              <Collapse ghost style={{ marginBottom: 100, marginTop: 20 }}>
                <Collapse.Panel
                  header={<h4>Danger Zone</h4>}
                  key="1"
                  className="intercom-campaignDelete"
                >
                  <Space direction="vertical">
                    <Typography.Text>
                      Are you sure you want to delete this campaign? If you
                      delete a campaign, it will not show paywalls to your app
                      users and may impact your app revenue. If this campaign
                      has recorded purchases or impressions, your metrics may be
                      affected by deleting this campaign.
                    </Typography.Text>
                    <Typography.Text strong>
                      A deleted campaign cannot be restored.
                    </Typography.Text>
                    <Space direction="horizontal" size="small">
                      <Button
                        type="primary"
                        danger
                        loading={deleteCampaignRuleMutation.isLoading}
                        onClick={() =>
                          deleteCampaignRuleMutation.mutate(ruleId)
                        }
                        disabled={!userHasEntitlement('app.campaign.delete')}
                      >
                        Delete Campaign
                      </Button>
                      {!ruleQuery.data?.archived && (
                        <Button
                          loading={archiveMutation.isLoading}
                          onClick={() =>
                            archiveMutation.mutate({
                              rule_id: ruleId,
                              archived: !!ruleQuery.data?.archived,
                            })
                          }
                          disabled={!userHasEntitlement('app.campaign.update')}
                        >
                          Archive Campaign
                        </Button>
                      )}
                    </Space>
                  </Space>
                </Collapse.Panel>
              </Collapse>
            </Form>
          </Col>
        </Row>
        <DuplicateRuleModal
          open={isDuplicateModalVisible}
          ruleId={ruleId}
          onClose={() => setDuplicateModalVisible(false)}
        />
        <DuplicateRuleToPlacementsModal
          open={isDuplicateToPlacementsModalVisible}
          ruleId={ruleId}
          onClose={() => setDuplicateToPlacementsModalVisible(false)}
          ruleName={ruleQuery.data?.name || ''}
        />
        <Responsive size="mdUp">{getCampaignPageFooter('mdUp')}</Responsive>
        <Responsive size="mdDown">{getCampaignPageFooter('mdDown')}</Responsive>
        <PaywallCapabilityWebPaywall
          visible={capabilityPaywallOpen}
          onCancel={closeCapabilityPaywall}
        />
      </Page>
    </>
  );

  function getPageTitle(): string {
    if (ruleQuery.isLoading) return 'Loading campaign...';
    else {
      if (!!ruleQuery.data?.archived)
        return `${ruleQuery.data?.name} [Archived]`;
      return ruleQuery.data?.name || '';
    }
  }

  function getPlacementBreadcrumb(): { url: string; name: string } {
    if (ruleQuery.isLoading || ruleQuery.isFetching) {
      return {
        url: '/campaigns/?label_id=',
        name: 'Loading...',
      };
    } else if (ruleQuery.data?.label && Object.keys(labelsMap).length) {
      return {
        url: `/campaigns/?label_id=${ruleQuery.data.label.id}`,
        name:
          labelsMap[ruleQuery.data.label.id].display_name ||
          ruleQuery.data.label.value,
      };
    }
    return {
      url: '/campaigns/?label_id=',
      name: '',
    };
  }

  function copyValue() {
    const message = `Copied Campaign ID Successfully`;
    copyTextToClipBoard(ruleQuery.data?.id).then(() =>
      notification.success({ message })
    );
  }

  function getCampaignPageFooter(size: 'mdUp' | 'mdDown') {
    return (
      <Drawer
        bodyStyle={{
          padding: 12,
          background: namiPureWhite,
          marginLeft: size === 'mdDown' ? 8 : 101,
          marginRight: size === 'mdDown' ? 8 : 101,
        }}
        placement="bottom"
        open={true}
        height="82px"
        closable={false}
        mask={false}
      >
        <Row justify="start" align="middle" style={{ marginTop: 10 }}>
          <Col span={12}>
            <Space direction="horizontal" size={8}>
              <CampaignStatusLabel
                enabled={!!isEnabled}
                startDate={startDate}
                size={size}
                archived={!!ruleQuery.data?.archived}
              />
              <Switch
                checked={!!isEnabled}
                size="small"
                className="campaignSwitch"
                onChange={() => {
                  setIsEnabled(!isEnabled);
                  setHasChanges(true);
                }}
                loading={ruleQuery.isFetching}
                disabled={
                  !ruleQuery.data ||
                  !userCanEditCampaign ||
                  !!ruleQuery.data?.archived
                }
              />
            </Space>
          </Col>
          <Col span={12}>
            <CampaignFooterButtons
              size={size}
              duplicateAction={() => setDuplicateModalVisible(true)}
              bulkDuplicateAction={() =>
                setDuplicateToPlacementsModalVisible(true)
              }
              copyAction={() => copyValue()}
              actionButton={ActionButton}
              archiveAction={() => {
                if (!!ruleQuery.data?.archived) {
                  Modal.confirm({
                    centered: true,
                    onOk: () =>
                      archiveMutation.mutate({
                        rule_id: ruleId,
                        archived: !!ruleQuery.data?.archived,
                      }),
                    title: 'Unarchive Paywalls?',
                    content:
                      'By unarchiving this campaign, you will also unarchive all attached paywalls.',
                  });
                } else {
                  archiveMutation.mutate({
                    rule_id: ruleId,
                    archived: !!ruleQuery.data?.archived,
                  });
                }
              }}
              currentlyArchived={!!ruleQuery.data?.archived}
              hasChanges={hasChanges}
            />
          </Col>
        </Row>
      </Drawer>
    );
  }

  function getActionButtonTooltipMessage(): React.ReactNode {
    let messagesList: Array<any> = [];
    if (!userCanEditCampaign)
      messagesList.push("You don't have permission to edit campaigns");
    if (!!ruleQuery.data?.archived)
      messagesList.push('Unarchive campaign to make changes');
    if (validationStatus.hasEmptyFilter)
      messagesList.push('Add missing value to Audience filter');
    if (validationStatus.hasInvalidFilterValueSemver)
      messagesList.push('Filter value must be valid semver');
    if (validationStatus.segmentMissingPaywalls)
      messagesList.push('Add missing paywall to segment');
    if (validationStatus.segmentFFMismatch)
      messagesList.push('Match paywall form factor to Audience');
    if (validationStatus.anonymousCampaignMissingPlatformFilter)
      messagesList.push('Add platform filter');
    if (validationStatus.splitTotalNot100Percent)
      messagesList.push('Make sure segment split total is 100%');
    if (validationStatus.startDateInPast)
      messagesList.push('Add Start Date in future');
    if (validationStatus.conversionEventUrlMissing)
      messagesList.push('Provide a Conversion Event URL');
    if (validationStatus.conversionEventUrlInvalid)
      messagesList.push('Provide a valid Conversion Event URL');
    if (validationStatus.segmentHasGatedPaywalls)
      messagesList.push(
        <IconActionButton
          type="text"
          size="small"
          icon={<UnlockOutlined style={{ fontSize: '13px' }} />}
          style={{ color: namiSmoke }}
          onClick={openCapabilityPaywall}
        >
          Upgrade your Plan
        </IconActionButton>
      );
    if (messagesList.length) {
      return (
        <ul style={{ marginBottom: 0, padding: '10px!important' }}>
          {messagesList.map((message, index) => {
            return <li key={index}>{message}</li>;
          })}
        </ul>
      );
    }
    return null;
  }

  function updateAnonymousFlag(flag: boolean): void {
    if (flag) {
      const newFilters = Object.keys(filters).reduce((output, filterKey) => {
        if (anonymous_filters.includes(filters[filterKey].identifier))
          return { ...output, [filterKey]: filters[filterKey] };
        return { ...output };
      }, {});
      setFilters(newFilters);
      deleteTest();
    }
    setAnonymous(flag);
  }

  function deleteTest(): void {
    setSegments((segments) => {
      const [key, segment] = Object.entries(segments)[0];
      return { [key]: { ...segment, split: 100 } };
    });
  }

  function updateStartDate(value: Moment | null | undefined) {
    setStartDate(value ? value.toISOString() : null);
  }

  function openConfirmationModal() {
    if (!ruleQuery.data?.enabled) {
      //Campaign being set live
      Modal.confirm({
        zIndex: 1002,
        title: 'Setting Campaign Live',
        icon: <ExclamationCircleOutlined />,
        content:
          'By going live, users will now be eligible to see this campaign and paywall. Are you sure you want to proceed?',
        onOk: updateCampaign,
        centered: true,
      });
    } else {
      //Live campaign being updated
      Modal.confirm({
        zIndex: 1002,
        title: 'Changing Live Campaign',
        icon: <ExclamationCircleOutlined />,
        content:
          'These campaign edits will be seen by your app users immediately. Are you sure you want to make these changes?',
        onOk: updateCampaign,
        centered: true,
      });
    }
  }
}
