import React, { useMemo } from 'react';

import { TagsOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Form, Select } from 'antd';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { deconstructVariable } from 'src/utils/paywall';
import { namiSmoke } from 'src/variables';

import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type CarouselInputSelectorProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  variant: 'text' | 'image';
};

const selector = createSelector(
  [
    ({ paywallBuilder }: RootState) =>
      paywallBuilder.paywall?.template['ui.carousels'] || {},
  ],
  (carousels) => carousels
);

export default function CarouselInputSelector({
  defaultValue,
  controlled = false,
  componentLocation,
  variant = 'text',
  ...field
}: CarouselInputSelectorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const carousels = useAppSelector(selector);

  const itemProps = {
    name: controlled ? undefined : field.variable,
    label: <InputLabel {...field} />,
  };

  const selectOptions = (carousels['carouselName'].fields || []).reduce(
    (output, field) => {
      if (field.type === variant) {
        return [
          ...output,
          {
            value: deconstructVariable(field.variable, false, true),
            label: `Slide ${field.label}`,
          },
        ];
      }
      return output;
    },
    [] as Array<{ value: string; label: string }>
  );

  const variableValue = useMemo(() => {
    return deconstructVariable(field.variable || '', false, true);
  }, [field.variable]);

  return (
    <Form.Item
      {...itemProps}
      tooltip={{
        title: (
          <>
            <IconActionButton
              type="text"
              size="small"
              icon={<TagsOutlined style={{ fontSize: '13px' }} />}
              style={{ color: namiSmoke }}
              onClick={() => {
                actions.setEditingComponentId('carousel'); //TODO - handle multiple carousels
                actions.setSelectedTreeKey('carousel');
                actions.setEditingSlideId(null);
              }}
            >
              Edit Carousel Slide Content
            </IconActionButton>
          </>
        ),
      }}
    >
      <Select
        value={variableValue}
        placeholder="Select input"
        options={selectOptions}
        onChange={(value: string) => handleChange(value)}
      />
    </Form.Item>
  );

  function handleChange(value: string) {
    if (!componentLocation) {
      console.warn(
        `Cannot update carousel input to ${value}, component location is unknown`
      );
      return;
    }

    if (variant === 'text') {
      actions.updateComponentCarouselText({
        location: componentLocation,
        value: `\${slide.${value}}`,
      });
    }
  }
}
