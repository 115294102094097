import React, { useState } from 'react';

import { Form, Modal, Select, Space } from 'antd';

import { useAppOptions } from '../../../../../hooks/queries/app.hooks';
import { useDuplicatePaywallMutation } from '../../../../../hooks/queries/paywall.hooks';
import { useAppSelector } from '../../../../../hooks/redux.hooks';

type DuplicatePaywallModalProps = {
  open: boolean;
  onDuplicateSettled: () => void;
  onCancel: () => void;
  paywallId: string;
};

export default function DuplicatePaywallModal({
  open,
  onDuplicateSettled,
  onCancel,
  paywallId,
}: DuplicatePaywallModalProps) {
  // TODO: Figure how to merge with "DuplicateRuleModal"
  const { appId } = useAppSelector(({ root }) => ({
    appId: root.currentApp?.id,
  }));
  const [selectedAppId, setSelectedAppId] = useState(appId || null);
  const duplicatePaywallMutation = useDuplicatePaywallMutation({
    paywallId,
    onSettled: onDuplicateSettled,
  });
  const { appOptions, appIdMap } = useAppOptions();

  const selectedApp = findAppOption(selectedAppId);
  const currentApp = findAppOption(appId);

  return (
    <Modal
      title="Duplicate Paywall"
      open={open}
      onCancel={onCancel}
      onOk={() => {
        const app = appIdMap[selectedAppId!];
        duplicatePaywallMutation.mutate({ app });
      }}
      confirmLoading={duplicatePaywallMutation.isLoading}
      okText="Duplicate"
      zIndex={1002}
      centered
      closable={false}
    >
      <Form layout="vertical">
        <Space>
          Source App:
          {currentApp?.label || 'None'}
        </Space>
        <Form.Item label="Select App" className="intercom-selectApptoDuplicate">
          <Select
            options={appOptions}
            value={selectedApp}
            onChange={(value) => setSelectedAppId(value as unknown as string)}
            size="large"
          />
        </Form.Item>
      </Form>
    </Modal>
  );

  function findAppOption(appId: string | null) {
    return (appId && appOptions.find(({ value }) => value === appId)) || null;
  }
}
