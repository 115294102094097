import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  DeleteOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  PushpinOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Typography,
  UploadFile,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from 'node_modules/rc-upload/lib/interface';
import { useHistory } from 'react-router-dom';
import api from 'src/api';
import { TCampaignLabel } from 'src/api/types/campaign.types';
import { AppContext } from 'src/AppContextProvider';
import Loading from 'src/components/Loading/Loading';
import {
  useArchiveCampaignLabelMutation,
  useCampaignLabelQuery,
  useToggleCampaignLabelMutation,
  useUpdateCampaignLabelMutation,
} from 'src/hooks/queries/campaign.hooks';
import Token from 'src/services/token';
import { isValidURL } from 'src/utils/string';
import { namiAliceGray, namiDarkGray } from 'src/variables';
import styled from 'styled-components';

import { StyledUpload } from '../paywalls/PaywallBuilder/editor/inputs/ImageButton';
import CampaignSnippets from './detail/CampaignSnippets';
import PlacementStatusLabel from './utils/PlacementStatusLabel';

const WhiteText = styled.span<{ as?: any }>`
  color: white;
  transition: all 0.3s !important;

  &:hover {
    transform: scale(1.2);
  }
`;
const ResponsiveDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 700px !important;

  @media only screen and (max-width: 767px) {
    width: 400px !important;
  }

  @media only screen and (max-width: 480px) {
    width: 320px !important;
  }
`;

type EditLabelModalProps = {
  labelId: string;
  open: boolean;
  onClose: () => void;
};

export default function EditLabelModal({
  labelId,
  open,
  onClose,
}: EditLabelModalProps) {
  const { userHasEntitlement, planHasEntitlement } = useContext(AppContext);
  const history = useHistory();
  const label = useCampaignLabelQuery(labelId);
  const updateLabelMutation = useUpdateCampaignLabelMutation(labelId);
  const toggleLabelMutation = useToggleCampaignLabelMutation();
  const archiveLabelMutation = useArchiveCampaignLabelMutation();
  const [form] = useForm<TCampaignLabel>();
  const labelValue = Form.useWatch('value', form);
  const [file, setFile] = useState<UploadFile | null>(
    label.data?.screenshot ? buildFile(label.data.screenshot) : null
  );
  const [loading, setLoading] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState<string | null>();

  useEffect(() => {
    setScreenshotUrl(label.data?.screenshot);
  }, [label.data?.screenshot]);

  const canEditLabel = userHasEntitlement('app.campaign.update');

  const numberRules = useMemo(() => {
    return Object.keys(label.data?.rules || {}).length;
  }, [label.data]);

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      notification.error({ message: 'Screenshot must be smaller than 1MB' });
    }
    return isLt1M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getSwitch = useMemo(() => {
    if (label.isFetching || label.isLoading || !label.data) {
      return <></>;
    }
    return (
      <Switch
        checked={label.data.enabled}
        size="small"
        onChange={() => toggleLabelMutation.mutate(label?.data)}
        loading={toggleLabelMutation.isLoading}
        disabled={
          !userHasEntitlement('app.campaign.update') || label.data.archived
        }
        className="campaignSwitch"
      />
    );
  }, [label, userHasEntitlement, toggleLabelMutation]);

  return (
    <>
      <ResponsiveDrawer
        title={
          <Space direction="vertical" size={5} style={{ width: '100%' }}>
            <Row justify="end">
              <Col xs={16} md={20}>
                <>Placement Settings</>
              </Col>
              <Col xs={8} md={4}>
                <Space direction="horizontal" style={{ float: 'right' }}>
                  <PlacementStatusLabel
                    enabled={!!label.data?.enabled}
                    archived={!!label.data?.archived}
                  />
                  {getSwitch}
                </Space>
              </Col>
            </Row>
            <Space direction="horizontal" size={2}>
              <Button
                type="text"
                icon={
                  <PushpinOutlined
                    style={{ color: namiDarkGray, fontSize: 13 }}
                  />
                }
                size="small"
                style={{
                  maxWidth: 350,
                  background: `${namiAliceGray}`,
                  borderRadius: 4,
                  marginRight: 5,
                }}
              >
                <Typography.Text ellipsis={true}>
                  {label.isFetching ? 'Loading...' : label.data?.value}
                </Typography.Text>
              </Button>
              <>{`>`}</>
              <Button
                type="text"
                size="small"
                icon={<PlayCircleOutlined style={{ fontSize: 12 }} />}
                onClick={() => {
                  onClose();
                  history.push(`/campaigns/rules?label_id=${labelId}`);
                }}
              >
                {numberRules} Campaign Rule{`${numberRules !== 1 ? 's' : ''}`}
              </Button>
            </Space>
          </Space>
        }
        open={open}
        onClose={onClose}
        footer={null}
        zIndex={1002}
      >
        {label.isFetching ? (
          <Loading />
        ) : (
          <Form
            layout="vertical"
            initialValues={{ ...label.data }}
            form={form}
            onFinish={() => {
              form
                .validateFields()
                .then(() =>
                  updateLabelMutation.mutate({
                    ...form.getFieldsValue(),
                    screenshot: screenshotUrl || null,
                  })
                )
                .then(() => onClose());
            }}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="value"
                  label="Placement Label"
                  style={{ marginBottom: 16 }}
                  rules={[
                    () => {
                      const isUrlType = label.data?.type === 'url';
                      return {
                        message: isUrlType
                          ? 'Please enter a valid URL'
                          : 'Please enter an alphanumeric value with no spaces or special characters',
                        validator: (_, value) => {
                          const isValid = isUrlType
                            ? isValidURL(value)
                            : /^[a-zA-Z0-9-_]+$/g.test(value);
                          return isValid ? Promise.resolve() : Promise.reject();
                        },
                      };
                    },
                  ]}
                >
                  <Input maxLength={255} readOnly={!canEditLabel} />
                </Form.Item>
              </Col>
              {planHasEntitlement('app.placement.create_infinite') && (
                <Col xs={24} md={12}>
                  <Form.Item
                    name="display_name"
                    label="Display Name"
                    style={{ marginBottom: 16 }}
                  >
                    <Input maxLength={255} readOnly={!canEditLabel} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item name="description" label="Description">
                  <Input.TextArea maxLength={255} readOnly={!canEditLabel} />
                </Form.Item>
              </Col>
            </Row>
            {planHasEntitlement('app.placement.create_infinite') && (
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item name="screenshot" label="Screenshot">
                    <StyledUpload
                      name="logo"
                      listType="picture-card"
                      className="avatar-uploader"
                      accept=".jpg,.jpeg,.png"
                      showUploadList={{
                        previewIcon: <WhiteText as={ZoomInOutlined} />,
                        removeIcon: <WhiteText as={DeleteOutlined} />,
                      }}
                      customRequest={({ file }: UploadRequestOption) =>
                        handleNewFile(file)
                      }
                      beforeUpload={beforeUpload}
                      fileList={file ? [file] : []}
                      onChange={handleChange}
                      maxCount={1}
                      disabled={loading || !canEditLabel}
                    >
                      {!screenshotUrl && !loading && uploadButton}
                    </StyledUpload>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              <Col xs={12}>
                <Space direction="horizontal">
                  {labelValue !== label.data?.value && (
                    <Popconfirm
                      title={
                        <>
                          <Typography.Paragraph>
                            Are you sure you want to change this placement
                            label?
                          </Typography.Paragraph>
                          <Typography.Paragraph>
                            You will need to update your app code to reflect the
                            new value.
                          </Typography.Paragraph>
                        </>
                      }
                      onConfirm={() => {
                        form
                          .validateFields()
                          .then(() =>
                            updateLabelMutation.mutate(form.getFieldsValue())
                          )
                          .then(() => onClose());
                      }}
                    >
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="intercom-updatePlacement"
                        loading={updateLabelMutation.isLoading}
                        style={{ width: '10rem' }}
                        ghost
                        disabled={label.data?.archived}
                      >
                        Save
                      </Button>
                    </Popconfirm>
                  )}
                  {!labelValue ||
                    (labelValue === label.data?.value && (
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="intercom-updatePlacement"
                        loading={updateLabelMutation.isLoading}
                        style={{ width: '10rem' }}
                        disabled={!canEditLabel || label.data?.archived}
                        ghost
                      >
                        Save
                      </Button>
                    ))}
                  {label.isFetched && !label.data?.archived && (
                    <Popconfirm
                      title="Are you sure? If you archive this placement, it will not be available to raise in the app."
                      cancelText="No"
                      okText="Yes, archive"
                      onConfirm={() => {
                        archiveLabelMutation.mutate({
                          label_id: labelId,
                          archived: label.data?.archived || false,
                        });
                        onClose();
                      }}
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        disabled={!canEditLabel}
                      >
                        Archive Placement
                      </Button>
                    </Popconfirm>
                  )}
                  {label.isFetched && label.data?.archived && (
                    <Button
                      icon={<DeleteOutlined />}
                      disabled={!canEditLabel}
                      onClick={() => {
                        archiveLabelMutation.mutate({
                          label_id: labelId,
                          archived: label.data?.archived || false,
                        });
                        onClose();
                      }}
                    >
                      Unarchive Placement
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col xs={24}>
                <CampaignSnippets label={labelValue} />
              </Col>
            </Row>
          </Form>
        )}
      </ResponsiveDrawer>
    </>
  );

  function handleChange(info: UploadChangeParam): void {
    if (info.file.status === 'removed') {
      deleteImage();
    } else if (info.file.status === 'uploading') {
      setLoading(true);
    } else if (info.file.status === 'done') {
      setLoading(false);
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setFile(info.file);
      });
    }
  }

  function deleteImage() {
    setScreenshotUrl(null);
    setFile(null);
  }

  function handleNewFile(file: string | Blob | RcFile): void {
    if (file && typeof file !== 'string') {
      setLoading(true);
      api.uploadFile(file, { orgId: Token.getOrgId()! }).then((url) => {
        setScreenshotUrl(url);
        setFile(buildFile(url));
        setLoading(false);
      });
    } else {
      setFile(buildFile(file));
    }
  }
}

function buildFile(file: string | Blob | RcFile): UploadFile {
  const url = typeof file === 'string' ? file : URL.createObjectURL(file);
  const name = url.split('/').reverse()[0];
  return { uid: '-1', name, status: 'done', url };
}
