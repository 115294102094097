import React, { useEffect, useMemo, useState } from 'react';

import { PlusOutlined, StarFilled } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import { createSelector } from 'reselect';
import { useAppContext } from 'src/hooks';
import { toSlug } from 'src/utils/string';
import styled from 'styled-components';

import api from '../../../../../api';
import { TProductGroup } from '../../../../../api/types/paywall.types';
import { useAppSelector } from '../../../../../hooks/redux.hooks';
import { RootState } from '../../../../../redux';
import AddProductGroupModal from './AddProductGroupModal';

type ProductGroupsModalProps = {
  onClose: () => void;
  onChange(): void;
};

const FormItem = styled(Form.Item)`
  .ant-form-item-required:before {
    display: none !important;
  }
`;

const selector = createSelector(
  [
    ({ paywallBuilder: { productGroups } }: RootState) => productGroups,
    ({ paywallBuilder: { editingProductGroups } }: RootState) =>
      editingProductGroups,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.capabilities'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.dynamicAddGroups'],
  ],
  (productGroups, editingProductGroups, capabilities, dynamicAddGroups) => ({
    currentProductGroups: productGroups,
    showEditingProductGroupsModal: editingProductGroups,
    productGroupCapability: (capabilities || []).includes(
      'conditional_product_groups'
    ),
    dynamicAddGroups,
  })
);

export default function ProductGroupsEditModal({
  onChange,
  onClose,
}: ProductGroupsModalProps) {
  const {
    currentProductGroups,
    showEditingProductGroupsModal,
    productGroupCapability,
    dynamicAddGroups,
  } = useAppSelector(selector);
  const allowAddingProductGroup = dynamicAddGroups;
  const appContext = useAppContext();

  const [isLoading, setLoading] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [productGroups, setProductGroups] = useState<
    Record<string, TProductGroup>
  >({});
  const [form] = Form.useForm();
  const [selectedProductGroupId, setSelectedProductGroupId] = useState<string>(
    currentProductGroups[0].id
  );
  const defaultGroupId = useMemo(() => {
    const groups = Object.values(productGroups);
    return groups.find((group) => group.default)?.id || groups[0]?.id;
  }, [productGroups]);

  useEffect(() => {
    const groups = currentProductGroups.reduce((output, productGroup) => {
      return { ...output, [productGroup.id]: productGroup };
    }, {} as Record<string, TProductGroup>);
    setProductGroups(groups);
  }, [currentProductGroups]);

  return (
    <Modal
      title="Edit Product Groups"
      open={showEditingProductGroupsModal}
      footer={null}
      centered
      closable={true}
      zIndex={1005}
      onCancel={onClose}
      forceRender
    >
      <>
        <Select
          options={currentProductGroups.map((group) => ({
            key: group.id,
            label: `${group.display_name} ${
              productGroupCapability ? `•  ${group.ref}` : ''
            }`,
            value: group.id,
            text: group.ref,
          }))}
          value={selectedProductGroupId}
          showSearch={true}
          open={selectOpen}
          onDropdownVisibleChange={(visible) => setSelectOpen(visible)}
          dropdownRender={(menu) => (
            <>
              {menu}
              {allowAddingProductGroup && (
                <>
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setSelectOpen(false);
                        setAddModalOpen(true);
                      }}
                      disabled={
                        !appContext.userHasEntitlement(
                          'app.productgroup.create'
                        )
                      }
                    >
                      Add Product Group
                    </Button>
                  </Space>
                  <AddProductGroupModal
                    isOpen={isAddModalOpen}
                    onAdd={onChange}
                    onClose={() => setAddModalOpen(false)}
                  />
                </>
              )}
            </>
          )}
          filterOption={(input, option) =>
            (option?.label || '').toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: '100%', marginBottom: '24px' }}
          onChange={(newId) => setSelectedProductGroupId(newId)}
        />
        <Form layout="vertical" form={form} onFinish={updateProductGroups}>
          <div key={selectedProductGroupId}>
            <Row gutter={16}>
              <Col xs={18}>
                <FormItem
                  label="Display Name"
                  required
                  tooltip="This value is shown to users on your paywall"
                  style={{ marginBottom: 12 }}
                >
                  <Input
                    maxLength={100}
                    disabled={isLoading}
                    onChange={handleDisplayNameChange}
                    value={
                      productGroups[selectedProductGroupId]?.display_name || ''
                    }
                  />
                </FormItem>
              </Col>
              <Col xs={6}>
                <FormItem label="Default">
                  <Switch
                    checked={
                      productGroups[selectedProductGroupId]?.default ||
                      selectedProductGroupId === defaultGroupId ||
                      false
                    }
                    onChange={handleDefaultChange}
                    loading={isLoading}
                    checkedChildren={
                      <StarFilled style={{ fontSize: '0.9em' }} />
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            {productGroupCapability && (
              <Row>
                <Col xs={18}>
                  <FormItem
                    label="Reference ID"
                    required
                    tooltip="This value is for your app code. Provide an alphanumeric string without any special characters."
                    rules={[
                      {
                        pattern: /^[a-zA-Z0-9-]+$/g,
                        message:
                          'Please enter an alphanumeric value with no special characters',
                      },
                      {
                        required: true,
                        message: 'Provide an alphanumeric value',
                      },
                    ]}
                  >
                    <Input
                      maxLength={100}
                      onChange={handleRefChange}
                      value={productGroups[selectedProductGroupId]?.ref || ''}
                      disabled={
                        isLoading ||
                        !appContext.userHasEntitlement(
                          'app.productgroup.id.update'
                        )
                      }
                    />
                  </FormItem>
                </Col>
              </Row>
            )}
          </div>
          <Button
            htmlType="submit"
            loading={isLoading}
            type="primary"
            disabled={form.getFieldsError().some(({ errors }) => errors.length)}
          >
            Save
          </Button>
        </Form>
      </>
    </Modal>
  );

  function handleDisplayNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setProductGroups((state) => {
      const display_name = e.target.value;
      const productGroup = {
        ...state[selectedProductGroupId],
        display_name,
      };
      return { ...state, [selectedProductGroupId]: productGroup };
    });
  }

  function handleRefChange(e: React.ChangeEvent<HTMLInputElement>) {
    setProductGroups((state) => {
      const ref = toSlug(e.target.value, '-');
      const group = { ...state[selectedProductGroupId], ref };
      return { ...state, [selectedProductGroupId]: group };
    });
  }

  function handleDefaultChange(flag: boolean) {
    setProductGroups((state) => {
      return Object.values(state).reduce((state, group) => {
        const value = group.id === selectedProductGroupId ? flag : !flag;
        const newGroup = { ...state[group.id], default: value };
        return { ...state, [group.id]: newGroup };
      }, state);
    });
  }

  function updateProductGroups() {
    setLoading(true);
    const promises = Object.values(productGroups).map(
      ({ id: groupId, ...group }) => {
        if (!!groupId) return api.updateProductGroup(groupId, group);
        return api.createProductGroup(group);
      }
    );
    Promise.all(promises)
      .then(() => onChange())
      .finally(() => {
        setLoading(false);
        onClose();
      });
  }
}
