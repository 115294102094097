import React from 'react';

import {
  CopyOutlined,
  DeleteOutlined,
  ReadOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
  notification,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router';
import { CampaignRuleReferenceType } from 'src/api/types/main.types';
import DisabledBadgeDot from 'src/components/StatusDots/DisabledBadgeDot';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import ScheduledDot from 'src/components/StatusDots/ScheduledDot';
import styled from 'styled-components';

import { PaywallType } from '../../../../../api/types/paywall.types';
import { useAppContext, useBooleanState } from '../../../../../hooks';
import { useAppSelector } from '../../../../../hooks/redux.hooks';
import DuplicatePaywallModal from './DuplicatePaywallModal';
import SwapPaywallModal from './SwapPaywallModal';

type PaywallSettingsModalProps = {
  open: boolean;
  archived: boolean;
  onSave: (values: Partial<PaywallType>) => void;
  isSaving: boolean;
  onClose: () => void;
  onArchive: () => void;
};

const StyledTag = styled(Tag)`
  margin-right: 0px;
  text-wrap: wrap;
`;

export default function PaywallSettingsModal({
  open,
  archived,
  onSave,
  isSaving,
  onClose,
  onArchive,
}: PaywallSettingsModalProps) {
  const [paywall, hasChanges] = useAppSelector(({ paywallBuilder }) => {
    return [paywallBuilder.paywall, paywallBuilder.hasChanges];
  });
  const [isDuplicateModalOpen, openDuplicate, closeDuplicate] =
    useBooleanState(false);
  const [isSwapModalOpen, openSwapModal, closeSwapModal] =
    useBooleanState(false);
  const appContext = useAppContext();

  if (!paywall) return null;

  return (
    <>
      <Modal
        title="Settings"
        open={open && !isDuplicateModalOpen && !isSwapModalOpen}
        onCancel={onClose}
        footer={null}
        zIndex={1002}
        centered
        width={700}
      >
        <Form
          layout="vertical"
          initialValues={{ ...paywall }}
          onFinish={onSave}
          style={{ marginBottom: 24 }}
        >
          <Row gutter={16}>
            <Col xs={24} style={{ paddingBottom: '12px' }}>
              <Typography.Text strong>Template: </Typography.Text>
              <Typography.Text>
                {paywall.template.codename} v{paywall.template.version}
              </Typography.Text>
              {getDocLink()}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} style={{ paddingBottom: '12px' }}>
              <Typography.Text strong>Minimum SDK Version: </Typography.Text>
              <Typography.Text>
                {paywall.template.min_sdk_version || '3.0.0'}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} style={{ paddingBottom: '24px' }}>
              <Space direction="horizontal" size={'small'} wrap>
                <Typography.Text strong>Associated Campaigns:</Typography.Text>
                {paywall.rules.length > 0 ? (
                  paywall.rules.map((rule) => (
                    <RuleTag key={rule.id} {...rule} />
                  ))
                ) : (
                  <span style={{ fontStyle: 'italic' }}>None</span>
                )}
                {paywall.rules.length > 0 && getSwapButton()}
              </Space>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="name"
                label="Paywall Name"
                style={{ marginBottom: 16 }}
              >
                <Input
                  maxLength={255}
                  disabled={
                    !appContext.userHasEntitlement('app.paywall.update')
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea
                  maxLength={255}
                  disabled={
                    !appContext.userHasEntitlement('app.paywall.update')
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Space>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={isSaving}
                  style={{ width: '10rem' }}
                  ghost
                  className="intercom-savePaywallSettings"
                  disabled={
                    !appContext.userHasEntitlement('app.paywall.update')
                  }
                >
                  Save
                </Button>
                <Button
                  loading={isSaving}
                  icon={<CopyOutlined />}
                  disabled={
                    !appContext.userHasEntitlement('app.paywall.create')
                  }
                  onClick={() => {
                    if (hasChanges) {
                      notification.warn({
                        message: 'Save paywall edits before duplicating',
                      });
                    } else {
                      openDuplicate();
                    }
                  }}
                  className="intercom-duplicatePaywall"
                >
                  Duplicate Paywall
                </Button>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Button
                type="text"
                loading={isSaving}
                className="intercom-archive-paywall"
                onClick={onArchive}
                disabled={
                  !appContext.userHasEntitlement('app.paywall.update') ||
                  paywall.rules.some((rule) => rule.archived === false)
                }
                icon={<DeleteOutlined style={{ fontSize: 13 }} />}
              >
                {archived ? `Unarchive Paywall` : `Archive Paywall`}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <DuplicatePaywallModal
        open={open && isDuplicateModalOpen}
        onCancel={closeDuplicate}
        onDuplicateSettled={() => {
          closeDuplicate();
          onClose();
        }}
        paywallId={paywall.id}
      />
      <SwapPaywallModal
        open={open && isSwapModalOpen}
        onCancel={closeSwapModal}
        currentPaywall={paywall}
        onSwapSettled={() => {
          closeSwapModal();
          onClose();
        }}
      />
    </>
  );

  function getDocLink() {
    if (paywall?.template.doc_link) {
      return (
        <Typography.Link
          href={paywall.template.doc_link}
          target="_blank"
          rel="noreferrer"
        >
          <Space direction="horizontal" size={3} style={{ paddingLeft: 16 }}>
            {<ReadOutlined style={{ fontSize: '13px' }} />}
            Docs
          </Space>
        </Typography.Link>
      );
    }
    return;
  }

  function getSwapButton() {
    if (appContext.planHasEntitlement('app.campaign.bulk_update')) {
      return (
        <Button
          type="text"
          icon={<SwapOutlined />}
          onClick={openSwapModal}
          disabled={!appContext.userHasEntitlement('app.campaign.update')}
        >
          Replace
        </Button>
      );
    }
    return undefined;
  }
}

export function RuleTag(rule: CampaignRuleReferenceType) {
  const history = useHistory();

  return (
    <Typography.Link onClick={() => history.push(`/campaigns/${rule.id}`)}>
      <StyledTag>
        {getStatusDot()}
        <span style={{ marginLeft: 4 }}>{rule.name}</span>
      </StyledTag>
    </Typography.Link>
  );

  function getStatusDot() {
    const startsInFuture =
      rule.not_before && moment(rule.not_before).isAfter(moment());

    if (rule.enabled && !rule.archived) {
      if (startsInFuture) {
        return <ScheduledDot />;
      }
      return <LiveBadgeDot />;
    } else if (!rule.enabled && !rule.archived) {
      return <DisabledBadgeDot />;
    }
    return <DeleteOutlined style={{ fontSize: 10 }} />;
  }
}
