import React, { useState } from 'react';

import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { toSlug } from 'src/utils/string';
import { PartialAttrs } from 'src/utils/types';
import styled from 'styled-components';

import api from '../../../../../api';
import { TProductGroup } from '../../../../../api/types/paywall.types';
import { useAppSelector } from '../../../../../hooks/redux.hooks';
import { RootState } from '../../../../../redux';

type ProductGroupsModalProps = {
  onChange(): void;
};

const FormItem = styled(Form.Item)`
  .ant-form-item-required:before {
    display: none !important;
  }
`;

const selector = createSelector(
  [
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.requiredGroups'],
    ({ paywallBuilder: { productGroups } }: RootState) => productGroups,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.capabilities'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.dynamicAddGroups'],
  ],
  (requiredGroups, productGroups, capabilities, dynamicAddGroups) => ({
    show:
      typeof requiredGroups === 'number' &&
      requiredGroups > productGroups.length &&
      !!!dynamicAddGroups,
    requiredGroups: requiredGroups || 0,
    currentProductGroups: productGroups,
    productGroupCapability: (capabilities || []).includes(
      'conditional_product_groups'
    ),
  })
);

export default function ProductGroupsModal({
  onChange,
}: ProductGroupsModalProps) {
  const paywallId = useParams<{ paywallID: string }>().paywallID;
  const { requiredGroups, show, currentProductGroups, productGroupCapability } =
    useAppSelector(selector);

  const [isLoading, setLoading] = useState(false);
  const [productGroups, setProductGroups] = useState(
    currentProductGroups.reduce((output, productGroup, i) => {
      return { ...output, [i]: productGroup };
    }, {} as { [key: number]: PartialAttrs<TProductGroup, 'id'> })
  );

  const isFormValid =
    Object.values(productGroups).filter((group) => !!group.display_name)
      .length === requiredGroups;
  return (
    <Modal
      title="Setup Product Groups"
      open={show}
      footer={null}
      centered
      closable={false}
      zIndex={1002}
    >
      <Form layout="vertical">
        {Array(requiredGroups)
          .fill(0)
          .map((_, i) => {
            return (
              <div key={i}>
                <Typography.Text key={`title${i}`}>
                  <strong>{`Group ${i + 1}`}</strong>
                </Typography.Text>
                <Row gutter={16} key={`name${i}`}>
                  <Col xs={24}>
                    <FormItem label={`Name`} required>
                      <Input
                        onChange={handleNameChange}
                        disabled={isLoading}
                        maxLength={100}
                      />
                    </FormItem>
                  </Col>
                </Row>
                {productGroupCapability && (
                  <Row gutter={16} key={`refId${i}`}>
                    <Col xs={24}>
                      <FormItem label={`Ref Id`} required>
                        <Input
                          onChange={handleRefIdChange}
                          disabled={isLoading}
                          maxLength={100}
                          placeholder="Short slug for use in code"
                        />
                      </FormItem>
                    </Col>
                  </Row>
                )}
              </div>
            );

            function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
              setProductGroups((state) => {
                const productGroup =
                  i in state
                    ? {
                        ...state[i],
                        display_name: e.target.value,
                        ref: productGroupCapability
                          ? state[i].ref
                          : toSlug(e.target.value, '-'),
                      }
                    : {
                        default: false,
                        paywall: paywallId,
                        display_name: e.target.value,
                        display_order: i + 1,
                        ref: toSlug(e.target.value, '-'),
                      };
                return { ...state, [i]: productGroup };
              });
            }

            function handleRefIdChange(e: React.ChangeEvent<HTMLInputElement>) {
              setProductGroups((state) => {
                const productGroup =
                  i in state
                    ? {
                        ...state[i],
                        ref: e.target.value,
                      }
                    : {
                        default: false,
                        paywall: paywallId,
                        display_name: e.target.value,
                        display_order: i + 1,
                        ref: e.target.value,
                      };
                return { ...state, [i]: productGroup };
              });
            }
          })}
        <Button
          htmlType="submit"
          onClick={updateProductGroups}
          loading={isLoading}
          disabled={!isFormValid}
          type="primary"
        >
          Continue to paywall editor
        </Button>
      </Form>
    </Modal>
  );

  function updateProductGroups() {
    setLoading(true);
    const promises = Object.values(productGroups).map(
      ({ id: groupId, ...group }) => {
        if (!!groupId) return api.updateProductGroup(groupId, group);
        return api.createProductGroup(group);
      }
    );
    Promise.all(promises)
      .then(() => onChange())
      .catch(() => setLoading(false));
  }
}
