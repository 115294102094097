import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { useHistory } from 'react-router';

import api from '../../api';
import { bareClient } from '../../api/clients';
import {
  PlatformAllowedOriginType,
  PlatformPayloadType,
} from '../../api/types/main.types';
import { downloadContent } from '../../utils/file';
import { useAppSelector } from '../redux.hooks';
import QueryKeys from './queryKeys';

export function usePlatformsQuery() {
  const appId = useAppSelector(({ root }) => root.currentApp?.id);

  return useQuery([QueryKeys.platforms, appId], () => api.getPlatforms(appId), {
    enabled: !!appId,
  });
}

export function usePlatformQuery(platformId: string | undefined) {
  return useQuery(
    [QueryKeys.platforms, platformId],
    () => api.getPlatform(platformId!),
    { enabled: !!platformId }
  );
}

export function useCreateOrUpdatePlatformMutation(
  platformId: string | undefined
) {
  const history = useHistory();
  const appId = useAppSelector(({ root }) => root.currentApp?.id);
  const queryClient = useQueryClient();

  return useMutation(
    ['createOrUpdatePlatform'],
    (payload: Omit<PlatformPayloadType, 'app'>) => {
      return platformId
        ? api.updatePlatform(platformId, payload)
        : api.addPlatform({ app: appId, ...payload });
    },
    {
      onSuccess: () => {
        if (platformId) {
          const message = 'Your integration changes were successfully saved';
          notification.success({ message });
        } else {
          queryClient
            .invalidateQueries([QueryKeys.platforms])
            .then(() => history.push('/integrations/'));
        }
      },
    }
  );
}

export function useDeletePlatformMutation(platformId: string) {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(['deletePlatform'], () => api.deletePlatform(platformId), {
    onSuccess: () => {
      history.push('/integrations/');
      return queryClient.invalidateQueries([QueryKeys.platforms]);
    },
  });
}

export function useDownloadInitialConfigMutation(platformId: string) {
  return useMutation(
    ['downloadInitialConfig'],
    () =>
      bareClient.get<Record<string, any>>(
        `/sdk/v3/platform/${platformId}/initial_config/`
      ),
    {
      onSuccess: ({ data }) =>
        downloadContent('initial_config.json', JSON.stringify(data, null, 0)),
    }
  );
}

export function useDownloadInitialConfigMutationVersion31(platformId: string) {
  return useMutation(
    ['downloadInitialConfig'],
    () =>
      bareClient.get<Record<string, any>>(
        `/sdk/v3/platform/${platformId}/initial_config/?sdk_version=3.1.0`
      ),
    {
      onSuccess: ({ data }) =>
        downloadContent('initial_config.json', JSON.stringify(data, null, 0)),
    }
  );
}

export function usePlatformAllowedOriginsQuery(
  integrationId: string | undefined
) {
  return useQuery([QueryKeys.platformAllowedOrigins], () =>
    api.getPlatformAllowedOrigins(integrationId!)
  );
}

export function useCreatePlatformAllowedOriginMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ['createPlatformAllowedOrigin'],
    (payload: Omit<PlatformAllowedOriginType, 'id'>) => {
      return api.createPlatformAllowedOrigin(payload);
    },
    {
      onSuccess: () => {
        const message = 'CORS Allowed Origin was successfully added';
        notification.success({ message });
        queryClient.invalidateQueries([QueryKeys.platformAllowedOrigins]);
      },
    }
  );
}

export function useDeletePlatformAllowedOriginMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ['deletePlatformAllowedOrigin'],
    (payload: { originId: string }) => {
      return api.deletePlatformAllowedOrigin(payload.originId);
    },
    {
      onSuccess: () => {
        const message = 'CORS Allowed Origin was successfully deleted';
        notification.success({ message });
        queryClient.invalidateQueries([QueryKeys.platformAllowedOrigins]);
      },
    }
  );
}
