import React from 'react';

import { TDevice, TDeviceOrientation } from 'src/api/types/paywall.types';
import { TSemverObj } from 'src/utils/parsing';

import { TPaywallTemplate } from '../../api/types/paywallTemplate.types';
import Desktop from '../Desktop/Desktop';
import IPad from '../IPad/IPad';
import IPhone from '../IPhone/IPhone';
import TV from '../TV/TV';
import BackgroundContainer from './components/BackgroundContainer';
import Content from './components/Content';
import Footer from './components/Footer';
import Header from './components/Header';

type PaywallPreviewProps = {
  device: TDevice;
  template: TPaywallTemplate;
  orientation: TDeviceOrientation;
  focusedState: boolean;
  currentPage: string;
  groupId: string | null;
  minSDKVersion: TSemverObj;
};

const DEVICE_COMPONENTS = {
  phone: IPhone,
  tablet: IPad,
  television: TV,
  desktop: Desktop,
};

export default function PaywallPreview({
  device,
  template,
  orientation,
  focusedState,
  currentPage,
  groupId,
  minSDKVersion,
}: PaywallPreviewProps) {
  const DeviceComponent = DEVICE_COMPONENTS[device];
  const { pages, initialState } = template;

  const {
    header,
    backgroundContainer: background,
    contentContainer: content,
    footer,
  } = pages.find((page) => page.name === currentPage) || pages[0];

  return (
    <DeviceComponent orientation={orientation} inFocusedState={focusedState}>
      <BackgroundContainer
        component={background}
        device={device}
        zIndex={1}
        fullscreen={initialState?.fullScreenPresentation}
        groupId={groupId}
        minSDKVersion={minSDKVersion}
      />
      <Header
        components={header || []}
        device={device}
        zIndex={2}
        inFocusedState={focusedState}
        inFullScreen={initialState?.fullScreenPresentation}
        groupId={groupId}
        slides={initialState?.slides}
        minSDKVersion={minSDKVersion}
      />
      <Content
        component={content}
        device={device}
        zIndex={2}
        inFocusedState={focusedState}
        groupId={groupId}
        slides={initialState?.slides}
        minSDKVersion={minSDKVersion}
      />
      <Footer
        components={footer || []}
        zIndex={3}
        inFocusedState={focusedState}
        groupId={groupId}
        minSDKVersion={minSDKVersion}
      />
    </DeviceComponent>
  );
}
