import React, { useContext, useEffect } from 'react';

import { DownOutlined, ReadOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  Divider,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Space,
  Spin,
  Typography,
} from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { useParams } from 'react-router-dom';
import { platformName } from 'src/services/helpers';
import styled from 'styled-components';

import { AppContext } from '../../../AppContextProvider';
import { PlatformConfigType } from '../../../api/types/main.types';
import SyncStatus from '../../../components/SyncStatus';
import { usePageContext } from '../../../hooks';
import {
  useCreateOrUpdatePlatformMutation,
  useDeletePlatformMutation,
  useDownloadInitialConfigMutation,
  useDownloadInitialConfigMutationVersion31,
  usePlatformQuery,
} from '../../../hooks/queries/platform.hooks';
import { getPlatformMarketplaceIdLabel } from '../../../services/helpers';
import { extractFieldError } from '../../../services/utilsService';
import { TDRFErrors } from '../../../utils/ant';
import IntegrationSnippets from './IntegrationSnippets';
import { TIntegrationParams } from './params.types';
import CORSFields from './platformFields/CORSFields';
import RewardedFields from './platformFields/RewardedFields';
import S2SUrlFields from './platformFields/S2SUrlFields';
import StoreCredentialsLink from './platformFields/StoreCredentialsLink';

const REWARDED_TYPES = new Set(['ironsource_rewarded', 'applovin_rewarded']);
type TSDKVersions = '3.0' | '3.1';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const HeaderTitle = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
`;

export default function PlatformForm() {
  const { type, integrationId: platformId } = useParams<TIntegrationParams>();
  const [form] = Form.useForm();
  const { userHasEntitlement } = useContext(AppContext);
  const { setTitle } = usePageContext();
  const platformQuery = usePlatformQuery(platformId);
  const createOrUpdateMutation = useCreateOrUpdatePlatformMutation(platformId);
  const deleteMutation = useDeletePlatformMutation(platformId!);

  const error = createOrUpdateMutation.error as TDRFErrors | undefined;
  const integrationName = platformQuery.data?.name;
  const platformType = platformQuery.data?.type;
  const platformLabel = platformType ? platformName(platformType) : 'store';
  const canUpdate = userHasEntitlement('app.platform.update');
  const lastSync = platformQuery.data?.last_sync
    ? new Date(platformQuery.data.last_sync)
    : null;
  const isAutoCreated = !!lastSync;

  useEffect(() => {
    setTitle(integrationName || type);
  }, [type, integrationName, setTitle]);

  useEffect(() => {
    if (!platformQuery.data) return;
    form.setFieldsValue(platformQuery.data);
  }, [platformQuery.data, form]);

  if (platformQuery.isFetching) return <Spin size="large" />;

  return (
    <Form
      style={{ width: '100%', height: '100%' }}
      form={form}
      layout="vertical"
    >
      <Header>
        <HeaderTitle>Settings</HeaderTitle>
        <SyncStatus
          label="Platform Sync"
          syncDate={lastSync}
          syncLabelPrefix="Last synced"
        />
      </Header>
      <Form.Item label="Name" name="name" {...buildErrorProps(error?.name)}>
        <Input placeholder="Name" disabled={!canUpdate || isAutoCreated} />
      </Form.Item>
      {type !== 'web' && (
        <Form.Item
          label={getPlatformMarketplaceIdLabel(type)}
          name="marketplace_app_id"
          {...buildErrorProps(error?.marketplace_app_id)}
        >
          <Input
            placeholder={getPlatformMarketplaceIdLabel(type)}
            disabled={!canUpdate || isAutoCreated}
          />
        </Form.Item>
      )}
      <RewardedFields />
      <S2SUrlFields />
      <StoreCredentialsLink />
      <CORSFields />
      <Space style={platformId ? {} : { marginBottom: '2rem' }}>
        <Button
          onClick={() => {
            const { config: _, ...data } = form.getFieldsValue();
            const config = getConfig();
            createOrUpdateMutation.mutate({ ...data, type, config });
          }}
          disabled={!canUpdate}
          loading={createOrUpdateMutation.isLoading}
          className="nami-primary-button"
        >
          {platformId ? 'Save Changes' : 'Add New Integration'}
        </Button>
        {userHasEntitlement('app.initial_state.list') && platformId ? (
          <ExportInitialConfigButton
            platformId={platformId}
            loading={createOrUpdateMutation.isLoading}
          />
        ) : null}
      </Space>
      {platformId && type !== 'web' && (
        <>
          <Divider />
          <HeaderTitle>Setup SDK</HeaderTitle>
          <Typography.Paragraph>
            Add this code into your app to configure the SDK and get started
            with Nami.
            <Button
              type="link"
              icon={<ReadOutlined />}
              href="https://learn.namiml.com/public-docs/get-started/sdks"
              target="_blank"
            >
              Go to Instructions
            </Button>
          </Typography.Paragraph>
          <IntegrationSnippets
            platformId={platformId}
            platformType={platformType}
          />
          <Divider />
        </>
      )}
      {!platformId || isAutoCreated ? null : (
        <Collapse ghost style={{ marginBottom: 15, marginTop: 20 }}>
          <Collapse.Panel
            header={<h4>Danger Zone</h4>}
            key="1"
            className="intercom-integrationDelete"
          >
            <Space direction="vertical">
              <Typography.Text strong>
                Deleting {integrationName} will break any apps you have
                configured with this integration. Users won't be able to
                purchase or launch a paywall. If this platform integration has
                recorded impressions, sessions, or purchases your metrics will
                be affected by deleting this integration.
              </Typography.Text>
              <Typography.Text>
                This does not delete your app from the {platformLabel} and you
                may re-add the integration later.
              </Typography.Text>
              <Button
                type="primary"
                danger
                disabled={!canUpdate}
                loading={deleteMutation.isLoading}
                onClick={() => deleteMutation.mutate()}
              >
                Delete Integration
              </Button>
            </Space>
          </Collapse.Panel>
        </Collapse>
      )}
    </Form>
  );

  function buildErrorProps(
    errors: string[] | undefined
  ): Pick<FormItemProps, 'help' | 'validateStatus'> {
    const error = extractFieldError(errors);
    return { help: error, validateStatus: error ? 'error' : undefined };
  }

  function getConfig(): Partial<PlatformConfigType> | undefined {
    const config = (form.getFieldValue('config') || {}) as
      | PlatformConfigType
      | undefined;
    // If any value was changed but erased (e.g. empty string), then ignore
    if (!(config && Object.values(config).find(Boolean))) return;

    const {
      itunes_shared_secret,
      google_service_account: googleData,
      shared_secret,
      private_key,
      api_key,
      minutes_per_reward_units,
    } = config;

    if (type === 'apple') return { itunes_shared_secret };
    if (type === 'google') {
      return { google_service_account: googleData && JSON.parse(googleData) };
    }
    if (type === 'amazon_iap') return { shared_secret };
    if (type === 'roku_pay') return { api_key };
    if (!REWARDED_TYPES.has(type)) return;
    return { private_key, minutes_per_reward_units };
  }
}

function ExportInitialConfigButton({
  loading,
  platformId,
}: {
  loading: boolean;
  platformId: string;
}) {
  const { userHasEntitlement } = useContext(AppContext);
  const downloadConfigMutationV30 =
    useDownloadInitialConfigMutation(platformId);
  const downloadConfigMutationV31 =
    useDownloadInitialConfigMutationVersion31(platformId);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (!canFetchInitialConfig) return;
    if ((e.key as TSDKVersions) === '3.1') {
      downloadConfigMutationV31.mutate();
    } else {
      downloadConfigMutationV30.mutate();
    }
  };

  const menuItems: { key: TSDKVersions; label: string }[] = [
    {
      key: '3.1',
      label: 'SDK Version 3.1+ (recommended)',
    },
    {
      key: '3.0',
      label: 'SDK Version 3.0.x',
    },
  ];

  const canFetchInitialConfig = userHasEntitlement('app.initial_state.get');
  return (
    <Dropdown menu={{ items: menuItems, onClick: handleMenuClick }}>
      <Button
        disabled={!canFetchInitialConfig}
        loading={
          loading ||
          downloadConfigMutationV30.isLoading ||
          downloadConfigMutationV31.isLoading
        }
      >
        <Space>
          Export initial config
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
