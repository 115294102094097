import React from 'react';

import { Radio } from 'antd';
import PropTypes from 'prop-types';

import Controls from './Controls';
import GradientBar from './GradientBar';
import Hue from './Hue';
import Inputs from './Inputs';
import Opacity from './Opacity';
import { usePicker } from './PickerContextWrapper';
import Presets from './Presets';
import Square from './Square';

const Picker = ({
  inDarkMode,
  hideControls,
  hideInputs,
  hidePresets,
  presets,
  hideOpacity,
  hideEyeDrop,
  hideAdvancedSliders,
  hideColorGuide,
  hideInputType,
  showDarkModeSwitcher,
  onDarkModeChange,
  dynamicColors,
}) => {
  const { isGradient } = usePicker();

  return (
    <div style={{ userSelect: 'none' }}>
      {showDarkModeSwitcher && (
        <Radio.Group
          value={inDarkMode}
          style={{ marginBottom: '1rem' }}
          onChange={
            onDarkModeChange && ((e) => onDarkModeChange(e.target.value))
          }
        >
          <Radio.Button value={false}>Light Mode</Radio.Button>
          <Radio.Button value={true}>Dark Mode</Radio.Button>
        </Radio.Group>
      )}
      <Square />
      {!hideControls && (
        <Controls
          hideEyeDrop={hideEyeDrop}
          hideAdvancedSliders={hideAdvancedSliders}
          hideColorGuide={hideColorGuide}
          hideInputType={hideInputType}
        />
      )}
      {isGradient && <GradientBar />}
      <Hue />
      {!hideOpacity && <Opacity />}
      {!hideInputs && <Inputs hideOpacity={hideOpacity} />}
      {!hidePresets && (
        <Presets
          presets={presets}
          dynamicColors={isGradient ? [] : dynamicColors}
        />
      )}
    </div>
  );
};

export default Picker;

Picker.propTypes = {
  inDarkMode: PropTypes.bool,
  hideControls: PropTypes.bool,
  hideInputs: PropTypes.bool,
  hidePresets: PropTypes.bool,
  presets: PropTypes.array,
  hideEyeDrop: PropTypes.bool,
  hideOpacity: PropTypes.bool,
  hideAdvancedSliders: PropTypes.bool,
  hideColorGuide: PropTypes.bool,
  hideInputType: PropTypes.bool,
  showDarkModeSwitcher: PropTypes.bool,
  onDarkModeChange: PropTypes.func,
  dynamicColors: PropTypes.array,
};
