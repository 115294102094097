import { TSchemaRoot } from './schema.types';

export type TApiOptions = {
  page?: number;
  pageSize?: number;
  search?: string;
  enabled?: boolean;
  archived?: boolean;
  platformId?: string;
  form_factor?: string;
};

export interface UserPoliciesType {
  password_is_expired: boolean;
}

export interface UserOrgType {
  id: string;
  name: string;
  entitlements: string[];
}

export type TierType = 'paywallsOnly' | 'paywallsAndSubscriptions';
export const TierNames: Record<TierType, string> = {
  paywallsOnly: 'No-Code Paywalls',
  paywallsAndSubscriptions: 'Paywalls + Subscriptions',
};
export type PlanType = 'legacy' | 'tiered';
export type ProductType = 'starter' | 'team' | 'business' | 'enterprise';

export type RawPlanName =
  | 'appsumo_tier1'
  | 'appsumo_tier2'
  | 'appsumo_tier3'
  | 'appsumo_tier4'
  | 'appsumo_tier5'
  | 'enterprise_paywall_mgmt_v1'
  | 'enterprise_v1'
  | 'free_v1'
  | 'free_v2'
  | 'pro_v1'
  | 'starter_plus_v1'
  | 'paywalls_only_starter_v1'
  | 'paywalls_only_team_v1'
  | 'paywalls_only_business_v1'
  | 'paywalls_only_enterprise_v1'
  | 'paywalls_and_subs_starter_v1'
  | 'paywalls_and_subs_team_v1'
  | 'paywalls_and_subs_business_v1'
  | 'paywalls_and_subs_enterprise_v1'
  | 'unlimited';

export const PlanMeta: Record<RawPlanName, TInternalProduct> = {
  appsumo_tier1: {
    plan: 'legacy',
    rawPlanName: 'appsumo_tier1',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'AppSumo',
    upgradeTo: [
      'paywalls_and_subs_team_v1',
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
  },
  appsumo_tier2: {
    plan: 'legacy',
    rawPlanName: 'appsumo_tier2',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'AppSumo',
    upgradeTo: [
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
  },
  appsumo_tier3: {
    plan: 'legacy',
    rawPlanName: 'appsumo_tier3',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'AppSumo',
    upgradeTo: [
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
  },
  appsumo_tier4: {
    plan: 'legacy',
    rawPlanName: 'appsumo_tier4',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'AppSumo',
    upgradeTo: [
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
  },
  appsumo_tier5: {
    plan: 'legacy',
    rawPlanName: 'appsumo_tier5',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'AppSumo',
    upgradeTo: [
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
  },
  enterprise_paywall_mgmt_v1: {
    plan: 'legacy',
    rawPlanName: 'enterprise_paywall_mgmt_v1',
    tier: 'paywallsOnly',
    fullReadablePlanName: 'Enterprise Paywall Management',
    upgradeTo: ['paywalls_and_subs_enterprise_v1'],
    shortPlanName: 'Legacy Enterprise',
  },
  enterprise_v1: {
    plan: 'legacy',
    rawPlanName: 'enterprise_v1',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Legacy Optimize',
    upgradeTo: [],
    basePricePerMonth: 999,
  },
  free_v1: {
    plan: 'legacy',
    rawPlanName: 'free_v1',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Legacy Start',
    basePricePerMonth: 0,
    upgradeTo: [
      'paywalls_and_subs_starter_v1',
      'paywalls_and_subs_team_v1',
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
  },
  free_v2: {
    plan: 'legacy',
    rawPlanName: 'free_v2',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Legacy Start',
    basePricePerMonth: 0,
    upgradeTo: [
      'paywalls_and_subs_starter_v1',
      'paywalls_and_subs_team_v1',
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
  },
  starter_plus_v1: {
    plan: 'legacy',
    rawPlanName: 'starter_plus_v1',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Legacy Start Plus',
    basePricePerMonth: 10,
    upgradeTo: [
      'paywalls_and_subs_starter_v1',
      'paywalls_and_subs_team_v1',
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
    purchaseLinkProd: 'https://buy.stripe.com/00gcNjg6mgkT2s06oC',
  },
  pro_v1: {
    plan: 'legacy',
    rawPlanName: 'pro_v1',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Legacy Grow',
    basePricePerMonth: 149,
    upgradeTo: [
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
    purchaseLinkProd: 'https://buy.stripe.com/14k7sZ5rIfgPd6EeV7',
  },
  paywalls_only_starter_v1: {
    plan: 'tiered',
    product: 'starter',
    rawPlanName: 'paywalls_only_starter_v1',
    tier: 'paywallsOnly',
    fullReadablePlanName: 'No-Code Paywalls Starter',
    shortPlanName: 'Starter',
    basePricePerMonth: 5,
    upgradeTo: [
      'paywalls_only_team_v1',
      'paywalls_only_business_v1',
      'paywalls_only_enterprise_v1',
    ],
    purchaseLinkProd: 'https://buy.stripe.com/00geVrbQ65Gf4A814l',
    purchaseLinkStaging: 'https://buy.stripe.com/test_bIYfZf4Gm7ic2TS3cg',
  },
  paywalls_only_team_v1: {
    plan: 'tiered',
    product: 'team',
    rawPlanName: 'paywalls_only_team_v1',
    tier: 'paywallsOnly',
    fullReadablePlanName: 'No-Code Paywalls Team',
    shortPlanName: 'Team',
    basePricePerMonth: 99,
    upgradeTo: ['paywalls_only_business_v1', 'paywalls_only_enterprise_v1'],
    purchaseLinkProd: 'https://buy.stripe.com/00g14B5rI5GfaYwbIY',
    purchaseLinkStaging: 'https://buy.stripe.com/test_14k14ldcS31W7a8bIK',
    marketingTagLine: 'Deploy and test paywalls faster',
  },
  paywalls_only_business_v1: {
    plan: 'tiered',
    product: 'business',
    rawPlanName: 'paywalls_only_business_v1',
    tier: 'paywallsOnly',
    fullReadablePlanName: 'No-Code Paywalls Business',
    shortPlanName: 'Business',
    basePricePerMonth: 249,
    upgradeTo: ['paywalls_only_enterprise_v1'],
    purchaseLinkProd: 'https://buy.stripe.com/fZeaFb1bsd8H0jScN4',
    purchaseLinkStaging: 'https://buy.stripe.com/test_00g7sJb4K4607a8006',
    marketingTagLine: 'Personalize for better paywalls',
  },
  paywalls_only_enterprise_v1: {
    plan: 'tiered',
    product: 'enterprise',
    rawPlanName: 'paywalls_only_enterprise_v1',
    tier: 'paywallsOnly',
    fullReadablePlanName: 'No-Code Paywalls Enterprise',
    shortPlanName: 'Enterprise',
    upgradeTo: [],
    basePricePerMonth: 749,
    marketingTagLine: 'Paywall optimization at scale',
  },
  paywalls_and_subs_starter_v1: {
    plan: 'tiered',
    product: 'starter',
    rawPlanName: 'paywalls_and_subs_starter_v1',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Paywalls and Subscriptions Starter',
    shortPlanName: 'Starter',
    basePricePerMonth: 8,
    upgradeTo: [
      'paywalls_and_subs_team_v1',
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
    purchaseLinkProd: 'https://buy.stripe.com/fZe6oV3jAgkT6Ig6oH',
    purchaseLinkStaging: 'https://buy.stripe.com/test_aEU28pb4K7iccusaEJ',
  },
  paywalls_and_subs_team_v1: {
    plan: 'tiered',
    product: 'team',
    rawPlanName: 'paywalls_and_subs_team_v1',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Paywalls and Subscriptions Team',
    shortPlanName: 'Team+',
    basePricePerMonth: 149,
    upgradeTo: [
      'paywalls_and_subs_business_v1',
      'paywalls_and_subs_enterprise_v1',
    ],
    purchaseLinkProd: 'https://buy.stripe.com/8wMaFb8DUecL9UsaEY',
    purchaseLinkStaging: 'https://buy.stripe.com/test_5kA8wNdcSbys8ecaEH',
    marketingTagLine: 'Foundation for growth',
  },
  paywalls_and_subs_business_v1: {
    plan: 'tiered',
    product: 'business',
    rawPlanName: 'paywalls_and_subs_business_v1',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Paywalls and Subscriptions Business',
    shortPlanName: 'Business+',
    basePricePerMonth: 299,
    upgradeTo: ['paywalls_and_subs_enterprise_v1'],
    purchaseLinkProd: 'https://buy.stripe.com/cN2eVr2fw3y7aYw28t',
    purchaseLinkStaging: 'https://buy.stripe.com/test_aEUbIZa0GdGA9igcMT',
    marketingTagLine: 'Amplify your subscription revenue',
  },
  paywalls_and_subs_enterprise_v1: {
    product: 'enterprise',
    plan: 'tiered',
    rawPlanName: 'paywalls_and_subs_enterprise_v1',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Paywalls and Subscriptions Enterprise',
    shortPlanName: 'Enterprise+',
    basePricePerMonth: 999,
    upgradeTo: [],
    marketingTagLine: 'Paywalls + subscriptions at scale',
  },
  unlimited: {
    plan: 'legacy',
    rawPlanName: 'unlimited',
    tier: 'paywallsAndSubscriptions',
    fullReadablePlanName: 'Legacy Unlimited',
    shortPlanName: 'Unlimited',
    upgradeTo: [],
  },
};

export type TInternalProduct = {
  rawPlanName: RawPlanName;
  fullReadablePlanName: string;
  shortPlanName?: string;
  tier: TierType;
  basePricePerMonth?: number;
  upgradeTo: RawPlanName[];
  plan: PlanType;
  purchaseLinkProd?: string;
  purchaseLinkStaging?: string;
  product?: ProductType;
  marketingTagLine?: string;
};

export type TDisplayProduct = TInternalProduct & {
  product_value_props: string[];
};

export interface UserRoleType {
  id: string;
  name: string;
  display_name: string;
}

export interface UserPermissionType {
  // TODO: Rename to "OrgType"
  id: string;
  name: string;
  roles: UserRoleType[];
  plans: string[];
  entitlements: string[];
  policies: UserPoliciesType;
}

export interface UserPayloadType {
  avatar: string | null;
  first_name: string;
  last_name: string;
  opted_in_marketing: boolean;
  orgs: UserOrgType[];
  permissions: UserPermissionType[];
  username: string;
  intercom_hash: string;
}

export interface UserType extends UserPayloadType {
  id: string;
}

export interface PasswordType {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface TokenPayloadType {
  name: string;
  expiration: string;
}

export interface TokenType {
  jti: string;
  name: string;
  source: string;
  token_type: string;
  session: string;
  exp: string;
  iat: string;
  nbf: string;
}

export interface NewAccountPayloadType {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  date_accepted_tos: string;
  promo_code: string;
  detail: string;
}

export type TAuthenticator = {
  id: string;
  type: string;
  type_display: string;
  finished_setup: boolean;
  name: string;
};

export type TOTPAuthenticatorType = TAuthenticator & {
  qr_code: string;
  secret: string;
};

export type RecoveryAuthenticatorType = TAuthenticator & {
  codes: string[];
};

export type TOnboardingPayload = {
  first_name: string;
  last_name: string;
  password: string;
  opted_in_marketing: boolean;
};

export type AuthPayloadType = {
  token_type: string;
  profile: UserType;
  access_token: string;
  refresh_token: string;
};

export type PreAuthPayloadType = {
  token_type: string;
  pre_auth_token: string;
  authenticators:
    | TAuthenticator[]
    | RecoveryAuthenticatorType[]
    | TOTPAuthenticatorType[];
};

export interface IntegrationMetadataReferenceType {
  title: string;
  url: string;
}

export type TIntegrationMetadata = {
  logo: string;
  type: string;
  title: string | null;
  subtitle: string;
  description: string;
  implemented: boolean;
  request_only: boolean;
  entitlement: string;
  references: IntegrationMetadataReferenceType[];
};

export type SchemaPartnerType = {
  config: TSchemaRoot;
  catch_all_events: boolean;
  events: string[];
  event_schemas: TSchemaRoot;
};

export type SchemaPartnersType = Record<string, SchemaPartnerType>;

export interface InviteAcceptancePayloadType {
  first_name: string;
  last_name: string;
  password: string;
  opted_in_marketing: boolean;
}

export interface EntitlementType {
  description: string;
  display_name: string;
  id: string;
  name: string;
}

export interface RoleType {
  id: string;
  name: string;
  org_owner: string;
  type: string;
  creator: string;
  description: string;
  display_name: string;
  entitlements: EntitlementType[];
}

export interface FeatureRequestPayloadType {
  name: string;
  url: string;
  use_case: string;
}

export interface FeatureRequestType extends FeatureRequestPayloadType {
  id: string;
}

export interface UpgradeRequestType {
  id: string;
  entitlement: string;
  org: string;
  account: string;
}

export interface OrgType {
  // TODO: Rename to "ShallowOrgType"
  id: string;
  name: string;
  accounts: UserType[];
}

export interface LegacyCampaignReferenceType {
  id: string;
  name: string;
}

export interface CampaignRuleReferenceType {
  id: string;
  name: string;
  enabled: boolean;
  not_before: string | null;
  archived: boolean;
  label_id: string;
  label_enabled: boolean;
}

// TODO: Split the config per Platform type
export interface PlatformConfigType {
  itunes_shared_secret: string;
  shared_secret: string;
  google_service_account: string;
  private_key: string;
  api_key: string;
  minutes_per_reward_units: number;
}

export interface PlatformPayloadType {
  app: string;
  marketplace_app_id: string | null;
  name: string;
  sdk_enabled: boolean;
  type: string;
  s2s_url: string | null;
  config: Partial<PlatformConfigType>;
}

export interface PlatformType extends Omit<PlatformPayloadType, 'config'> {
  id: string;
  credentials_are_set: boolean;
  last_sync: string | null;
  is_config_valid: boolean;
  last_config_update: string | null;
}

export interface PlatformAllowedOriginType {
  id: string;
  platform: string;
  url: string;
}

export interface OrgPolicyPayloadType {
  session_inactivity_timeout: number | null;
  session_max_duration: number | null;
  password_max_age: number | null;
  inactive_user_disable: number | null;
  inactive_user_delete: number | null;
  domain_restriction: boolean;
  authn_max_attempts: number | null;
  authn_track_duration: number | null;
  authn_lockout_time: number | null;
}

export interface OrgPolicyType extends OrgPolicyPayloadType {
  id: string;
}

export interface SessionDefaultsType {
  inactivity_timeout: number;
  max_duration: number;
}

export interface EventStreamPayloadType {
  app: string;
  platform: string;
  partner: string;
  name: string;
  enabled: boolean;
  catchall: boolean;
  config: Record<string, any>;
  topics: string[];
}

export interface EventStreamType extends EventStreamPayloadType {
  id: string;
  has_complete_config: boolean;
}

export interface LanguageType {
  code: string;
  language: string;
}

export type TFont = {
  id: string;
  family: string;
  style: string;
  file: string;
};

export type TListResult<T extends any> = {
  count: number;
  page_number: number;
  total_pages: number;
  next: number | null;
  previous: number | null;
  results: T[];
};

export enum TOrgSecretKindEnum {
  AppleAppStoreConnectApi = 'apple_app_store_connect_api',
  AppleInAppPurchase = 'apple_in_app_purchase',
  ApplePushNotificationService = 'apple_push_notification_service',
  AppleSharedSecret = 'apple_shared_secret',
  AppLovinEventKey = 'applovin_event_key',
  GoogleServiceAccount = 'google_service_account',
  AmazonIAPSharedSecret = 'amazon_iap_shared_secret',
  RokuPay = 'roku_pay_api_key',
  IronsourcePrivateKey = 'ironsource_private_key',
  MParticleApiAuthV1 = 'mparticle_api_auth_v1',
  NamiOutboundWebhookV1 = 'nami_outbound_webhook_v1',
  ZendeskApiAuthV1 = 'zendesk_api_auth_v1',
}

export type TOrgSecret = {
  id: string;
  expires_at: string | null;
  kind: TOrgSecretKindEnum;
  public: Record<string, unknown>;
  validated_at: string | null;
  validated_success: string | null;
};

export type TOrgSecretPayload = {
  secret: Record<string, unknown> | null;
} & Pick<TOrgSecret, 'kind' | 'public' | 'expires_at'>;

export type TOrgSecretListResult = TListResult<TOrgSecret> & {
  schema: Record<
    Extract<
      TOrgSecretKindEnum,
      'apple_app_store_connect_api' | 'google_service_account'
    >,
    {
      label: string;
      secret: TSchemaRoot;
      public: TSchemaRoot;
    }
  >;
};

export type TOrgCDPIntegrationPayload = {
  config: {};
  url: string;
  display_name: string;
  provider: TOrgCDPIntegrationProvider;
  created_date: string;
  updated_date: string;
};

export interface TOrgCDPIntegrationDisplaySettings {
  title: string;
  status: 'ready' | 'coming_soon' | 'hidden';
}

export type TOrgCDPIntegration = TOrgCDPIntegrationPayload & { id: string };

export type TOrgCDPIntegrationToken = {
  jti: string;
  name: string;
  token_type: string;
  exp: string;
  iat: string;
};

export type TOrgCDPIntegrationProvider =
  | 'adobe_ec'
  | 'custom'
  | 'amplitude'
  | 'generic';

export const TOrgCDPIntegrationProviderDisplay: Record<
  TOrgCDPIntegrationProvider,
  TOrgCDPIntegrationDisplaySettings
> = {
  custom: {
    title: 'Custom Audience',
    status: 'ready',
  },
  adobe_ec: {
    title: 'Adobe Experience Cloud',
    status: 'coming_soon',
  },
  amplitude: {
    title: 'Amplitude',
    status: 'coming_soon',
  },
  generic: {
    title: 'Self-Service Audience',
    status: 'hidden',
  },
};

export const TOrgCDPIntegrationDocLinks: Record<
  TOrgCDPIntegrationProvider,
  string
> = {
  custom:
    'https://learn.namiml.com/public-docs/integrations/cdp-integrations/custom-cdp-integration',
  generic:
    'https://learn.namiml.com/public-docs/integrations/cdp-integrations/generic-cdp-integration',
  amplitude:
    'https://learn.namiml.com/public-docs/integrations/cdp-integrations/amplitude-cdp-integration',
  adobe_ec:
    'https://learn.namiml.com/public-docs/integrations/cdp-integrations/adobe-cdp-integration', //TODO
};

export type TCDPIntegration = {
  id: string;
  display_name: string;
  provider: TOrgCDPIntegrationProvider;
  enabled: boolean;
  created_date: string;
  updated_date: string;
  cdp_integration_id: string;
  configured: boolean;
};

export type TNewAppCDPIntegrationPayload = {
  integration: string;
  app: string;
};

export type TCDPIntegrationIdentityScope = {
  id: string;
  integration: string;
  created_date: string;
  updated_date: string;
  name: string;
};

export type TCDPIntegrationLinkType = 'external_id' | 'cdp_id';

export type TCDPIntegrationLinks = {
  id: string;
  type: TCDPIntegrationLinkType;
  created_date: string;
  updated_date: string;
  activation: string;
  scope: string;
};
