import React, { useMemo, useState } from 'react';

import { Form, Modal, Select, Space, Typography } from 'antd';
import { PaywallType } from 'src/api/types/paywall.types';
import { findBackgroundColor, findBackgroundImage } from 'src/utils/paywall';
import { namiDarkGray } from 'src/variables';

import {
  usePaywallsQuery,
  useSwapPaywallMutation,
} from '../../../../../hooks/queries/paywall.hooks';
import { PaywallBackground } from '../../../campaigns/detail/SegmentFieldSection';

type SwapPaywallModalProps = {
  open: boolean;
  onSwapSettled: () => void;
  onCancel: () => void;
  currentPaywall: PaywallType;
};

export default function SwapPaywallModal({
  open,
  onSwapSettled,
  onCancel,
  currentPaywall,
}: SwapPaywallModalProps) {
  const [selectedPaywall, setSelectedPaywall] = useState<string | null>(null);
  const swapPaywallMutation = useSwapPaywallMutation({
    paywallId: currentPaywall.id,
    onSettled: onSwapSettled,
  });
  const paywalls = usePaywallsQuery({ pageSize: 1000 });
  const filteredPaywalls = useMemo(() => {
    return paywalls.data?.results.filter((paywall) => {
      return paywall.id !== currentPaywall.id;
    });
  }, [paywalls.data, currentPaywall]);

  const paywallOptions = useMemo(() => {
    return (filteredPaywalls || []).map((paywall) => {
      return {
        key: paywall.id,
        value: paywall.id,
        text: paywall.name,
        label: (
          <Space align="baseline">
            <PaywallBackground
              height={20}
              preview={false}
              width={20}
              color={findBackgroundColor(paywall)}
              src={findBackgroundImage(paywall) || undefined}
            />
            {paywall.name}
            <Typography.Text
              style={{ fontSize: 'smaller', color: namiDarkGray }}
            >
              {paywall.description ? ' • ' : ''}
              {paywall.description}
            </Typography.Text>
          </Space>
        ),
      };
    });
  }, [filteredPaywalls]);

  return (
    <Modal
      title="Replace Paywall"
      open={open}
      onCancel={onCancel}
      okButtonProps={{
        disabled: !selectedPaywall,
      }}
      onOk={() => {
        selectedPaywall &&
          swapPaywallMutation.mutate({
            newPaywallId: selectedPaywall,
          });
      }}
      confirmLoading={swapPaywallMutation.isLoading}
      okText="Replace"
      zIndex={1002}
      centered
      closable={false}
      width={700}
    >
      <Form layout="vertical">
        <Form.Item
          label="Choose Replacement"
          className="intercom-selectPaywallForSwap"
        >
          <Select
            options={paywallOptions}
            value={selectedPaywall}
            onChange={(value) => setSelectedPaywall(value as unknown as string)}
            filterOption={(input, option) =>
              (option?.text || '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch={true}
          />
        </Form.Item>
        <Space>{getReplacementText()}</Space>
      </Form>
    </Modal>
  );

  function getReplacementText(): React.ReactNode {
    const paywallLive = currentPaywall.rules.some((rule) => rule.enabled);
    return (
      <Typography.Paragraph>
        You are replacing <i>{currentPaywall.name}</i> in <> </>
        {currentPaywall.rules.length} campaign
        {currentPaywall.rules.length > 1 ? 's' : ''}.
        {paywallLive && <strong> This paywall will no longer be live.</strong>}
      </Typography.Paragraph>
    );
  }
}
