import React, { useContext, useState, useEffect } from 'react';

import tinycolor from 'tinycolor2';

import { config } from './constants';
import { low, high, getColors, TColorStop } from './formatters';
import {
  computePickerPosition,
  getGradientType,
  computeSquareXY,
  getDegrees,
  getNewHsl,
  getHandleValue,
  isUpperCase,
} from './utils';

type PickerContextWrapperProps = {
  children: React.ReactNode;
  bounds: DOMRect | null;
  value: string;
  onChange(value: string): void;
};

type TPickerContext = {
  x: number;
  y: number;
  s: number;
  l: number;
  r: number;
  g: number;
  b: number;
  hue: number;
  hsvS: number;
  hsvV: number;
  value: string;
  colors: TColorStop[];
  degrees: number;
  opacity: number;
  onChange(color: string): void;
  addPoint(e: React.MouseEvent<HTMLDivElement>): void;
  inputType: string;
  tinyColor: tinycolor.Instance;
  handleHue(e: React.MouseEvent<HTMLDivElement>): void;
  isGradient: boolean;
  offsetLeft: number | undefined;
  handleColor(
    e: React.MouseEvent<HTMLDivElement>,
    ctx: CanvasRenderingContext2D
  ): void;
  currentLeft: number | undefined;
  deletePoint(): void;
  internalHue: number;
  setInputType(value: string): void;
  gradientType: string;
  handleChange(value: string): void;
  currentColor: string;
  selectedColor: number;
  handleOpacity(e: React.MouseEvent<HTMLDivElement>): void;
  setInternalHue(value: number): void;
  previousColors: string[];
  handleGradient(value: string): void;
  setSelectedColor(value: number): void;
  previousGraidents: string[];
};

const { squareSize, crossSize } = config;
const PickerContext = React.createContext<TPickerContext | null>(null);

export default function PickerContextWrapper({
  children,
  bounds,
  value,
  onChange,
}: PickerContextWrapperProps) {
  const offsetLeft = bounds?.x;

  const isGradient = value?.includes('gradient');
  const gradientType = getGradientType(value);
  const degrees = getDegrees(value);
  const degreeStr =
    gradientType === 'linear-gradient' ? `${degrees}deg` : 'circle';
  const colors = getColors(value);
  const [selectedColor, setSelectedColor] = useState(0);
  const indexedColors = colors?.map((c, i) => ({ ...c, index: i }));
  const currentColorObj =
    indexedColors?.filter((c) => isUpperCase(c.value))[selectedColor] ||
    indexedColors[selectedColor];
  const currentColor = currentColorObj?.value;
  const currentLeft = currentColorObj?.left;
  const [tinyColor, setTinyColor] = useState<tinycolor.Instance>(
    tinycolor(currentColor)
  );

  const [inputType, setInputType] = useState('rgb');

  const { r, g, b, a: opacity } = tinyColor.toRgb();
  const { h, s, l } = tinyColor.toHsl();
  const { s: hsvS, v: hsvV } = tinyColor.toHsv();
  const [internalHue, setInternalHue] = useState(Math.round(h));
  const hue = Math.round(h);
  const [x, y] = computeSquareXY([hue, s, l]);
  const [previousColors, setPreviousColors] = useState<string[]>([]);
  const [previousGraidents, setPreviousGradients] = useState<string[]>([]);

  useEffect(() => {
    setTinyColor(tinycolor(currentColor));
    setInternalHue(hue);
  }, [currentColor, hue]);

  useEffect(() => {
    if (isGradient) {
      setPreviousGradients([value, ...previousGraidents?.slice(0, 4)]);
    } else {
      if (tinycolor(value).isValid()) {
        setPreviousColors([value, ...previousColors?.slice(0, 4)]);
      }
    }
    //eslint-disable-next-line
  }, [value]);

  const createGradientStr = (newColors: TColorStop[]): void => {
    let sorted = newColors.sort((a, b) => (a.left || 0) - (b.left || 0));
    let colorString = sorted?.map((cc) => `${cc?.value} ${cc.left}%`);
    onChange(`${gradientType}(${degreeStr}, ${colorString.join(', ')})`);
  };

  const handleGradient = (newColor: string, left = currentLeft) => {
    let remaining = colors?.filter((_c, i) => i !== selectedColor);
    let newColors = [
      { value: newColor.toUpperCase(), left: left },
      ...remaining,
    ];
    createGradientStr(newColors);
  };

  const handleChange = (newColor: string): void => {
    if (isGradient) {
      handleGradient(newColor);
    } else {
      onChange(newColor);
    }
  };

  const handleOpacity = (e: React.MouseEvent<HTMLDivElement>): void => {
    let newO = getHandleValue(e) / 100;
    let newColor = `rgba(${r}, ${g}, ${b}, ${newO})`;
    handleChange(newColor);
  };

  const handleHue = (e: React.MouseEvent<HTMLDivElement>): void => {
    let newHue = getHandleValue(e) * 3.6;
    let newHsl = getNewHsl(newHue, s, l, opacity, setInternalHue);
    handleChange(newHsl);
  };

  const handleColor = (
    e: React.MouseEvent<HTMLDivElement>,
    ctx: CanvasRenderingContext2D
  ): void => {
    const [x, y] = computePickerPosition(e);
    const x1 = Math.min(x + crossSize / 2, squareSize - 1);
    const y1 = Math.min(y + crossSize / 2, squareSize - 1);
    const [r, g, b] = Array.from(ctx.getImageData(x1, y1, 1, 1).data);
    let newColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
    handleChange(newColor);
  };

  const addPoint = (e: React.MouseEvent<HTMLDivElement>) => {
    let left = getHandleValue(e);
    let newColors = [
      ...colors.map((c) => ({ ...c, value: low(c) })),
      { value: currentColor, left: left },
    ];
    createGradientStr(newColors);
  };

  const deletePoint = () => {
    if (colors?.length > 2) {
      let remaining = colors?.filter((rc, i) => i !== selectedColor);
      createGradientStr(remaining);
      setSelectedColor(Math.max(selectedColor - 1, 0));
    }
  };

  const pickerState: TPickerContext = {
    x,
    y,
    s,
    l,
    r,
    g,
    b,
    hue,
    hsvS,
    hsvV,
    value,
    colors,
    degrees,
    opacity,
    onChange,
    addPoint,
    inputType,
    tinyColor,
    handleHue,
    isGradient,
    offsetLeft,
    handleColor,
    currentLeft,
    deletePoint,
    internalHue,
    setInputType,
    gradientType,
    handleChange,
    currentColor,
    selectedColor,
    handleOpacity,
    setInternalHue,
    previousColors,
    handleGradient,
    setSelectedColor,
    previousGraidents,
  };

  return (
    <PickerContext.Provider value={pickerState}>
      {children}
    </PickerContext.Provider>
  );
}

export function usePicker() {
  return useContext(PickerContext);
}
