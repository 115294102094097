import React, { useContext } from 'react';

import { Form, Input, InputNumber } from 'antd';
import { useParams } from 'react-router-dom';

import { AppContext } from '../../../../AppContextProvider';
import { usePlatformQuery } from '../../../../hooks/queries/platform.hooks';
import { TIntegrationParams } from '../params.types';

const typePropsMap: Record<string, { label: string; placeholder: string }> = {
  ironsource_rewarded: {
    label: 'Server to Server Callback Private',
    placeholder: 'Server to Server Callback Private Key',
  },
  applovin_rewarded: {
    label: 'AppLovin Event',
    placeholder: 'AppLovin Event Key',
  },
};

export default function RewardedFields() {
  const { type, integrationId } = useParams<TIntegrationParams>();
  const { userHasEntitlement } = useContext(AppContext);
  const platformQuery = usePlatformQuery(integrationId);

  if (!(type in typePropsMap)) return null;
  const { label, placeholder } = typePropsMap[type];

  const hasCredentials = !!platformQuery.data?.credentials_are_set;
  const canUpdate = userHasEntitlement('app.platform.update');
  return (
    <>
      <Form.Item label={label} name={['config', 'private_key']}>
        <Input.Password
          className={hasCredentials ? 'credentials_are_set' : ''}
          disabled={!canUpdate}
          autoComplete="new-password"
          placeholder={hasCredentials ? '●●●●●●●●●●●●●●●●' : placeholder}
        />
      </Form.Item>
      <Form.Item
        label="Minutes of Access per Reward Unit"
        name={['config', 'minutes_per_reward_units']}
      >
        <InputNumber disabled={!canUpdate} placeholder="60" />
      </Form.Item>
    </>
  );
}
