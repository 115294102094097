export function isValidURL(string: string): boolean {
  let url: URL;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return !!(url.hostname || url.pathname) && !!url.protocol;
}

export function capitalizePhrase(
  string: string,
  separator: string = ' ',
  joint?: string
): string {
  return string
    .split(separator)
    .map(capitalizeString)
    .join(joint || separator);
}

export function toReadable(string: string): string {
  const output = string
    .replace(/[A-Z]/g, (char) => ` ${char}`)
    .replace(/[-_]/g, ' ');
  return output[0].toUpperCase() + output.substring(1);
}

export function toSlug(
  string: string,
  preferredSpaceReplacement: string = '_'
): string {
  return string
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the accent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .replace(/[^a-z0-9\s-_]/g, '') // remove all chars not letters, numbers, hyphens, underscores and spaces (to be replaced)
    .trim()
    .replace(/\s+/g, preferredSpaceReplacement);
}

export function replaceWhitespace(
  string: string,
  preferredSpaceReplacement: string = ''
): string {
  return string
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the accent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .replace(/[^a-zA-Z0-9\s-_]/g, '') // remove all chars not letters, numbers, hyphens, underscores and spaces (to be replaced)
    .trim()
    .replace(/\s+/g, preferredSpaceReplacement);
}

export function toCamelCase(string: string): string {
  return string.toString().replace(/\s+/g, '');
}

export function capitalizeString(string: string): string {
  string = string.toLowerCase();
  if (!string.length) return '';
  if (string === 'sku') return 'SKU';
  if (string === 'mparticle') return 'mParticle';
  return string[0].toUpperCase() + string.substring(1);
}

export function capitalizeFirstCharacter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function hasLowerCase(string: string): boolean {
  return string.toUpperCase() !== string;
}

export function hasUpperCase(string: string): boolean {
  return string.toLowerCase() !== string;
}

export function hasNumber(string: string): boolean {
  return /\d/.test(string);
}

export function truncateString(
  string = '',
  length: number,
  slicePeriod: boolean = true
): string {
  if (!(length && string.length > length)) return string;

  const truncated = string.slice(0, length);

  if (slicePeriod) {
    const dotIndex = truncated.lastIndexOf('.');
    if (dotIndex > 0) return truncated.slice(0, dotIndex + 1);
  }

  return truncated.replace(/[,\s]+$/, '') + '...';
}

export function lowercaseIncludes(expected: string[], value: string): boolean {
  return expected.some((option) =>
    option.toLowerCase().includes(value.toLowerCase())
  );
}

export function toBoolean(value: string | null): boolean | undefined {
  if (value == null) return undefined;
  if (value.toLowerCase() === 'true') return true;
  if (value.toLowerCase() === 'false') return false;
  return undefined;
}
