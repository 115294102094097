import React from 'react';

import { Space } from 'antd';
import styled from 'styled-components';

import { namiDarkGray } from '../../../../variables';
import {
  getRuleDotForDetailPage,
  getRuleStatusForDetailPage,
} from './campaign.utils';

const StatusWrapper = styled(Space)`
  .ant-space-item {
    display: flex;
    align-items: center;
  }
`;

const StatusText = styled.span`
  color: ${namiDarkGray};
`;

export default function CampaignStatusLabel({
  enabled,
  startDate,
  size,
  archived = false,
}: {
  enabled: boolean;
  startDate: string | null;
  size: 'mdUp' | 'mdDown';
  archived?: boolean;
}) {
  return (
    <>
      <StatusWrapper>
        {getRuleDotForDetailPage(enabled, startDate, archived)}
        <StatusText>
          {getRuleStatusForDetailPage(enabled, startDate, size, archived)}
        </StatusText>
      </StatusWrapper>
    </>
  );
}
