import React from 'react';

import { DatePicker, Form, Space, Tag } from 'antd';
import moment, { Moment } from 'moment';
import { useAppContext } from 'src/hooks';
import styled from 'styled-components';

const StyledTag = styled(Tag)`
  cursor: pointer;
`;

type CampaignSchedulerProps = {
  startDateInPast: boolean;
  startDate: string | null;
  onChange: (value: Moment | null) => void;
};

export default function CampaignScheduler({
  startDateInPast,
  startDate,
  onChange,
}: CampaignSchedulerProps) {
  const { userHasEntitlement } = useAppContext();
  const userCanEditCampaign = userHasEntitlement('app.campaign.update');
  return (
    <Form.Item
      label="Start Date"
      className="intercom-campaignStart"
      validateStatus={startDateInPast ? 'error' : undefined}
    >
      <DatePicker
        showNow={false}
        onChange={onChange}
        placeholder="Select date"
        value={startDate ? moment(startDate) : undefined}
        showTime={{
          showNow: false,
          format: 'HH:mm',
          defaultValue: moment('00:00:00', 'HH:mm'),
        }}
        format={'YYYY-MM-DD HH:mm'}
        disabledDate={(currentDate) => currentDate.isBefore(moment(), 'day')}
        disabled={!userCanEditCampaign}
        renderExtraFooter={rangeFooter}
      />
    </Form.Item>
  );

  function rangeFooter() {
    return (
      <Space direction="horizontal" size={2}>
        <StyledTag
          color="blue"
          onClick={() => onChange(moment().add(12, 'hour'))}
        >
          In 12 hours
        </StyledTag>
        <StyledTag
          color="blue"
          onClick={() => onChange(moment().add(1, 'day').startOf('day'))}
        >
          Tomorrow
        </StyledTag>
        <StyledTag
          color="blue"
          onClick={() => onChange(moment().add(1, 'week').startOf('isoWeek'))}
        >
          Next week
        </StyledTag>
        <StyledTag
          color="blue"
          onClick={() => onChange(moment().add(1, 'month').startOf('month'))}
        >
          Next month
        </StyledTag>
      </Space>
    );
  }
}
