import React from 'react';

import styled from 'styled-components';

import { colorBlack, colorNegative } from '../../variables';

type DesktopProps = {
  className?: string;
  children: JSX.Element | JSX.Element[];
  inFocusedState: boolean;
};

const Wrapper = styled.div<{ height: number; scale?: number }>`
  --height: ${({ height }) => height}px;
  width: calc(var(--height) * 1.379310345);
  height: var(--height);
  background-color: ${colorBlack};
  border-radius: 8px;
  margin: 0px auto;
  padding: 6px;
  z-index: 2;
  box-shadow: 0 35px 60px -24px rgba(0 0 0 / 0.4);
  user-select: none;

  ${({ scale }) => (scale ? `transform: scale(${scale});` : '')}
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorNegative};
  border-radius: 7px;
  position: relative;
  padding: 5px;
  z-index: 2;
`;

const DesktopView = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorNegative};
  position: relative;
  overflow: hidden;
  border-radius: 6px;
`;

export const DesktopInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default function Desktop({ children, ...props }: DesktopProps) {
  return (
    <Wrapper height={1044} scale={0.75} {...props}>
      <Body>
        <DesktopView>
          <DesktopInner>{children}</DesktopInner>
        </DesktopView>
      </Body>
    </Wrapper>
  );
}
