import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

type IntegrationsWebPaywallProps = WebPaywallProps & {
  entitlement: string;
};

const IntegrationsWebPaywall = ({
  onCancel,
  visible,
  entitlement,
}: IntegrationsWebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Basic Webhooks',
        'Apple and Google store integrations',
      ],
      ...PlanMeta['paywalls_only_team_v1'],
    },
    {
      product_value_props: ['Advanced Webhooks', 'Amazon Appstore billing'],
      ...PlanMeta['paywalls_only_business_v1'],
    },
    {
      product_value_props: [
        'Roku Pay billing',
        'AppLovin, ironSource integrations',
        'mParticle, Wicket Labs',
        'Custom integrations',
      ],
      ...PlanMeta['paywalls_only_enterprise_v1'],
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Basic Webhooks',
        'Apple and Google store integrations',
      ],
      ...PlanMeta['paywalls_and_subs_team_v1'],
    },
    {
      product_value_props: [
        'Advanced Webhooks',
        'Amazon Appstore billing',
        'Zendesk integration',
      ],
      ...PlanMeta['paywalls_and_subs_business_v1'],
    },
    {
      product_value_props: [
        'Roku Pay billing',
        'AppLovin, ironSource integrations',
        'mParticle, Wicket Labs',
        'Custom integrations',
      ],
      ...PlanMeta['paywalls_and_subs_enterprise_v1'],
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock Integrations"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_business_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_business_v1"
      entitlement={entitlement}
      onCancel={onCancel}
      visible={visible}
    />
  );
};

export default IntegrationsWebPaywall;
