import React from 'react';

import { ScreenRotationAltRounded } from '@mui/icons-material';
import { Button, Tooltip } from 'antd';
import { TDeviceOrientation } from 'src/api/types/paywall.types';
import { useActions } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

type PaywallOrientationPickerProps = {
  currentOrientation: TDeviceOrientation;
};

export default function PaywallOrientationPicker({
  currentOrientation,
}: PaywallOrientationPickerProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  return (
    <Tooltip
      title={`Rotate the paywall preview into ${
        currentOrientation === 'landscape' ? 'portrait' : 'landscape'
      }`}
      mouseEnterDelay={0.8}
    >
      <Button
        type="default"
        size="small"
        icon={
          <ScreenRotationAltRounded
            style={{ transform: 'translateY(1.5px)' }}
          />
        }
        onClick={() => toggleOrientation()}
      />
    </Tooltip>
  );

  function toggleOrientation() {
    actions.setOrientation(
      currentOrientation === 'landscape' ? 'portrait' : 'landscape'
    );
  }
}
