import React, { useContext } from 'react';

import { Form, Input, Button, notification } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../AppContextProvider';
import { copyTextToClipBoard } from '../../../../services/helpers';
import { TIntegrationParams } from '../params.types';

export type S2SUrlFieldProps = {
  name: NamePath;
  label: string;
  help: string;
};

const CopyButton = styled(Button).attrs({ children: 'Copy' })`
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 0;
  border-end-end-radius: 1px;
  border-start-end-radius: 1px;

  .ant-input-group-addon:has(&) {
    padding: 0;
  }
`;

const HelpText = styled.span`
  display: block;
  margin-bottom: 1.5rem;
`;

const typePropsMap: Record<string, S2SUrlFieldProps[] | undefined> = {
  apple: [
    {
      name: 's2s_url',
      label: 'Subscription Status URL',
      help: 'Add this URL to App Store Connect to send Apple Server to Server notifications to Nami.',
    },
  ],
  google: [
    {
      name: ['nami_config', 'rtdn_topic'],
      label: 'Cloud Pub/Sub Topic',
      help: 'Add this to your app on Google Play Console to enable real-time developer notifications.',
    },
  ],
  amazon_iap: [
    {
      name: 's2s_url',
      label: 'URL for Appstore Notifications',
      help: 'Add this URL to the Amazon Developer Console to send real-time event notifications to Nami.',
    },
  ],
  roku_pay: [
    {
      name: 's2s_url',
      label: 'URL for Transaction Notifications',
      help: 'Add this URL to the Roku Developer Dashboard to send real-time event notifications to Nami.',
    },
  ],
  ironsource_rewarded: [
    {
      name: 's2s_url',
      label: 'Server to Server Callback URL',
      help: 'Add this URL to IronSource Rewarded Video Server to Server Callback Settings to send reward callbacks to Nami.',
    },
    {
      name: 's2s_revoke_url',
      label: 'Server to Server Negative Callback URL',
      help: 'Add this URL to IronSource Rewarded Video Server to Server Negative Callback Settings to send reward callbacks to Nami.',
    },
  ],
  applovin_rewarded: [
    {
      name: 's2s_url',
      label: 'Server Side Callback URL',
      help: 'Add this URL to AppLovin Rewarded Video ad unit S2S Reward Callback settings to send reward callbacks to Nami.',
    },
  ],
};

export default function S2SUrlFields() {
  const { integrationId, type } = useParams<TIntegrationParams>();

  if (!integrationId) return null;

  const fields = (typePropsMap[type] || []).map((fieldProps) => {
    const { name } = fieldProps;
    const key = Array.isArray(name) ? name.join('.') : name;
    return <S2SUrlField key={key} {...fieldProps} />;
  });
  return (
    <>
      {fields}
      <S2SUrlField
        name="id"
        label="App Platform Identifier"
        help="Use this ID to initialize the SDK"
      />
    </>
  );
}

function S2SUrlField({ name, label, help }: S2SUrlFieldProps) {
  const value = Form.useFormInstance().getFieldValue(name) || '';
  const { userHasEntitlement } = useContext(AppContext);

  const canUpdate = userHasEntitlement('app.platform.update');

  return (
    <Form.Item label={label} help={<HelpText>{help}</HelpText>}>
      <Input
        placeholder={label}
        disabled={!canUpdate || !value}
        value={value}
        addonAfter={<CopyButton disabled={!value} onClick={copyValue} />}
      />
    </Form.Item>
  );

  function copyValue() {
    const message = `Copied ${label} Successfully`;
    copyTextToClipBoard(value).then(() => notification.success({ message }));
  }
}
