import { RcFile } from 'antd/es/upload';

import { getHash } from '../services/helpers';
import { client } from './clients';
import { extractResponseData, extractErrorData } from './utils';

const files = {
  uploadFile: (
    file: File | Blob | RcFile,
    {
      orgId,
      appId,
    }: { orgId: string; appId?: never } | { appId: string; orgId?: never }
  ): Promise<string> => {
    const objectId = appId || orgId;
    const type = appId ? 'app' : orgId ? 'org' : null;
    if (!type || !objectId) {
      throw new Error('File uploaded with incorrect info.');
    }
    const hash = getHash(9);
    const fileName = 'name' in file ? '-' + file.name : '';
    const filePath = `${type}/${objectId}/${hash}${fileName}`;
    return client
      .put(`/private/frontend/v1/upload/${filePath}`, file, {
        headers: { Accept: 'application/json', 'Content-Type': file.type },
      })
      .then(extractResponseData)
      .then((data) => data.url)
      .catch(extractErrorData);
  },
};

export default files;
