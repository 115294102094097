import React, { useState } from 'react';

import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { GroupsOutlined } from '@mui/icons-material';
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import {
  TOrgCDPIntegration,
  TOrgCDPIntegrationProvider,
  TOrgCDPIntegrationProviderDisplay,
} from 'src/api/types/main.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import Loading from 'src/components/Loading/Loading';
import SectionTitle from 'src/components/SectionTitle';
import { useAppContext } from 'src/hooks';
import {
  useAddCDPIntegrationMutation,
  useCDPIntegrationsQuery,
} from 'src/hooks/queries/org.hooks';
import adobe_ep from 'src/images/adobe_ep.svg';
import amplitude from 'src/images/logos_amplitude-icon.svg';
import { namiDarkGray, namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import OrgIntegrationModal from './OrgIntegrationModal';

const StyledStatusTag = styled(Tag)`
  position: absolute;
  z-index: 1;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.2);
`;

const CardDescription = styled.span`
  color: ${namiMediumGray};
`;

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 32px 12px;
  }
  min-height: 260px;
`;

export default function OrgIntegrationsPage() {
  const context = useAppContext();
  const orgCDPIntegrations = useCDPIntegrationsQuery();
  const newCDPIntegrationMutation = useAddCDPIntegrationMutation(() => {});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingIntegrationId, setEditingIntegrationId] = useState<
    string | null
  >(null);

  if (orgCDPIntegrations.isFetching) {
    return <Loading />;
  } else {
    return (
      <>
        <SectionTitle>My CDP Integrations</SectionTitle>
        <Row
          gutter={[12, 12]}
          style={{ marginTop: 24 }}
          key={'my-integrations'}
        >
          {orgCDPIntegrations.data?.results.map((integration) => {
            return getSetupCDPCard(integration);
          })}
        </Row>
        <Divider />
        <SectionTitle>All CDP Integrations</SectionTitle>
        <Row
          gutter={[12, 12]}
          style={{ marginTop: 24 }}
          key={'available-integrations'}
        >
          {Object.keys(TOrgCDPIntegrationProviderDisplay).map((provider) => {
            return getAvailableCDPCard(provider as TOrgCDPIntegrationProvider);
          })}
        </Row>
        {editingIntegrationId && (
          <OrgIntegrationModal
            id={editingIntegrationId}
            open={editModalOpen}
            onClose={() => {
              setEditModalOpen(false);
              setEditingIntegrationId(null);
            }}
          />
        )}
      </>
    );
  }

  function getSetupCDPCard(integration: TOrgCDPIntegration): React.ReactNode {
    return (
      <Col
        lg={8}
        md={12}
        sm={24}
        style={{ width: '100%' }}
        key={integration.id}
      >
        <StyledCard key={`${integration.id}-card`}>
          <Space
            direction="vertical"
            style={{ width: '100%', textAlign: 'center' }}
            size="large"
          >
            {getCDPProviderLogo(integration.provider)}
            <Space direction="vertical" size="small">
              <Typography.Text style={{ fontWeight: 600, fontSize: 15 }}>
                {integration.display_name}
              </Typography.Text>
              {getListItemDetails(integration)}
            </Space>
            {getListActions(integration)}
          </Space>
        </StyledCard>
      </Col>
    );
  }

  function getAvailableCDPCard(
    provider: TOrgCDPIntegrationProvider
  ): React.ReactNode {
    if (TOrgCDPIntegrationProviderDisplay[provider].status === 'hidden')
      return <span key={provider}></span>;
    return (
      <Col lg={8} md={12} sm={24} style={{ width: '100%' }} key={provider}>
        <StyledCard key={`${provider}-card`}>
          {TOrgCDPIntegrationProviderDisplay[provider].status ===
            'coming_soon' && <StyledStatusTag>Coming Soon</StyledStatusTag>}
          <Space
            direction="vertical"
            style={{ width: '100%', textAlign: 'center' }}
            size="large"
          >
            {getCDPProviderLogo(provider)}
            <Typography.Text style={{ fontWeight: 600, fontSize: 15 }}>
              {TOrgCDPIntegrationProviderDisplay[provider].title}
            </Typography.Text>
            <Button
              icon={<PlusOutlined />}
              disabled={
                TOrgCDPIntegrationProviderDisplay[
                  provider as TOrgCDPIntegrationProvider
                ].status === 'coming_soon' ||
                !context.userHasEntitlement('org.cdp_integration.create')
              }
              onClick={() =>
                newCDPIntegrationMutation.mutate({
                  provider: provider as TOrgCDPIntegrationProvider,
                })
              }
            >
              Add to Org
            </Button>
          </Space>
        </StyledCard>
      </Col>
    );
  }

  function getListActions(item: TOrgCDPIntegration): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        icon={<EditOutlined style={{ fontSize: '13px' }} />}
        size="small"
        onClick={() => {
          setEditingIntegrationId(item.id);
          setEditModalOpen(true);
        }}
      >
        Edit
      </IconActionButton>
    );
  }

  function getListItemDetails(item: TOrgCDPIntegration): React.ReactNode {
    return (
      <CardDescription>
        {TOrgCDPIntegrationProviderDisplay[item.provider].title}
        <span style={{ padding: 6 }}>•</span>
        {
          <Tooltip
            title={moment(item.created_date).format('YYYY-MM-DD h:mm A')}
          >
            <span style={{ cursor: 'pointer', fontSize: 13 }}>
              Created {moment(item.created_date).fromNow()}
            </span>
          </Tooltip>
        }
      </CardDescription>
    );
  }
}

export function getCDPProviderLogo(
  provider: TOrgCDPIntegrationProvider,
  height: number = 45,
  fontSize: number = 39,
  extraStyles?: React.CSSProperties
): React.ReactNode {
  if (provider === 'adobe_ec') {
    return (
      <Image src={adobe_ep} height={height} width={height} preview={false} />
    );
  } else if (provider === 'amplitude') {
    return (
      <Image src={amplitude} height={height} width={height} preview={false} />
    );
  }
  return (
    <GroupsOutlined
      style={{ fontSize: fontSize, color: namiDarkGray, ...extraStyles }}
    />
  );
}

export function getCDPProviderSubtitle(
  provider_type: TOrgCDPIntegrationProvider
) {
  if (provider_type === 'adobe_ec' || provider_type === 'amplitude')
    return 'Segmentation';
  if (provider_type === 'generic' || provider_type === 'custom')
    return 'Custom Audience Segmentation';
  return '';
}
